<template>
  <div v-if="order && ready" class="checkout">
    <header class="flex overlay_header">
      <div @click="close" class="back flex">
        <img src="@/assets/back.svg" alt="" />
      </div>
      <h2 class="header-label">{{ $t("checkout.checkout") }}</h2>
      <p />
    </header>
    <main>
      <ProductList
        v-if="getCheckoutCart"
        class="padding"
        :products="getCheckoutCart"
        :order="true"
        :small="true"
      />
      <Delivery
        :sameDelivery="sameDeliveryAddress"
        ref="billing"
        class="delivery"
        :isOrder="true"
        :billing="true"
      />
      <div class="delivery_same" @click="changeDelivery">
        <Checkbox :active="sameDeliveryAddress" />
        <h5 class="small-label-thin">
          {{ $t("delivery.sameDeliveryAddress") }}
        </h5>
      </div>
      <Delivery
        ref="delivery"
        v-if="!sameDeliveryAddress"
        class="delivery"
        :isOrder="true"
      />

      <div class="payment_method" id="payoptions">
        <h4 v-if="!isSpanish" class="small-label">
          {{ $t("checkout.payment_methods") }}
        </h4>
        <p v-if="!isSpanish && chooseMessage" class="choose">
          {{ chooseMessage }}
        </p>
        <!-- <div class="lev_points">
          <div class="flex">
            <Checkbox :active="levPoints" @clicked="toggleLevPoints" />
            <img src="@/assets/lev.svg" alt="lev" />
            <h5 class="small-label-thin">
              {{ $t("checkout.points") }}: <span>{{ user.points }}</span>
            </h5>
          </div>
          <p
            v-html="$t('checkout.lev_points_info', { lev: `<span>Lev</span>` })"
          ></p>
        </div> -->
        <div v-if="!isSpanish" class="multibanco">
          <div class="flex" @click="toggleMb">
            <Checkbox :round="true" :active="active.mb" />
            <img src="@/assets/multibanco.svg" alt="multibanco" />
            <h5 class="small-label">{{ $t("checkout.payment_by_atm") }}</h5>
          </div>
          <p>{{ $t("checkout.mb_info") }}</p>
        </div>
        <div v-if="!isSpanish" class="mbway">
          <div class="flex" @click="toggleMbway">
            <Checkbox :round="true" :active="active.mbway" />
            <img src="@/assets/mbway.svg" alt="mbway" />
            <h5 class="small-label">{{ $t("checkout.payment_by_mbway") }}</h5>
          </div>
          <TextInput
            v-model="phone"
            :value="phone"
            v-if="active.mbway"
            :placeholder="$t('checkout.phone')"
            :errorIcon="false"
            :label="$t('checkout.phone')"
          />
        </div>
        <div class="hipay_card">
          <div v-if="!isSpanish" class="flex" @click="toggleCard">
            <Checkbox :round="true" :active="active.card" />
            <img src="@/assets/creditcards.svg" alt="creditcards.svg" />
            <h5 class="small-label">{{ $t("checkout.payment_by_card") }}</h5>
          </div>
          <form v-show="active.card || isSpanish">
            <div id="hipay-hostedfields-form"></div>
          </form>
        </div>
      </div>
      <hr />
      <div class="terms">
        <p v-if="pleaseAccept" class="choose">{{ pleaseAccept }}</p>
        <div class="flex">
          <p>
            {{ $t("checkout.have_read") }}
            <a
              target="_blank"
              href="https://www.lev.pt/politica-de-privacidade"
              >{{ $t("checkout.terms_and_conditions") }}</a
            >
          </p>
          <Checkbox :active="accepted" @click="accept" />
        </div>
        <div v-if="!user.marketing" class="flex">
          <p>{{ $t("checkout.subscribe_to_newsletter") }}</p>
          <Checkbox :active="newsletter" @click="newsletter = !newsletter" />
        </div>
      </div>
      <hr />
      <Coupon />
      <CartSummary class="padding" :checkout="true" />
      <div class="padding">
        <PrimaryButton
          :finish="done"
          :saving="payInProgress"
          :disabled="!enabled"
          @click="pay"
          :title="buttonMessage"
        />
      </div>
      <p class="error_message">{{ error }}</p>
    </main>
  </div>
</template>

<script>
import ProductList from "@/components/ProductList.vue";
import Delivery from "@/components/Delivery.vue";
import CartSummary from "@/components/CartSummary.vue";
import PrimaryButton from "@/components/ui/PrimaryButton.vue";
import { mapGetters, mapState, mapMutations, mapActions } from "vuex";
import Checkbox from "@/components/ui/Checkbox.vue";
import bolev from "../apis/bolev";
import Coupon from "@/components/Coupon.vue";
import TextInput from "afonso-forms/src/components/TextInput";
import woocommerce from "../apis/woocommerce";

export default {
  name: "checkout",

  components: {
    ProductList,
    Delivery,
    CartSummary,
    PrimaryButton,
    Checkbox,
    Coupon,
    TextInput,
  },

  data() {
    return {
      loaded: false,
      active: {
        card: false,
        mb: true,
        mbway: false,
      },
      newsletter: false,
      accepted: false,
      payInProgress: false,
      chooseMessage: null,
      pleaseAccept: null,
      done: false,
      mbResult: null,
      phone: null,
      interval: null,
      ready: false,
      sameDeliveryAddress: true,
      config: {
        selector: "hipay-hostedfields-form",
        template: "auto",
      },
      cardInstance: null,
      ip: null,
    };
  },

  watch: {
    async done(value) {
      if (value) {
        setTimeout(() => {
          this.$router.push({ path: "/receipt" });
        }, 500);
      }
    },
    order(value) {
      if (!value.points_payment) {
        this.mutate({
          property: "levPoints",
          with: false,
        });
      }
    },
    "active.mbway"(value) {
      if (value) {
        this.phone = this.user.phone;
      }
    },
    mbwayPending(value) {
      if (!value) {
        this.payInProgress = false;
      }
    },
    error(value) {
      if (value) {
        const error = document.querySelector(".error_message");
        if (error) {
          error.scrollIntoView({ behavior: "smooth" });
        }
        setTimeout(() => {
          this.setError(null);
        }, 10000);
      }
    },
    ready() {
      if (this.error) {
        setTimeout(() => {
          const error = document.querySelector(".error_message");
          if (error) {
            error.scrollIntoView({ behavior: "smooth" });
          }
        }, 2000);
      }
    },
  },

  async mounted() {
    if (this.$route.query.orderid) {
      this.handleRedirect();
    }
    const { data } = await woocommerce.getProductsSku(
      this.cart.map((p) => p.sku)
    );
    this.setProducts(data);
    this.ready = true;
    if (this.user.marketing) {
      this.newsletter = true;
    }
    this.initiateHipay();
    setTimeout(() => {
      if (!this.order) {
        this.$router.push("/");
      }
    }, 5000);
  },

  beforeUnmount() {
    clearInterval(this.interval);
  },

  methods: {
    ...mapActions([
      "copyOrder",
      "saveMbDetails",
      "setLevPoints",
      "getUserData",
    ]),
    ...mapMutations([
      "setError",
      "toggle",
      "mutate",
      "setMbwayPending",
      "setProducts",
    ]),

    getIp() {
      fetch("https://ifconfig.me/all.json")
        .then((response) => response.json())
        .then((res) => {
          this.ip = res.ip_addr;
        });
    },

    initiateHipay() {
      if (!document.querySelectorAll("script[hid='hipay']").length) {
        let hipayScript = document.createElement("script");
        hipayScript.setAttribute("src", "https://libs.hipay.com/js/sdkjs.js");
        hipayScript.setAttribute("defer", true);
        hipayScript.setAttribute("hid", "hipay");
        document.head.appendChild(hipayScript);
      }

      if (!document.querySelectorAll("link[hid='hipaystyle']").length) {
        let style = document.createElement("link");
        style.setAttribute("rel", "stylesheet");
        style.setAttribute("hid", "hipaystyle");
        style.setAttribute(
          "href",
          "https://libs.hipay.com/themes/material.css"
        );
        document.head.appendChild(style);
      }

      setTimeout(() => {
        this.getIp();
        this.hipay = window.HiPay({
          username: process.env.VUE_APP_HIPAY_PRO_USER,
          password: process.env.VUE_APP_HIPAY_PRO_PASSWORD,
          environment:
            process.env.VUE_APP_HIPAY_PRO_STAGE &&
            process.env.VUE_APP_HIPAY_PRO_STAGE === "true"
              ? "stage"
              : "production",
          lang: process.env.VUE_APP_LANG,
        });
        this.cardInstance = this.hipay.create("card", this.config);
      }, 1000);
    },

    async pay() {
      if (this.chosenMethod) {
        if (this.accepted) {
          if (this.isSpanish) {
            this.payWithCard();
          } else if (this.active.card) {
            this.payWithCard();
          } else if (this.active.mb) {
            this.payWithMb();
          } else if (this.active.mbway) {
            this.payWithMbway();
          }
        } else {
          this.pleaseAccept = this.$t("checkout.please_accept_the_terms");
        }
      } else {
        let payOptions = document.getElementById("payoptions");
        let pos = payOptions.getBoundingClientRect().top - 95;
        window.scrollBy({
          top: pos,
          left: 0,
          behavior: "smooth",
        });
        this.chooseMessage = this.$t("checkout.please_choose_a_payment_method");
      }
    },

    async handleRedirect() {
      const data = await bolev.getOrder({
        orderId: this.$route.query.orderid,
        patientId: this.patientId,
      });
      this.mutate({
        property: "order",
        with: data,
      });
      await this.copyOrder();
      if (data.payments.reverse()[0].method === "multibanco") {
        const payment = this.order.payments.reverse()[0];
        this.saveMbDetails({
          entity: payment.mb_entity,
          reference: payment.mb_reference,
          expires: payment.expires_at,
        });
      }
      switch (this.$route.query.state) {
        case "pending":
        case "forwarding":
        case "completed":
          this.done = true;
          break;
        case "declined":
        case "error":
        default:
          this.setError(this.$t("checkout.payment_error"));
          this.payInProgress = false;
      }
    },

    accept() {
      this.setError(null);
      this.pleaseAccept = null;
      this.accepted = !this.accepted;
    },

    addCardStatus(value) {
      this.addCard = value;
    },

    close() {
      this.$router.push({
        name: "shop",
        params: { slug: this.categories[0].slug },
      });
    },

    toggleMb() {
      this.setError(null);
      this.chooseMessage = null;
      this.active.mb = !this.active.mb;
      if (this.active.mb) {
        this.active.mbway = false;
        this.active.card = false;
      }
    },

    toggleMbway() {
      this.setError(null);
      this.chooseMessage = null;
      this.active.mbway = !this.active.mbway;
      if (this.active.mb) {
        this.active.mb = false;
        this.active.card = false;
      }
    },

    toggleCard() {
      this.setError(null);
      this.chooseMessage = null;
      this.active.card = !this.active.card;
      if (this.active.card) {
        this.active.mb = false;
        this.active.mbway = false;
      }
    },

    async toggleLevPoints() {
      await this.toggle("levPoints");
      this.setLevPoints();
    },

    async payWithMb() {
      this.payInProgress = true;
      try {
        const data = await bolev.hiPayPayment({
          data: {
            payment_product: "multibanco",
          },
          patientId: this.patientId,
          orderId: this.order.id,
          source: "app",
          marketing: this.user.marketing,
        });

        switch (data.state) {
          case "pending":
          case "completed":
            await this.copyOrder();
            this.done = true;
            break;
          case "forwarding":
            window.location.href = data.url;
            break;
          case "declined":
          case "error":
          case "unknown":
          default:
            this.setError(data.error);
            this.payInProgress = false;
            break;
        }
      } catch (e) {
        this.setError(this.$t("checkout.payment_error"));
        this.payInProgress = false;
      }
    },
    async payWithMbway() {
      this.payInProgress = true;
      try {
        const data = await bolev.hiPayPayment({
          data: {
            payment_product: "mbway",
            phone: this.phone,
          },
          patientId: this.patientId,
          orderId: this.order.id,
          marketing: this.user.marketing,
          phone: this.phone,
          source: "app",
        });
        switch (data.state) {
          case "pending":
            this.setMbwayPending(true);
            this.interval = setInterval(() => {
              this.checkStatus(data.paymentId);
            }, 3000);
            setTimeout(() => {
              clearInterval(this.interval);
              this.setError(this.$t("checkout.payment_error"));
            }, 400000);
            break;
          case "completed":
            this.setMbwayPending(false);
            await this.copyOrder();
            this.done = true;
            break;
          case "forwarding":
            window.location.href = data.url;
            break;
          case "declined":
          case "error":
          case "unknown":
          default:
            this.setError(this.$t("checkout.payment_error"));
            break;
        }
      } catch (e) {
        this.setError(this.$t("checkout.payment_error"));
        this.payInProgress = false;
      }
    },
    async checkStatus(id) {
      try {
        const data = await bolev.hiPayCheckStatus({
          id,
        });
        if (data.paid) {
          this.setMbwayPending(false);
          await this.copyOrder();
          this.done = true;
        }
      } catch (e) {
        this.setMbwayPending(false);
        this.payInProgress = false;
        console.log(e);
      }
    },

    changeDelivery() {
      this.sameDeliveryAddress = !this.sameDeliveryAddress;
      setTimeout(() => {
        if (this.sameDeliveryAddress) {
          this.$refs.billing.changeBillingAddress();
        } else {
          this.$refs.delivery.changeDeliveryAddress();
        }
      }, 1000);
    },

    async payWithCard() {
      this.setError(null);
      this.payInProgress = true;
      try {
        const paymentData = await this.cardInstance.getPaymentData();
        paymentData.browser_info.http_accept = "*/*";
        paymentData.browser_info.ipaddr = this.ip;
        const data = await bolev.hiPayPayment({
          orderId: this.order.id,
          patientId: this.patientId,
          marketing: this.user.marketing,
          source: "app",
          data: paymentData,
        });
        switch (data.state) {
          case "pending":
          case "completed":
            await this.copyOrder();
            this.done = true;
            break;
          case "forwarding":
            window.location.href = data.url;
            break;
          case "declined":
          case "error":
          case "unknown":
          default:
            this.payInProgress = false;
            console.log(data);
            break;
        }
      } catch (e) {
        this.payInProgress = false;
        if (e.length && e[0]) {
          this.setError(e[0].error);
        } else {
          this.setError(this.$t("checkout.payment_error"));
          console.log(e);
        }
      }
    },
  },

  computed: {
    ...mapState([
      "cart",
      "categories",
      "savedCards",
      "user",
      "error",
      "order",
      "levPoints",
      "patientId",
      "mbwayPending",
      "lastOrder",
    ]),
    ...mapGetters([
      "getActiveCard",
      "totalAmount",
      "getCheckoutCart",
      "products",
    ]),
    chosenMethod() {
      if (
        !this.active.card &&
        !this.active.mbway &&
        !this.active.mb &&
        this.totalAmount > 0
      )
        return false;
      else return true;
    },
    enabled() {
      if (this.chosenMethod && this.accepted) return true;
      else return false;
    },
    isSpanish() {
      return process.env.VUE_APP_LANG === "es";
    },
    buttonMessage() {
      if (this.done) return this.$t("checkout.payment_accepted");
      else if (this.payInProgress) return this.$t("checkout.processing");
      else return this.$t("checkout.confirm_order");
    },
  },
};
</script>

<style lang="scss" scoped>
.checkout {
  margin: 0 0 100px;
  min-height: 100vh;
  .choose {
    color: $orange;
  }
  .error_message {
    color: $orange;
    text-align: center;
    padding: 0 20px;
  }
  p {
    font-size: 12px;
    line-height: 16px;
    span {
      font-weight: 700;
    }
    ::v-deep span {
      font-weight: 700;
      text-transform: capitalize;
    }
    &::first-letter {
      text-transform: capitalize;
    }
  }
  hr {
    max-width: calc(100vw - 40px);
    height: 1px;
    border: none;
    background-color: rgba($color: #000000, $alpha: 0.1);
  }
  .padding {
    padding: 0 20px;
  }
  main {
    h4 {
      text-align: left;
    }
    .delivery {
      margin: 20px 0;
      &_same {
        margin: 20px;
        display: flex;
        h5 {
          text-transform: capitalize;
        }
        .checkbox {
          margin-right: 20px;
        }
      }
    }
    .terms {
      padding: 0 20px;
      margin: 20px 0;
      .flex {
        justify-content: space-between;
        align-items: center;
        p {
          a {
            pointer-events: initial;
            color: $brown;
            text-decoration: underline;
          }
        }
      }
    }
    .payment_method {
      margin-bottom: 20px;
      h5 {
        text-align: left;
      }
      p {
        margin-left: 20px;
        span {
          text-transform: capitalize;
        }
      }
      h4 {
        margin-left: 20px;
      }
      .lev_points,
      .multibanco,
      .mbway,
      .hipay_card {
        padding: 0 20px;
        h5 {
          text-transform: uppercase;
        }
        p {
          margin: 0 0 0 40px;
        }
        .checkbox {
          margin-right: 20px;
        }
        img {
          height: 25px;
          margin: 0 10px 5px 0;
        }
      }
      .stripe {
        .checkbox {
          margin: 0 20px;
        }
        p {
          margin: 0 0 0 70px;
        }
      }
      .multibanco,
      .mbway {
        .flex {
          padding: 5px 0;
        }
        img {
          margin-right: 10px;
        }
      }
      .flex {
        margin: 20px 0 0 0;
        position: relative;
        justify-content: left;
        align-items: center;
        h5 {
          span {
            font-weight: 700;
          }
          span {
            text-transform: capitalize;
          }
        }
      }
    }
  }
  .choose-card {
    height: 10px;
    width: 10px;
  }

  header {
    width: 100%;
    p {
      width: 85px;
    }
  }
}
</style>

<style lang="scss">
#hipay-hostedfields-form {
  margin-top: 20px;
  .hipay {
    &-form-container {
      max-width: unset;
    }
    &-field-container {
      margin: 0;
      label {
        text-align: left;
      }
    }
    &-form-row {
      gap: 1rem;
    }
    input {
      font-size: 16px;
    }
  }
}
</style>
