<template>
  <a :href="link" :style="{ width: width + 'px' }">
    <img
      class="left"
      v-if="leftIcon"
      :src="require('@/assets/' + leftIcon)"
      alt=""
    />
    <div v-if="title" class="button-bold">{{ title }}</div>
    <img class="right" v-if="rightIcon" src="" alt="" />
  </a>
</template>

<script>
//WIDTH, ICON AND LINK ARE OPTIONAL PROPS
//Pass name of img for icon, place in assets

export default {
  props: {
    title: String,
    width: Number,
    leftIcon: String,
    rightIcon: String,
    link: String,
  },
};
</script>

<style lang="scss" scoped>
a {
  cursor: pointer;
  text-transform: capitalize;
  display: flex;
  justify-content: center;
  align-items: center;
  color: $black;
  background-color: transparent;
  height: 53px;
  border-radius: $rounded;
  width: 100%;
  border: 2px solid $black;
  .left {
    margin-right: 10px;
  }
  .right {
    margin-left: 10px;
  }
}
</style>
