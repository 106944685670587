<template>
  <div
    class="container"
    :class="{ saving: saving, reload: reload, fixed: fixed }"
  >
    <div class="spinner"></div>
    <div class="spinner2"></div>
  </div>
</template>

<script>
export default {
  props: {
    saving: Boolean,
    fixed: Boolean,
    reload: Boolean,
  },
};
</script>

<style lang="scss" scoped>
.container {
  z-index: -1;
  text-align: center;
  color: #fff;
}
.spinner {
  width: 1rem;
  height: 1rem;
  display: inline-block;
  border: 3px solid $dark-yellow;
  border-radius: 50%;
  border-top-color: white;
  animation: 1s spin ease-in-out infinite;
}
.reload {
  margin: -40px 0 20px 0;
}
.fixed {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}
</style>
