<template>
  <div class="appointment-history">
    <h4 class="appointment-history__title block-title">
      {{ $t('appointments.appointment_history') }}
    </h4>
    <ul class="appointment-history__appointments">
      <template v-for="a in appointments" :key="a.id">
        <AppointmentHistoryCard
          @label-clicked="toggleOpen(a.id)"
          :open="a.open"
          :appointment="a"
        />
      </template>
    </ul>
  </div>
</template>

<script>
import AppointmentHistoryCard from '@/components/AppointmentHistoryCard.vue';
import { mapState } from 'vuex';

export default {
  components: {
    AppointmentHistoryCard,
  },
  methods: {
    toggleOpen(id) {
      this.appointments.forEach((a) => {
        a.id === id ? (a.open = !a.open) : (a.open = false);
      });
    },
  },

  computed: {
    ...mapState(['pastAppointments', 'measurements']),
    appointments() {
      return this.pastAppointments.filter((a) => {
        this.measurements.filter((m) => {
          if (a.start.includes(m.date)) {
            a.measurements = m;
          }
        });
        return a;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.appointment-history {
  padding-top: 20px;
  &__title {
    padding: 0 20px;
  }
  &__appointments {
    padding: 20px 0;
    width: 100%;
    min-height: fit-content;
  }
}
</style>
