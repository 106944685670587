<template>
  <div
    @click="$emit('label-clicked')"
    :style="contentOpenClose"
    class="food-item flex-column card-list"
  >
    <div class="food-item__label label">
      <h4 class="food-item__title">{{ phase.title }}</h4>
      <div class="food-item__arrow">
        <img
          :class="{ rotate: open }"
          src="@/assets/arrow-yellow.svg"
          alt="arrow"
        />
      </div>
    </div>

    <div ref="content" class="food-item__content content">
      <h5 v-if="phase.note" class="food-item__note">{{ phase.note }}</h5>
      <h5 v-if="phase.content.note_extra" class="food-item__note">
        {{ phase.content.note_extra }}
      </h5>
      <template v-for="item in phase.content" :key="item.title">
        <div class="food-item__content-item">
          <p class="food-item__name">{{ item }}</p>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    open: {
      type: Boolean,
      default: false,
    },
    phase: Object,

    phaseOne: [],
    phaseTwo: [],
    phaseThree: [],
    phaseFour: [],
  },

  emits: ['label-clicked'],

  computed: {
    contentOpenClose() {
      if (this.open) return { height: `${this.contentHeight + 84}px` };
      return { height: '84px' };
    },
    contentHeight() {
      return this.$refs.content.getBoundingClientRect().height;
    },
    listPhase() {
      if (this.phase.phase === 1) {
        return this.phaseOne;
      } else if (this.phase.phase === 2) {
        return this.phaseTwo;
      } else if (this.phase.phase === 3) {
        return this.phaseThree;
      } else {
        return this.phaseFour;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.food-item {
  background-color: $background-color;
  box-shadow: $card-shadow-l;
  border: none;
  border-radius: $rounded-l;
  &:not(:last-child) {
    margin-bottom: 16px;
  }
  &__label {
    img {
      transition: all $open-card ease-in-out;
      z-index: 2;
    }
    .layer-img {
      top: 25px;
      z-index: 2;
    }
  }
  &__title {
    font-size: 18px;
    font-weight: 700;
    line-height: 16px;
    text-align: left;
    text-transform: lowercase;
    margin-right: 20px;
    &::first-letter {
      text-transform: capitalize;
    }
  }
  &__arrow {
    position: absolute;
    top: 36px;
    right: 20px;
    height: 14px;
    width: 16px;
    display: flex;
    align-items: center;
    overflow: hidden;
  }
  &__note {
    grid-column-start: 1;
    grid-column-end: 3;
    padding-bottom: 15px;
    font-size: 14px;
    font-weight: 400;
    text-align: left;
  }
  &__content {
    padding-bottom: 10px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 10px;
    &-item {
      margin: 0;
      padding-bottom: 10px;
      width: 100%;
    }
  }
  &__name {
    margin: 0;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    &::first-letter {
      text-transform: capitalize;
    }
  }
  .label {
    img {
      transition: all $open-card ease-in-out;
      z-index: 2;
    }
    .layer-img {
      top: 25px;
      z-index: 2;
    }
  }
  .rotate {
    transform: rotate(180deg);
  }
}
</style>
