<template>
  <div class="receipt">
    <header class="flex overlay_header">
      <div @click="close" class="back flex">
        <img class="lev" src="@/assets/back.svg" alt="" />
      </div>
    </header>
    <main class="flex-column">
      <img src="@/assets/lev-big.svg" alt="" />
      <h2>{{ $t('receipt.thank_you_for_your_purchase') }}</h2>
      <div v-if="lastOrder.mb" class="flex-column mb">
        <p class="body-regular">
          {{
            $t('receipt.mb_instructions', {
              mb: 'MB',
              homebanking: 'HomeBanking',
            })
          }}
        </p>
        <hr />
        <div class="grid">
          <div class="flex">
            <img src="@/assets/multibanco.svg" alt="multibanco" />
          </div>
          <ul>
            <li>
              <h5 class="medium-label-thin">{{ $t('receipt.entity') }}:</h5>
              <h5 class="medium-label">{{ lastOrder.mb.entity }}</h5>
            </li>
            <hr />
            <li>
              <h5 class="medium-label-thin">{{ $t('receipt.reference') }}:</h5>
              <h5 class="medium-label">{{ reference }}</h5>
            </li>
            <hr />
            <li>
              <h5 class="medium-label-thin">{{ $t('receipt.value') }}:</h5>
              <h5 class="medium-label">{{ lastOrder.price }} €</h5>
            </li>
          </ul>
        </div>
        <hr />
        <p class="body-regular">
          {{ $t('receipt.mb_validate', { mb: 'MB' }) }}
        </p>
      </div>
      <OrderList v-if="ready" />
      <div class="flex-column delivery">
        <!-- <h4 class="medium-label">{{$t('receipt.estimated_delivery')}}</h4>
                <h4 class="medium-label-thin">{{date}}</h4> -->
        <h4 class="medium-label">{{ $t('receipt.delivery_address') }}</h4>
        <div
          v-if="deliveryAddress && deliveryAddress.length > 0"
          class="address"
        >
          <h4 class="medium-label-thin">
            {{ deliveryAddress[0].first_name }}
            {{ deliveryAddress[0].last_name }}
          </h4>
          <h4 class="medium-label-thin">{{ deliveryAddress[0].address }}</h4>
          <h4 class="medium-label-thin">
            {{ deliveryAddress[0].zip }} {{ deliveryAddress[0].city }}
          </h4>
        </div>
        <div v-else class="address">
          <h4 class="medium-label-thin">
            {{ user.first_name }} {{ user.last_name }}
          </h4>
          <h4 class="medium-label-thin">{{ user.address }}</h4>
          <h4 class="medium-label-thin">{{ user.zip }} {{ user.city }}</h4>
        </div>
      </div>
      <div class="total flex">
        <h4 class="medium-label">{{ $t('receipt.total') }}</h4>
        <h4 class="medium-label">{{ lastOrder.price }} €</h4>
      </div>
      <PrimaryButton @click="close" :title="$t('receipt.back_to_home_page')" />
    </main>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex';
import OrderList from '@/components/OrderList.vue';
import PrimaryButton from '@/components/ui/PrimaryButton.vue';
import woocommerce from '../apis/woocommerce';

export default {
  components: {
    OrderList,
    PrimaryButton,
  },
  computed: {
    ...mapState(['lastOrder', 'deliveryAddress', 'user']),
    ...mapGetters(['products']),
    reference() {
      return this.lastOrder.mb.reference.replace(
        /(?!^)(?=(?:\d{3})+(?:\.|$))/gm,
        ' '
      );
    },
  },
  methods: {
    ...mapActions(['resetCart']),
    ...mapMutations(['setProducts']),
    close() {
      this.$router.push({ path: '/' });
    },
  },
  async mounted() {
    if (this.products.length < 1) {
      const { data } = await woocommerce.getProductsSku(
        this.lastOrder.order_rows.map((p) => p.sku)
      );
      this.setProducts(data);
      this.ready = true;
    } else {
      this.ready = true;
    }
    this.resetCart();
  },
  data() {
    return {
      ready: false,
    };
  },
};
</script>

<style lang="scss" scoped>
.receipt {
  margin-top: -75px;
  padding-bottom: 100px;
  .overlay_header {
    margin-top: 30px;
  }
  main {
    hr {
      height: 1px;
      border: none;
      background-color: rgba($black, 0.2);
      width: 100%;
    }
    padding: 0 20px;
    p {
      &::first-letter {
        text-transform: capitalize;
      }
    }
    .lev {
      height: 86px;
    }
    h2 {
      font-size: 22px;
      line-height: 27px;
      margin: 40px 0;
    }
    h4 {
      text-align: left;
      letter-spacing: 1.5px;
    }
    .medium-label-thin {
      margin: 20px 0 40px 0;
      line-height: 20px;
    }
    .delivery {
      margin-top: 20px;
    }
    .mb {
      margin-bottom: 20px;
      .grid {
        width: 100%;
        display: grid;
        grid-template-columns: 1.2fr 2fr;
        .flex {
          align-items: center;
          img {
            height: 66px;
          }
        }
        ul {
          li {
            display: flex;
            justify-content: space-between;
            h5 {
              margin: 10px 0;
              display: inline;
              &:first-letter {
                text-transform: capitalize;
              }
            }
          }
        }
      }
    }
    .flex-column {
      width: 100%;
      align-items: flex-start;
    }
    .total {
      justify-content: space-between;
      width: 100%;
      margin-bottom: 20px;
    }
    .address {
      margin: 20px 0 40px 0;
      h4 {
        margin: 0;
      }
    }
  }
}
</style>
