<template>
  <div class="summary">
    <h3 class="small-label">{{ $t('checkout.summary') }}</h3>
    <div class="flex">
      <h4 class="mini-label">{{ $t('checkout.subtotal') }}</h4>
      <h4 class="mini-label">{{ subTotal }} €</h4>
    </div>
    <div class="flex">
      <h4 class="mini-label">{{ $t('checkout.shipping') }}</h4>
      <h4 v-if="!checkout && shipping" class="mini-label">
        {{ shipping.shipping.shipping }} €
      </h4>
      <h4 v-else-if="checkout" class="mini-label">
        {{ order.shipping.shipping }} €
      </h4>
    </div>
    <div v-if="checkout && order.coupons.length > 0" class="flex">
      <h4 class="mini-label">{{ $t('checkout.discounts') }}</h4>
      <div>
        <template v-for="coupon in order.coupons" :key="coupon.id">
          <h4 v-if="coupon.type === 1" class="mini-label">
            -{{ coupon.amount }} €
          </h4>
          <h4 class="mini-label">-{{ coupon.amount }} %</h4>
        </template>
      </div>
    </div>
    <div v-if="order && order.points_payment && checkout" class="flex">
      <h4 class="mini-label">
        {{ $t('checkout.lev_points') }} {{ $t('checkout.discount') }}
      </h4>
      <h4 class="mini-label">
        -{{ order.points_payment.value }} € ({{ order.points_payment.points }})
        {{ $t('checkout.lev_points') }}
      </h4>
    </div>
    <div v-if="shipping || checkout" class="flex">
      <h3 class="small-label">{{ $t('checkout.total') }}</h3>
      <h3 v-if="!checkout" class="small-label">{{ totalAmount }} €</h3>
      <h3 v-else-if="order" class="small-label">{{ order.price }} €</h3>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState, mapActions } from 'vuex';
export default {
  computed: {
    ...mapGetters(['subTotal', 'totalAmount']),
    ...mapState(['shipping', 'order', 'levPoints']),
  },

  props: {
    checkout: {
      type: Boolean,
      default: false,
    },
  },

  methods: {
    ...mapActions(['getShipping']),
  },

  async mounted() {
    if (!this.checkout) {
      await this.getShipping();
    }
  },
};
</script>

<style lang="scss" scoped>
.summary {
  margin-bottom: 20px;
}
h3,
h4 {
  margin: 10px 0;
}
h3 {
  text-align: start;
}
h4 {
  opacity: 0.6;
}
p {
  margin: 0;
}
.flex {
  justify-content: space-between;
  h4:first-child {
    text-align: left;
  }
  h4:last-child {
    text-align: right;
  }
}
</style>
