<template>
  <div class="mbway_pending flex-column">
    <img src="@/assets/mbway.svg" alt="mbway.svg" />
    <h2>{{ $t('mbway.title') }}</h2>
    <h5>{{ timer }}</h5>
    <p>{{ $t('mbway.text') }}</p>
    <SecondaryButton
      :title="$t('mbway.cancel')"
      @click="setMbwayPending(false)"
    />
  </div>
</template>

<script>
import { mapMutations } from 'vuex';
import SecondaryButton from '@/components/ui/SecondaryButton.vue';

export default {
  name: 'MbwayPending',

  components: {
    SecondaryButton,
  },

  data() {
    return {
      time: 300000,
      timeout: null,
    };
  },

  computed: {
    timer() {
      const minutes = Math.floor(this.time / 60000);
      const seconds = ((this.time % 60000) / 1000).toFixed(0);
      return `${minutes} : ${seconds < 10 ? '0' : ''}${seconds}`;
    },
  },

  watch: {
    time(value) {
      if (value < 1000) {
        clearInterval(this.interval);
        this.setMbwayPending(false);
      }
    },
  },

  mounted() {
    document.body.classList.add('no-scroll');
    this.startTimer();
  },

  beforeUnmount() {
    document.body.classList.remove('no-scroll');
    clearInterval(this.interval);
  },

  methods: {
    ...mapMutations(['setMbwayPending']),

    startTimer() {
      this.interval = setInterval(() => {
        this.time = this.time - 1000;
      }, 1000);
    },
  },
};
</script>

<style lang="scss" scoped>
.mbway_pending {
  background-color: $white;
  align-items: center;
  box-shadow: 0 0 50px rgba(0, 0, 0, 0.2);
  padding: 40px 20px;
  width: 90vw;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: $white;
  z-index: 999;
  h2,
  h5,
  p {
    margin: 20px 0;
  }
}
</style>
