<template>
  <div class="home flex-column">
    <section class="upcoming-appointment">
      <AppointmentCard
        :appointment="nextAppointment"
        :text="nextAppointmentString"
      />
    </section>
    <section
      class="progress"
      v-if="phase && measurements && measurements.length > 0"
    >
      <h2 class="block-title slider-title">{{ $t('progress.progress') }}</h2>
      <LevContainer @click.stop>
        <div
          class="circle-card flex-column"
          @click="setValuesOpenChart(weight, strings.weight, 'kg')"
        >
          <h4 class="small-label">{{ strings.weight }}</h4>
          <CircleChart class="circle" :value="percent" :isSmall="true" />
          <h5 class="medium-label">
            {{
              numberFormat(
                measurements[measurements.length - 1].current_weight
              )
            }}kg
          </h5>
        </div>
        <LineCardSmall
          @click="setValuesOpenChart(bodyFat, strings.bodyFat, '%')"
          :bodyData="bodyFatFiltered"
          :unit="'%'"
          :title="strings.bodyFat"
          :big="true"
        />
        <LineCardSmall
          @click="setValuesOpenChart(bmi, strings.bmi, '')"
          :bodyData="bmiFiltered"
          :unit="''"
          :title="strings.bmi"
          :big="true"
        />
      </LevContainer>
    </section>
    <section class="promotions">
      <div v-if="promotions" class="cards">
        <h2 class="block-title slider-title">{{ $t('news.news') }}</h2>
        <LevContainer>
          <CustomLink
            v-for="promo in promotions"
            :key="promo.id"
            :to="promoLink(promo.fields)"
          >
            <div class="card">
              <div v-if="promo.fields.image" class="img-wrapper">
                <img
                  :src="promo.fields.image.sizes.medium"
                  :alt="promo.fields.image.title"
                />
              </div>
              <p class="promotions__title">{{ promo.fields.title }}</p>
            </div>
          </CustomLink>
        </LevContainer>
      </div>
      <div v-else class="cards">
        <div :style="backgroundColor('')" class="card">
          <div class="titles">
            <h5>{{ $t('news.no_current_news') }}</h5>
          </div>
        </div>
      </div>
    </section>

    <section v-if="posts" class="posts cards">
      <h2 class="block-title slider-title">{{ $t('posts.posts') }}</h2>
      <LevContainer class="cards">
        <CustomLink v-for="post in posts" :key="post.id" :to="post.link">
          <div class="card">
            <div v-if="post.acf.image" class="img-wrapper">
              <img
                :src="post.acf.image.sizes.medium"
                :alt="post.acf.image.title"
              />
            </div>
            <p class="post__title">{{ post.title.rendered }}</p>
          </div>
        </CustomLink>
      </LevContainer>
    </section>
  </div>
</template>

<script>
import AppointmentCard from '@/components/AppointmentCard';
import LevContainer from '@/components/LevContainer.vue';
import LineCardSmall from '@/components/LineCardSmall.vue';
import { mapActions, mapState } from 'vuex';
import CircleChart from '@/components/CircleChart.vue';
import CustomLink from '@/components/ui/Link.vue';
import shared from '@/shared';

export default {
  name: 'home',
  components: {
    AppointmentCard,
    LevContainer,
    LineCardSmall,
    CircleChart,
    CustomLink,
  },

  created() {
    this.numberFormat = shared.numberFormat;
  },

  data() {
    return {
      nextAppointmentString: this.$t('appointmentCard.your_next_appointment'),
      strings: {
        weight: this.$t('progress.weight'),
        bmi: this.$t('progress.bmi'),
        bodyFat: this.$t('progress.body_fat'),
      },
    };
  },

  computed: {
    ...mapState(['promotions', 'measurements', 'phase', 'posts']),
    percent() {
      const percent = Math.abs(
        (this.difference /
          (this.weightFiltered[0] -
            this.measurements[this.measurements.length - 1].target_weight)) *
          100
      );
      if (percent < 0) {
        return 0;
      } else if (percent > 100) {
        return 100;
      } else return percent;
    },
    difference() {
      return (
        this.weightFiltered[this.weightFiltered.length - 1] -
        this.weightFiltered[0]
      );
    },
    nextAppointment() {
      return this.$store.state.futureAppointments[0];
    },
    weight() {
      return this.measurements
        .filter((m) => m.current_weight !== null)
        .map((m) => ({
          value: m.current_weight,
          date: m.date,
        }));
    },
    weightFiltered() {
      return this.weight.map((item) => item.value);
    },
    bodyFat() {
      return this.measurements
        .filter((m) => m.body_fat !== null)
        .map((m) => ({
          value: m.body_fat,
          date: m.date,
        }));
    },
    bodyFatFiltered() {
      return this.bodyFat.map((item) => item.value);
    },
    bmi() {
      return this.measurements
        .filter((m) => m.bmi !== null)
        .map((m) => ({
          value: m.bmi,
          date: m.date,
        }));
    },
    bmiFiltered() {
      return this.bmi.map((item) => item.value);
    },
  },

  methods: {
    ...mapActions(['toggle', 'openChart']),
    navigatePromo(link) {
      if (link) {
        window.location.href = link;
      }
    },
    setValuesOpenChart(type, title, unit) {
      this.openChart({ data: type, title: title, unit: unit });
    },
    promoLink(fields) {
      return fields.product_link
        ? `/product/` + fields.product_link.post_name
        : fields.link;
    },
  },
};
</script>

<style lang="scss" scoped>
section:last-child {
  padding-bottom: 50px;
}
.block-title {
  text-align: left;
  padding: 0 20px;
}
.circle-card {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 12px;
  min-width: 210px;
  height: 210px;
  margin: 10px 15px 10px 0;
  position: relative;
  box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.08);
  border-radius: $rounded-l;
  background-color: $background-color-light;
  h4,
  h5 {
    opacity: 0.8;
  }
  &::after {
    background: linear-gradient(
      to right,
      transparent calc(100% - 1px),
      rgba($grey, 0.1) 0
    );
    background-size: calc((100% - 5 * 5px) / 8 + 5px) 100%;
    height: 100%;
    width: 100%;
    position: absolute;
    content: '';
    top: 0;
    left: 0;
    z-index: 0;
  }
  > * {
    z-index: 1;
  }
}
.upcoming-appointment {
  width: 100vw;
  min-height: 140px;
  padding: 15px 10px;
  background-color: $background-color-peach;
}
.progress {
  padding-top: 10px;
  background-color: $background-color-peach;
}
.posts {
  padding-top: 24px;
  background-color: $background-color-light-ginger;
}
.promotions {
  background-color: $background-color;
}
.promotions,
.posts {
  width: 100%;
  padding-top: 24px;
  &__title {
    max-width: 250px;
    font-size: 16px;
    line-height: 18px;
    font-weight: 400;
    text-align: left;
  }
  .cards {
    width: 100%;
    display: flex;
    flex-direction: column;
    .card_dark {
      .titles {
        h5 {
          color: $black !important;
          text-align: left;
        }
      }
      .promo_arrow {
        filter: brightness(0) invert(0) !important;
      }
    }
    a:not(:last-child) {
      margin-right: 14px;
    }
    .card {
      box-shadow: unset;
      width: 100%;
      border-radius: $rounded;
      filter: $card-shadow;
      align-items: center;
      margin-bottom: 10px;
      overflow: hidden;
      .post__title {
        max-width: 150px;
        text-align: left;
        margin-top: 20px;
      }
      .promo_arrow {
        transform: rotate(-90deg);
        margin-right: 16px;
        width: 16px;
        filter: brightness(0) invert(1);
      }
      .img-wrapper {
        height: 220px;
        width: 210px;
        display: flex;
        justify-content: center;
        align-items: center;
        object-fit: cover;
        overflow: hidden;
        img {
          height: 100%;
          width: auto;
        }
      }
    }
  }
}
</style>
