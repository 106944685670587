<template>
  <a
    :class="{ disabled: disabled, save: saving }"
    :href="link"
    :style="{ width: width + 'px' }"
  >
    <Spinner class="left" v-if="saving" :saving="saving" />
    <img
      class="left"
      v-if="leftIcon"
      :src="require('@/assets/' + leftIcon)"
      alt=""
    />
    <div v-if="title" class="button-bold">{{ title }}</div>
    <img class="right" v-if="rightIcon" src="" alt="" />
    <img
      class="right finish"
      v-if="finish"
      src="@/assets/checked-green.svg"
      alt=""
    />
  </a>
</template>

<script>
import Spinner from '@/components/ui/Spinner.vue';

//WIDTH, ICON AND LINK SAVING AND DISABLED ARE OPTIONAL PROPS
//Pass name of img for icon, place in assets

export default {
  props: {
    title: String,
    width: Number,
    leftIcon: String,
    rightIcon: String,
    link: String,
    disabled: Boolean,
    saving: Boolean,
    finish: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    Spinner,
  },
};
</script>

<style lang="scss" scoped>
a {
  cursor: pointer;
  transition: none;
  display: flex;
  justify-content: center;
  align-items: center;
  color: $white;
  background-color: $dark-yellow;
  height: 53px;
  border-radius: $rounded;
  width: 100%;
  filter: $card-shadow;
  .left {
    margin-right: 10px;
    margin-top: 2px;
  }
  .right {
    margin-left: 10px;
  }
  .button-bold {
    &::first-letter {
      text-transform: uppercase;
    }
  }
  &:active {
    opacity: 0.9;
    transform: scale(0.99);
  }
}
.disabled {
  background-color: $grey;
  &:active {
    background-color: #888888;
    transform: scale(0.99);
  }
}
.save {
  background-color: $accent-yellow;
  pointer-events: none;
}
.finish {
  animation: finish 1s forwards;
  opacity: 0;
}

@keyframes finish {
  0% {
    opacity: 0;
    transform: scale(1);
  }
  50% {
    opacity: 1;
    transform: scale(1.2);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}
</style>
