<template>
  <div v-click-outside="notActive" class="input-wrapper">
    <label
      @click="notActive"
      :class="{ show_label: active }"
      class="small-label-thin"
      for="password"
      >{{ placeholder }}</label
    >
    <input
      @click="setActive"
      :id="placeholder"
      :placeholder="placeholder"
      @input="$emit('type', password)"
      name="password"
      :type="getInputType"
      v-on:keyup.enter="$emit('enter-clicked')"
      v-model="password"
    />
    <h6 @click="toggleShow" v-if="password" class="small-label-thin show">
      {{ showHide }}
    </h6>
  </div>
</template>

<script>
import clickOutside from '@/directives/ClickOutside';

export default {
  props: {
    placeholder: String,
  },
  data() {
    return {
      password: null,
      active: false,
      show: false,
    };
  },
  directives: {
    clickOutside: clickOutside,
  },
  emits: ['type', 'enter-clicked'],
  methods: {
    setActive() {
      this.active = true;
    },
    notActive() {
      this.active = false;
    },
    toggleShow() {
      this.show = !this.show;
      let input = document.getElementById(this.placeholder);
      input.focus();
      setTimeout(() => {
        input.setSelectionRange(this.password.length, this.password.length);
      }, 1);
    },
  },
  computed: {
    showHide() {
      if (this.show) {
        return 'Hide';
      } else return 'Show';
    },
    getInputType() {
      if (!this.show) {
        return 'password';
      } else return 'text';
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/styles/_mixins';

div {
  @include input;
  h6 {
    pointer-events: initial;
  }
}
</style>
