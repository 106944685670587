<template>
  <div class="mylev">
    <NavTop :title="title" />
    <Home ref="home" />
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import NavTop from '@/components/NavTop';
import Home from '@/pages/Home';

export default {
  name: 'MyLev',

  components: {
    NavTop,
    Home,
  },

  data() {
    return {
      timeout: null,
      touch: {
        xDif: 0,
        yDif: 0,
        long: false,
        startX: 0,
        endX: 0,
        startY: 0,
        endY: 0,
      },
      movement: 0,
      marginLeft: '160px',
      lineWidth: '0px',
      containerHeight: 1000,
      customHeight: 'auto',
      welcome: this.$t('home.welcome'),
    };
  },

  computed: {
    ...mapState(['user', 'contactOverlay', 'onboarding']),
    title() {
      return this.$t('home.title');
    },
  },
  methods: {
    ...mapActions(['getUserData', 'getPrices', 'getProducts']),
  },
};
</script>

<style lang="scss" scoped>
.mylev {
  position: relative;
  overflow-x: hidden;
  height: 100%;

  .home {
    // padding: 20px 20px 100px 20px;
    padding: 80px 0px 55px 0px;
    width: 100vw;
    position: relative;
    overflow-x: hidden;
    max-width: 860px;
  }
}
</style>
