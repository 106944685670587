<template>
  <transition :name="transitionName" mode="out-in">
    <slot></slot>
  </transition>
</template>
<script>
import { mapState } from 'vuex';

export default {
  props: {
    transitionName: String,
  },
  computed: {
    ...mapState(['animationStartPos']),
  },
  watch: {
    animationStartPos: function () {
      let root = document.documentElement;
      root.style.setProperty('--movement', this.animationStartPos + 'px');
    },
  },
};
</script>

<style lang="scss" scoped>
//Time
$duration: 0.2s;

//Fade
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.1s ease-in-out;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}

//Slide up
.slide-up-enter-active {
  animation: slide-in-from-bottom $duration ease-out forwards;
  transition: opacity $duration;
}
.slide-up-leave {
  opacity: 1;
}
.slide-up-leave-active {
  animation: slide-out-to-bottom $duration ease-out forwards;
  transition: opacity $duration;
}
@keyframes slide-in-from-bottom {
  from {
    transform: translateY(100vh);
  }
  to {
    transform: translateY(0);
  }
}
@keyframes slide-out-to-bottom {
  from {
    transform: translateY(var(--movement));
  }
  to {
    transform: translateY(100vh);
  }
}
//Slide left
.slide-left-enter-active {
  animation: slide-in-from-right $duration ease-out forwards;
  transition: opacity $duration;
}
.slide-left-leave {
  opacity: 1;
}
.slide-left-leave-active {
  animation: slide-out-to-right $duration ease-out forwards;
  transition: opacity $duration;
}
@keyframes slide-in-from-right {
  from {
    transform: translateX(100vw);
  }
  to {
    transform: translateX(0);
  }
}
@keyframes slide-out-to-right {
  from {
    transform: translateX(var(--movement));
  }
  to {
    transform: translateX(100vw);
  }
}

// Slide right
.slide-right-enter-active {
  animation: slide-in-from-left $duration ease-out forwards;
  transition: opacity $duration;
}
.slide-right-leave {
  opacity: 1;
}
.slide-right-leave-active {
  animation: slide-out-to-left $duration ease-out forwards;
  transition: opacity $duration;
}
@keyframes slide-in-from-left {
  from {
    transform: translateX(-100vw);
  }
  to {
    transform: translateX(0);
  }
}
@keyframes slide-out-to-left {
  from {
    transform: translateX(var(--movement));
  }
  to {
    transform: translateX(-100vw);
  }
}
</style>
