<template>
  <NavTop v-if="product" ref="search" :product="product" />
  <div v-if="product" class="product">
    <div class="product__breadcrumbs">
      <Breadcrumbs :category="getCategory" :product="product.name" />
    </div>
    <div class="content">
      <h1 class="product-title">{{ product.name }}</h1>
      <div v-if="product.tags.length > 0" class="tags">
        <h6
          @click="openTag(tag.id)"
          v-for="tag in product.tags"
          :key="tag.id"
          class="small-label"
        >
          {{ tag.name }}
        </h6>
      </div>
      <SwipeContainer
        v-if="product.images.length > 1"
        :images="product.images"
      />
      <div v-else class="img-wrapper">
        <img :src="product.images[0].src" :alt="product.images[0].name" />
      </div>
      <div class="product__price flex prices">
        <div class="flex">
          <h5 v-if="product.on_sale" class="bold-label sale">
            {{ getActualPrice(product.sku) }} €
          </h5>
          <h5 :class="{ strike: product.on_sale }" class="bold-label">
            {{ getPrice(product.sku) }} €
          </h5>
        </div>
        <Stock
          v-if="!stock || (!stock.in_stock && stock.stock < 1)"
          :stock="stock"
        />
        <Counter
          v-else
          :stock="stock.stock"
          :width="40"
          :quantity="itemQuantity(product.sku)"
          @increase="add"
          @decrease="remove"
          @add-custom="addCustom"
        />
      </div>
      <div
        :class="{ product__closed: !showText }"
        class="product__description short"
        @click="showText = true"
      >
        <div v-html="product.description" />
        <img v-if="!showText" src="@/assets/arrow.svg" alt="show_more" />
      </div>
      <hr />
      <div v-for="info in productInfo" :key="info.title" class="product__info">
        <Sheet
          v-if="info.info && info.info.length > 0"
          :info="info"
          @labelclicked="toggleOpen"
        />
      </div>
    </div>
    <div class="product__similar">
      <h2 class="product__similar-title" v-html="$t('shop.similar')" />
      <ProductList
        class="product__similar-list"
        :small="false"
        :products="relatedProducts"
        :shop="true"
        :activeTags="activeTags"
      ></ProductList>
    </div>
  </div>
</template>

<script>
import Counter from '@/components/ui/Counter.vue';
import Sheet from '@/components/Sheet.vue';
import NavTop from '@/components/NavTop.vue';
import Stock from '@/components/Stock.vue';
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex';
import SwipeContainer from '@/components/ui/SwipeContainer.vue';
import woocommerce from '../apis/woocommerce';
import Breadcrumbs from '@/components/ui/Breadcrumbs.vue';
import ProductList from '@/components/ProductList.vue';

export default {
  components: {
    Counter,
    Sheet,
    NavTop,
    Stock,
    SwipeContainer,
    Breadcrumbs,
    ProductList,
  },
  data() {
    return {
      product: null,
      productInfo: [],
      relatedProducts: [],
      showText: false,
    };
  },
  methods: {
    ...mapActions(['close', 'setCategory']),
    ...mapMutations(['setOneTagOnly', 'setProducts', 'setProduct']),

    async getRelatedProducts() {
      const { data } = await woocommerce.getProductsId(this.relatedIds);
      this.relatedProducts = [...data];
      this.setProducts(data);
    },

    openTag(id) {
      this.setOneTagOnly(id);
      this.$router.push({ path: '/shop' });
    },

    add() {
      this.$store.commit('addToCart', this.product.sku);
    },

    remove() {
      this.$store.commit('removeFromCart', this.product.sku);
    },

    toggleOpen(title) {
      this.productInfo.forEach((p) => {
        p.title === title ? (p.open = !p.open) : (p.open = false);
      });
    },

    async addCustom(q) {
      await this.$store.commit('addCustomToCart', {
        quantity: q,
        sku: this.product.sku,
      });
      if (this.small) {
        this.getShipping();
      }
    },

    goToProduct(id) {
      this.$router.push({ path: `/product/${id}` });
    },
  },
  computed: {
    ...mapGetters([
      'getProduct',
      'itemQuantity',
      'getStock',
      'getPrice',
      'getActualPrice',
      'products',
    ]),
    ...mapState(['cartOverlay', 'tags']),

    getCategory() {
      let bottomLevel = this.product.categories.find((c) => c.parent !== 0);
      if (bottomLevel) {
        return bottomLevel;
      } else return this.product.categories[0];
    },

    stock() {
      return this.product?.sku ? this.getStock(this.product.sku) : null;
    },

    relatedIds() {
      const array = this.product
        ? [...this.crossAndUpsell, ...this.product.related_ids]
        : [];
      return [...new Set(array)].slice(0, 6);
    },

    crossAndUpsell() {
      return this.product
        ? [...this.product.upsell_ids, ...this.product.cross_sell_ids].sort(
            () => (Math.random() > 0.5 ? 1 : -1)
          )
        : [];
    },

    activeTags() {
      return this.tags.filter((t) => t.active);
    },
  },
  watch: {
    relatedIds(value) {
      if (value.length) {
        this.getRelatedProducts();
      }
    },
  },
  async mounted() {
    const { data } = await woocommerce.getProduct(this.$route.params.slug);
    this.product = { ...data[0] };
    this.setProduct(data[0]);
    setTimeout(() => {
      if (this.product.acf && this.product.acf.dropdown) {
        this.productInfo = this.product.acf.dropdown.map((d) => {
          return {
            title: d.dropdown_title,
            info: d.dropdown_content,
            open: false,
          };
        });
      }
      if (this.product.acf && this.product.acf.product_links) {
        this.productInfo.push({
          title: this.$t('shop.recipes'),
          info: this.product.acf.product_links,
          open: false,
        });
      }
      this.setCategory(this.getCategory);
    }, 1000);
    this.close('cart');
  },
};
</script>

<style lang="scss" scoped>
.product {
  min-height: 100vh;
  padding-bottom: 150px;
  padding-top: 80px;
  &__price {
    position: fixed;
    left: 0;
    bottom: calc(55px + env(safe-area-inset-bottom, 0px));
    width: 100vw;
    min-height: 50px;
    margin: 0;
    padding: 16px;
    justify-content: space-between;
    align-items: center;
    background-color: $background-color;
    box-shadow: $shadow;
    z-index: 3;
  }
  .product__closed {
    max-height: 250px;
    overflow: hidden;
    position: relative;
    cursor: pointer;
    img {
      position: absolute;
      bottom: 0;
      width: 20px;
      left: 50%;
      z-index: 2;
      transform: translateX(-50%);
    }
    &::after {
      content: '';
      z-index: 1;
      height: 100px;
      width: 100%;
      position: absolute;
      bottom: 0;
      background: rgb(255, 255, 255);
      background: linear-gradient(
        180deg,
        rgba($background-color, 0) 0%,
        $background-color 80%
      );
    }
  }
  &__breadcrumbs {
    padding: 16px 20px 15px;
    width: 100%;
  }
  &__description ::v-deep {
    font-family: $font;
    h3,
    h4 {
      font-weight: 600;
      line-height: 1.5;
      margin: 10px 0;
    }
    ul {
      font-family: inherit;
      font-size: inherit;
      margin: 20px 0;
      li {
        padding-left: 40px;
        font-size: 1rem;
        font-weight: 400;
        line-height: 1.5;
        margin-bottom: 10px;
        background: url(~@/assets/arrow-right.svg) no-repeat top 2px left;
      }
    }
  }
  &__info ::v-deep {
    p {
      line-height: 1.5;
      letter-spacing: 0;
    }
  }
  &__similar {
    padding: 0 20px;
    &-title {
      font-size: 26px;
      line-height: 30px;
      letter-spacing: initial;
      font-weight: 500;
      text-align: left;
      text-transform: capitalize;
      margin-bottom: 20px;
    }
    &-list ::v-deep {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-column-gap: 10px;
      .product-card .info {
        height: 134px;
      }
    }
  }

  .img-wrapper {
    height: 40vh;
    width: 100%;
    padding: 20px;
    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }

  .swiper-container {
    margin-top: 20px;
  }

  .content {
    margin-bottom: 30px;
    .short {
      margin: 20px 0 32px;
      font-size: 16px;
      line-height: 1.5;
    }
    hr {
      margin: 0 0 0 -20px;
      height: 1px;
      border: none;
      width: calc(100% + 40px);
      background-color: rgba($color: #000000, $alpha: 0.05);
    }
    p {
      margin: 0;
      text-transform: capitalize;
    }
    padding: 20px 20px 0 20px;
    h1 {
      margin-bottom: 10px;
      text-transform: capitalize;
    }
    .tags {
      display: flex;
      flex-wrap: wrap;
      .small-label {
        display: inline-block;
        margin-right: 5px;
        margin-bottom: 8px;
        padding: 8px;
        font-size: 12px;
        font-weight: 500;
        line-height: 14px;
        letter-spacing: initial;
        color: $brown;
        white-space: nowrap;
        background: $light-yellow;
        border-radius: 160px;
        pointer-events: initial;
      }
    }
    .prices {
      h5 {
        text-align: left;
        margin-right: 10px;
      }
      .sale {
        color: $red-sale;
      }
      .strike {
        text-decoration: line-through;
      }
    }
    .how-to {
      margin: 20px 0;
      h4 {
        text-align: left;
      }
      p {
        margin: 20px 0;
      }
    }
  }
}
</style>
