<template>
  <div :class="{ round: round }" @click="$emit('clicked')" class="checkbox">
    <div :class="{ active: active }" class="inner"></div>
  </div>
</template>

<script>
export default {
  props: {
    active: {
      type: Boolean,
      default: false,
    },
    round: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['clicked'],
};
</script>

<style lang="scss" scoped>
.round {
  border-radius: 50% !important;
  .inner {
    border-radius: 50% !important;
  }
}
.checkbox {
  height: 20px;
  min-width: 20px;
  border: 3px solid $brown;
  border-radius: 2px;
  padding: 1px;
  .inner {
    height: 100%;
    width: 100%;
    transition: 0.2s ease-in all;
  }
  .active {
    background-color: $brown;
  }
}
</style>
