<template>
  <div
    class="small-line flex-column"
    :class="{ inactive: bodyData.length < 1, 'small-line-big': big }"
  >
    <h4 class="small-label">{{ title }}</h4>
    <div v-if="bodyData.length > 1" class="poly">
      <PolyLine
        :bodyData="bodyData"
        class="graph"
        :firstInt="firstInt"
        :lastInt="lastInt"
      />
    </div>
    <div v-if="bodyData.length > 0" class="flex">
      <h5 class="medium-label">{{ lastInt }}{{ unit }}</h5>
      <h5 class="medium-label">
        {{ positive }}{{ numberFormat(difference) }}{{ unit }}
      </h5>
    </div>
    <div v-else class="no-data flex-column">
      <h5 class="medium-label">Not enough data</h5>
    </div>
  </div>
</template>

<script>
import shared from '@/shared.js';
import PolyLine from '@/components/PolyLine.vue';

export default {
  components: {
    PolyLine,
  },
  props: {
    bodyData: Array,
    unit: String,
    title: String,
    big: {
      type: Boolean,
      default: false,
    },
  },
  created() {
    this.numberFormat = shared.numberFormat;
  },
  data() {
    return {
      numberOfPoints: this.bodyData.length,
      firstInt: parseFloat(this.bodyData[0]),
    };
  },
  computed: {
    lastInt() {
      return parseFloat(this.bodyData[this.numberOfPoints - 1]);
    },
    difference() {
      return this.lastInt - this.firstInt;
    },
    positive() {
      if (this.difference > 0) {
        return `+`;
      } else return '';
    },
  },
};
</script>

<style lang="scss" scoped>
.inactive {
  pointer-events: none;
}
.small-line {
  width: 50vw;
  max-width: 160px;
  min-height: 140px;
  margin: 10px 0;
  padding: 12px;
  align-items: flex-start;
  justify-content: space-between;
  box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.08);
  border-radius: $rounded-l;
  background-color: $background-color-light;
  cursor: pointer;
  position: relative;
  &::after {
    background: linear-gradient(
      to right,
      transparent calc(100% - 1px),
      rgba($grey, 0.1) 0
    );
    background-size: calc((100% - 5 * 5px) / 8 + 5px) 100%;
    height: 100%;
    width: 100%;
    position: absolute;
    content: '';
    top: 0;
    left: 0;
    z-index: 0;
  }
  > * {
    z-index: 1;
  }
  &:nth-child(odd) {
    margin-right: 15px;
  }
  .poly {
    height: 45px;
    width: 100%;
  }
  hr {
    margin: 10px 0 0 0;
    width: 100%;
    border: none;
    height: 1px;
    background-color: $black;
    opacity: 0.1;
  }
  h5,
  h4 {
    margin: 0;
    opacity: 0.8;
  }
  div {
    width: 100%;
    justify-content: space-between;
  }
  .no-data {
    justify-content: center;
    height: 100%;
    h5 {
      color: $dark-yellow;
    }
  }
}
.small-line-big {
  margin-right: 15px;
  min-width: 210px;
  min-height: 210px;
  &:last-of-type {
    margin-right: 0;
  }
  .poly {
    height: 60px;
    overflow: hidden;
  }
}
</style>
