import axios from "axios";
import store from "../store";
import Echo from "laravel-echo";

const login = async (payload) => {
  try {
    const { data } = await axios.post(
      `${process.env.VUE_APP_BOLEV_URL}/oauth/token`,
      {
        grant_type: "password",
        client_id: process.env.VUE_APP_BOLEV_CLIENT_ID,
        client_secret: process.env.VUE_APP_BOLEV_CLIENT_SECRET,
        username: payload.email,
        password: payload.password,
        scope: "",
      }
    );
    return data;
  } catch (err) {
    throw new Error("login error");
  }
};

const phaseData = async (locale) => {
  try {
    const { data } = await axios.get(
      `${process.env.VUE_APP_BOLEV_URL}/api/lang/phases/${locale}.json`
    );
    return data;
  } catch (err) {
    throw new Error("error");
  }
};

const apiCall = async ({ endpoint, method, payload }) => {
  method = method || "get";
  try {
    const { data } = await axios({
      method: method,
      url: `${process.env.VUE_APP_BOLEV_URL}/api/${endpoint}`,
      headers: {
        Authorization: `Bearer ${store.state.token}`,
      },
      data: payload,
    });
    return data;
  } catch (err) {
    if (err.response.status === 404) {
      throw new Error("not found");
    } else if (err.response.status === 401) {
      throw new Error("unauthorized");
    } else {
      throw new Error(err);
    }
  }
};

const getUser = async () => await apiCall({ endpoint: "user" });

const getProducts = async () => await apiCall({ endpoint: "products" });

const getPrices = async () => await apiCall({ endpoint: "prices" });

const makeOrder = async (payload) => {
  try {
    const order = await apiCall({
      endpoint: "order",
      method: "post",
      payload: payload,
    });
    return order;
  } catch (err) {
    throw new Error("error");
  }
};

const updateOrder = async (payload) => {
  try {
    const order = await apiCall({
      endpoint: "order",
      method: "put",
      payload: payload,
    });
    return order;
  } catch (err) {
    throw new Error("error");
  }
};

const getOrder = async (payload) => {
  try {
    const order = await apiCall({
      endpoint: `order/${payload.orderId}`,
      method: "get",
      payload: payload,
      guest: !payload.patientId,
    });
    return order;
  } catch (err) {
    throw new Error("error");
  }
};

const applyCoupon = async (payload) => {
  try {
    const res = await apiCall({
      endpoint: "coupon",
      method: "post",
      payload: payload,
    });
    return res;
  } catch (err) {
    throw new Error("error");
  }
};

const applyLevPoints = async (payload) => {
  try {
    const res = await apiCall({
      endpoint: "levpoints",
      method: "post",
      payload: payload,
    });
    return res;
  } catch (err) {
    throw new Error("error");
  }
};

const deleteCard = async (cardId) => {
  try {
    const savedCards = await apiCall({
      endpoint: "deleteCard",
      method: "post",
      payload: { cardId },
    });
    return savedCards;
  } catch (err) {
    throw new Error("error");
  }
};

const setDelivery = async (payload) => {
  try {
    const data = await apiCall({
      endpoint: "delivery",
      method: "post",
      payload: payload,
    });
    return data;
  } catch (err) {
    throw new Error(err);
  }
};

const changeAddress = async (payload) => {
  try {
    const data = await apiCall({
      endpoint: "customer",
      method: "put",
      payload: payload,
    });
    return data;
  } catch (err) {
    throw new Error(err);
  }
};

const changeDelivery = async (payload) => {
  try {
    const data = await apiCall({
      endpoint: "delivery",
      method: "put",
      payload: payload,
    });
    return data;
  } catch (err) {
    throw new Error(err);
  }
};

const getShipping = async (payload) => {
  try {
    const data = await apiCall({
      endpoint: "shipping",
      method: "post",
      payload: payload,
    });
    return data;
  } catch (err) {
    throw new Error(err);
  }
};

const makePaymentIntent = async ({ cardId, orderId, marketing }) => {
  try {
    const data = await apiCall({
      endpoint: "paymentIntent",
      method: "post",
      payload: { cardId, orderId, marketing },
    });
    return data;
  } catch (err) {
    throw new Error("payment error");
  }
};

const initiateMultibanco = async ({ orderId, marketing }) => {
  try {
    const data = await apiCall({
      endpoint: "initiateMultibanco",
      method: "post",
      payload: { orderId, marketing },
    });
    return data;
  } catch (err) {
    throw new Error("payment error");
  }
};

const hiPayPayment = async (payload) => {
  try {
    const data = await apiCall({
      endpoint: "hipay",
      method: "post",
      payload,
    });
    return data;
  } catch (err) {
    throw new Error("payment error");
  }
};

const hiPayCheckStatus = async (payload) => {
  try {
    const data = await apiCall({
      endpoint: "hipay/poll",
      method: "post",
      payload,
    });
    return data;
  } catch (err) {
    throw new Error("payment error");
  }
};

const registerPayment = async ({ payment }) => {
  await apiCall({
    endpoint: "registerPayment",
    method: "post",
    payload: { payment },
  });
};

const setupIntent = async ({ patientId }) => {
  try {
    const intent = await apiCall({
      endpoint: "setupIntent",
      method: "post",
      payload: { patientId },
    });
    return intent;
  } catch (err) {
    throw new Error("stripe error");
  }
};

const saveCard = async ({ patientId, paymentMethod, intent }) => {
  try {
    const saved = await apiCall({
      endpoint: "saveCard",
      method: "post",
      payload: { patientId, paymentMethod, intent },
    });
    return saved;
  } catch (err) {
    throw new Error("stripe error");
  }
};

const uploadImage = async (form) => {
  const data = await axios
    .post(`${process.env.VUE_APP_BOLEV_URL}/api/userImage`, form, {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${store.state.token}`,
      },
    })
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      throw new Error(err.response.status);
    });
  return data;
};

const requestResetPassword = async (email) =>
  await apiCall({
    endpoint: "password/forgot",
    method: "post",
    payload: {
      email: email,
    },
  });

const resetPassword = async (payload) => {
  try {
    await apiCall({
      endpoint: "password/reset",
      method: "post",
      payload: payload,
    });
  } catch (err) {
    console.log(err.message);
    throw new Error("Unvalid token");
  }
};

const isSpanish = () => {
  return process.env.VUE_APP_LANG === 'es';
}

const getSocketsClient = async () =>
  new Echo({
    broadcaster: "pusher",
    key: process.env.VUE_APP_PUSHER_KEY,
    wsHost: process.env.VUE_APP_BOLEV_URL.replace(/http(s?):\/\//, ""),
    wsPort: isSpanish ? 6002 : 6001,
    wssHost: process.env.VUE_APP_BOLEV_URL.replace(/http(s?):\/\//, ""),
    wssPort: isSpanish ? 6002 : 6001,
    forceTLS: false,
    disableStats: true,
    enabledTransports: ["ws", "wss"],
  });

export default {
  login,
  getUser,
  getProducts,
  getPrices,
  requestResetPassword,
  resetPassword,
  makeOrder,
  makePaymentIntent,
  setupIntent,
  saveCard,
  deleteCard,
  registerPayment,
  initiateMultibanco,
  uploadImage,
  setDelivery,
  changeDelivery,
  getShipping,
  updateOrder,
  applyCoupon,
  applyLevPoints,
  getSocketsClient,
  hiPayPayment,
  hiPayCheckStatus,
  changeAddress,
  getOrder,
  phaseData,
};
