import axios from 'axios';

const apiCall = async ({ endpoint, method, payload }) => {
  method = method || 'get';
  try {
    const { data, headers } = await axios({
      method: method,
      url: `${process.env.VUE_APP_WORDPRESS_URL}/wp-json/wc/v3/${endpoint}`,
      auth: {
        username: process.env.VUE_APP_WOOCOMMERCE_KEY,
        password: process.env.VUE_APP_WOOCOMMERCE_SECRET,
      },
      data: payload,
    });
    return { data, headers };
  } catch (err) {
    if (err.response.status === 404) {
      throw new Error('not found');
    } else if (err.response.status === 401) {
      throw new Error('unauthorized');
    } else {
      throw new Error('error');
    }
  }
};

const getProduct = async (slug) =>
  await apiCall({ endpoint: `products?slug=${slug}` });

const getProductsSku = async (sku) =>
  await apiCall({ endpoint: `products?sku=${sku.join(',')}&per_page=100` });

const getProductsId = async (ids) =>
  await apiCall({ endpoint: `products?include=${ids.join(',')}` });

const getProducts = async (page) =>
  await apiCall({ endpoint: `products?per_page=100&page=${page}` });

const searchProducts = async (query, page) =>
  await apiCall({
    endpoint: `products?per_page=20&page=${page}&search=${query}`,
  });

const getProductsByCategory = async (page, category, perPage) =>
  apiCall({
    endpoint: `products?per_page=${perPage}&page=${page}&category=${category}`,
  });

const getProductsByTag = async (page, tag, perPage) =>
  apiCall({ endpoint: `products?per_page=${perPage}&page=${page}&tag=${tag}` });

const getCategories = async () =>
  await apiCall({
    endpoint: 'products/categories?per_page=100&hide_empty=true',
  });

const getTags = async () =>
  await apiCall({ endpoint: 'products/tags?per_page=100&hide_empty=true' });

export default {
  getProduct,
  getProducts,
  getCategories,
  getTags,
  getProductsSku,
  getProductsId,
  getProductsByCategory,
  getProductsByTag,
  searchProducts,
};
