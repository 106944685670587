<template>
  <div class="shells">
    <div class="shell" />
    <div class="shell" />
    <div class="shell" />
    <div class="shell" />
    <div class="shell" />
  </div>
</template>

<script>
export default {
  name: 'Skeleton',
};
</script>

<style lang="scss" scoped>
.shells {
  display: grid;
  grid-template-columns: 1fr 1fr;
}
.shell {
  display: inline-block;
  position: relative;
  overflow: hidden;
  background-color: #f5f3f5;
  width: 90%;
  height: 300px;
  margin-left: 5%;
  margin-bottom: 20px;

  &::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    transform: translateX(-100%);
    background-image: linear-gradient(
      90deg,
      rgba(#fff, 0) 0,
      rgba(#fff, 0.2) 20%,
      rgba(#fff, 0.5) 60%,
      rgba(#fff, 0)
    );
    animation: shimmer 2s infinite;
    content: '';
  }

  @keyframes shimmer {
    100% {
      transform: translateX(100%);
    }
  }
}
</style>
