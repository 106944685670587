export default {
  numberFormat: function (number) {
    let isInt = number - Math.floor(number) == 0;
    if (!isInt) {
      number = number.toFixed(1);
    } else {
      number = parseInt(number);
    }
    return number;
  },
  shorten(string) {
    let dot = string.indexOf('.');
    if (string.charAt(dot + 1) == 0) return string.substring(0, dot);
    else if (string === '0.50') {
      return '½';
    } else return string.substring(0, dot + 2);
  },
  addSwipeListeners(ref) {
    this.$refs[ref].addEventListener(
      'touchstart',
      (e) => this.touchstart(e),
      false
    );
    this.$refs[ref].addEventListener(
      'touchmove',
      (e) => this.touchmove(e),
      false
    );
    this.$refs[ref].addEventListener('touchend', () => this.touchend(), false);
  },
  removeSwipeListeners(ref) {
    this.$refs[ref].removeEventListener(
      'touchstart',
      (e) => this.touchstart(e),
      false
    );
    this.$refs[ref].removeEventListener(
      'touchmove',
      (e) => this.touchmove(e),
      false
    );
    this.$refs[ref].removeEventListener(
      'touchend',
      () => this.touchend(),
      false
    );
  },
  filterByDate(payload) {
    const filtered = [...payload.data].sort(
      (a, b) => new Date(a[payload.name]) - new Date(b[payload.name])
    );
    return filtered;
  },
  filterByDateReverse(payload) {
    const filtered = [...payload.data].sort(
      (a, b) => new Date(b[payload.name]) - new Date(a[payload.name])
    );
    return filtered;
  },
};
