<template>
  <div v-click-outside="notActive" class="mail-wrapper">
    <label
      @click="notActive"
      :class="{ show_label: active }"
      class="small-label-thin"
      for="email"
    >
      Email
    </label>

    <input
      @click="setActive"
      placeholder="Email"
      @input="$emit('type', email)"
      name="email"
      type="email"
      v-on:keyup.enter="$emit('enter-clicked')"
      v-model="email"
    />

    <img v-if="mailValid" src="@/assets/checked.svg" alt="checked" />
  </div>
</template>

<script>
import clickOutside from '@/directives/ClickOutside';

export default {
  data() {
    return {
      email: null,
      active: false,
      regExp:
        // eslint-disable-next-line no-useless-escape
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/,
    };
  },
  emits: ['type', 'enter-clicked'],
  directives: {
    clickOutside: clickOutside,
  },
  methods: {
    setActive() {
      this.active = true;
    },
    notActive() {
      this.active = false;
    },
  },
  computed: {
    mailValid() {
      if (this.regExp.test(this.email)) {
        return true;
      } else return false;
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/styles/_mixins';

div {
  @include input;
}
</style>
