<template>
  <ul class="breadcrumbs">
    <img
      v-if="product"
      class="breadcrumbs__back"
      src="@/assets/back.svg"
      alt="arrow"
      @click="$router.go(-1)"
    />
    <li class="breadcrumbs__main" @click="$router.push({ name: 'shop' })">
      {{ $t('navBottom.shop') }}
    </li>
    <li v-if="category">
      <span
        @click="$router.push({ name: 'shop', params: { slug: category.slug } })"
        class="breadcrumbs__crumb"
        :class="{
          'breadcrumbs__crumb--secondary breadcrumbs__crumb--cut': product,
        }"
        v-html="category.name"
      />
    </li>
    <li>
      <span
        v-if="product"
        class="breadcrumbs__crumb breadcrumbs__crumb--cut"
        v-html="product"
      />
    </li>
  </ul>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'Breadcrumbs',

  props: {
    category: {
      type: Object,
      default: null,
    },
    product: {
      type: String,
      default: '',
    },
  },

  computed: {
    ...mapState(['categories']),
  },
};
</script>

<style scoped lang="scss">
.breadcrumbs {
  display: flex;
  align-items: center;
  font-family: $font;
  font-size: 14px;
  line-height: 16px;
  &__main {
    font-weight: 600;
  }
  &__crumb {
    position: relative;
    display: block;
    padding-left: 24px;
    color: $orange;
    &:before {
      position: absolute;
      content: '';
      left: 12px;
      width: 1px;
      height: 100%;
      background-color: $black;
    }
    &--secondary {
      color: $black-off;
    }
    &--cut {
      max-width: 38vw;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
  &__back {
    margin-right: 10px;
  }
}
</style>
