<template>
  <li
    @click="$emit('clicked')"
    class="product-card flex"
    :class="{ small: small, big: !small }"
  >
    <div v-if="order" class="flex">
      <h5 class="medium-label-thin">{{ product.quantity }}</h5>
    </div>
    <div
      class="img-wrapper flex"
      :class="{ resize: small }"
      :style="'background-color: ' + backgroundColor"
    >
      <div @click="$emit('clicked')" class="click"></div>
      <div class="img-wrapper__box">
        <img
          :class="{ resize: small }"
          v-if="product.images[0]"
          :src="product.images[0].sizes.shop_single"
          :alt="product.name"
        />
      </div>
      <div class="tags" v-if="!small && product.tags.length > 0">
        <p v-for="t in product.tags.slice(0, 2)" :key="t.id">{{ t.name }}</p>
      </div>
    </div>
    <div class="info flex-column">
      <div
        @click="$emit('clicked')"
        class="wrapper"
        :class="{ small_wrapper: small && !search && !deleted }"
      >
        <h5 class="product-card__product-name medium-label-thin">
          {{ product.name }}
        </h5>
        <div v-if="!deleted" class="flex prices">
          <h5 v-if="product.on_sale" class="medium-label sale">
            {{ product.sale_price }} €
          </h5>
          <h5 :class="{ strike: product.on_sale }" class="medium-label">
            {{ getPrice(product.sku) }} €
          </h5>
        </div>
        <p class="deleted" v-else>{{ $t('cart.product_deleted') }}</p>
      </div>
      <h5 class="medium-label total" v-if="order">
        {{ getPrice(product.sku) }} €
      </h5>
      <Counter
        v-else-if="!search && stock && stock.stock > 0 && !deleted"
        :stock="stock.stock"
        :quantity="itemQuantity(product.sku)"
        :width="getCounterWidth"
        @increase="add"
        @decrease="remove"
        @add-custom="addCustom"
      />
      <Stock v-else-if="!search && !small" :shop="true" :stock="stock" />
    </div>
  </li>
</template>

<script>
import Counter from '@/components/ui/Counter.vue';
import Stock from '@/components/Stock.vue';
import { mapState, mapGetters, mapActions } from 'vuex';

export default {
  components: {
    Counter,
    Stock,
  },

  props: {
    product: Object,
    small: {
      type: Boolean,
      default: false,
    },
    order: {
      type: Boolean,
      default: false,
    },
    search: {
      type: Boolean,
      default: false,
    },
    deleted: {
      type: Boolean,
      default: false,
    },
  },

  emits: ['clicked'],

  computed: {
    ...mapState(['newProductDays', 'cart']),
    ...mapGetters(['itemQuantity', 'getPrice', 'getStock']),

    isNew() {
      let d = new Date();
      d.setDate(d.getDate() - this.newProductDays);
      if (new Date(this.product.date_created) > new Date(d)) return true;
      else return false;
    },
    getCounterWidth() {
      return this.small ? 50 : 80;
    },
    stock() {
      return this.product?.sku ? this.getStock(this.product.sku) : null;
    },
    backgroundColor() {
      return this.product?.acf?.background_color
        ? this.product.acf.background_color
        : '';
    },
  },

  methods: {
    ...mapActions(['getShipping']),

    async add() {
      await this.$store.commit('addToCart', this.product.sku);
      if (this.small) {
        this.getShipping();
      }
    },

    async addCustom(q) {
      await this.$store.commit('addCustomToCart', {
        quantity: q,
        sku: this.product.sku,
      });
      if (this.small) {
        this.getShipping();
      }
    },

    async remove() {
      await this.$store.commit('removeFromCart', this.product.sku);
      if (this.small) {
        this.getShipping();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.product-card {
  justify-content: flex-start;
  width: 100%;
  align-items: center;
  div {
    align-items: center;
  }
  .deleted {
    font-size: 0.8rem;
    color: red;
  }
  .info {
    justify-content: space-between;
    padding: 15px;
    padding-top: 5px;
    .total {
      width: 50%;
      text-align: right;
    }
    .wrapper {
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
    }
    .prices {
      justify-content: flex-start;
      h5 {
        max-height: 20px;
        font-size: 14px;
        font-weight: 500;
      }
      .sale {
        margin-right: 10px;
        color: $red-sale;
      }
      .strike {
        text-decoration: line-through;
      }
    }
    .counter {
      button {
        width: 25px;
        height: 25px;
      }
    }
  }
  div {
    h5 {
      color: $black;
      font-size: 14px;
      line-height: 17px;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
      overflow: hidden;
    }
  }
  .img-wrapper {
    position: relative;
    min-height: 224px;
    max-height: 224px;
    align-items: center;
    &:after {
      content: '';
      display: block;
      padding-bottom: 100%;
    }
    &.resize {
      min-height: unset;
    }
    &__box {
      position: absolute;
      top: 15px;
      width: 100%;
      height: 100%;
      padding: 10px;
    }
    img {
      height: calc(100% - 30px);
      width: 100%;
      object-fit: contain;
    }
    .click {
      width: 90%;
      height: 100%;
      left: 20px;
      position: absolute;
      cursor: pointer;
    }
    .resize {
      max-width: 99%;
    }
  }
}
.small {
  min-height: 70px;
  border-bottom: 1px solid rgba($color: #000000, $alpha: 0.1);
  .info {
    flex-direction: row;
    width: 80%;
    .prices,
    h5 {
      margin: 5px 5px 0 0;
    }
    h5 {
      text-align: left;
    }
  }
  .small_wrapper {
    width: 50%;
  }
  .img-wrapper {
    margin-left: 20px;
    width: 20%;
    padding: 10px 15px;
    max-height: 80px;
    height: 100px;
  }
}
.big {
  flex-direction: column;
  .img-wrapper {
    align-items: center;
    img {
      object-fit: contain;
    }
  }
  > div {
    width: 100%;
  }
  .info {
    h5 {
      height: 34px;
      text-align: center;
    }
    .prices {
      margin: 10px 0;
      font-size: 14px;
      font-weight: 500;
    }
    .wrapper {
      position: relative;
      width: 100%;
      align-items: center;
    }
  }
  .tags {
    position: absolute;
    width: 100%;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    bottom: 10px;
    p {
      margin: 4px;
      padding: 6px 8px;
      font-size: 9px;
      font-weight: 500;
      line-height: 10px;
      white-space: nowrap;
      text-transform: capitalize;
      color: $brown;
      background-color: $light-yellow;
      border-radius: 10px;
    }
  }
}
</style>

<style lang="scss">
.product-card {
  .info {
    .counter {
      max-width: fit-content;
      button {
        width: 30px;
        height: 30px;
      }
      input {
        width: 30px;
        font-size: 14px;
        font-weight: 500;
      }
    }
  }
}
</style>
