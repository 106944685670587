export default {
  beforeMount: function (el, binding) {
    el.touchOutsideEvent = function (event) {
      if (!(el == event.target || el.contains(event.target))) {
        binding.value(event, el);
      }
    };
    document.body.addEventListener('touchstart', el.touchOutsideEvent);
  },
  unmounted: function (el) {
    document.body.removeEventListener('touchstart', el.touchOutsideEvent);
  },
};
