<template>
  <div :style="cardMove" v-click-outside="close" class="filter" ref="filter">
    <div class="content">
      <Sort />
      <Filter />
      <h3>{{ $t('shop.categories') }}</h3>
      <ul class="categories">
        <li
          v-if="myProducts && myProducts.length > 0"
          class="my_products"
          @click="goToMyProducts"
        >
          <h4>{{ $t('shop.my_products') }}</h4>
        </li>
        <Category
          :category="category"
          :openCategory="openCategory"
          v-for="category in topLevelCategories"
          :key="category.id"
          @open="open"
          @save="save"
        />
      </ul>
    </div>
  </div>
</template>

<script>
import shared from '@/shared';
import clickOutside from '@/directives/ClickOutside';
import Category from '@/components/Category.vue';
import Sort from '@/components/Sort.vue';
import Filter from '@/components/Filter.vue';
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex';

export default {
  components: {
    Category,
    Sort,
    Filter,
  },
  created() {
    this.addSwipeListeners = shared.addSwipeListeners;
    this.removeSwipeListeners = shared.removeSwipeListeners;
  },
  mounted() {
    document.body.className = 'no-scroll';
    this.addSwipeListeners('filter');
    this.openCategory = this.activeCategory;
  },
  beforeUnmount() {
    document.body.className = '';
    this.removeSwipeListeners('filter');
  },
  directives: {
    clickOutside: clickOutside,
  },
  data() {
    return {
      movement: 0,
      touch: {
        startY: 0,
        endY: 0,
        startX: 0,
        endX: 0,
      },
      openCategory: 0,
    };
  },
  computed: {
    ...mapState(['categories', 'currentCategory', 'myProducts']),
    ...mapGetters(['getProduct']),
    isCurrent() {
      if (!this.$route.params.id && !this.$route.params.slug) {
        return true;
      } else return false;
    },
    cardMove() {
      return { transform: 'translateX(' + this.movement + 'px)' };
    },
    topLevelCategories() {
      return this.categories.filter((c) => c.parent === 0);
    },
    activeCategory() {
      if (
        this.$route.params.slug &&
        this.$route.name === 'shop' &&
        this.$route.params.slug !== 'myproducts'
      ) {
        let found = this.categories.find(
          (c) => c.slug === this.$route.params.slug
        );
        return found.parent;
      } else if (this.$route.name === 'product') {
        let found = this.categories.find(
          (c) => c.id === this.currentCategory.id
        );
        return found.parent;
      } else return 0;
    },
  },
  methods: {
    ...mapActions(['animationStartPos', 'toggle', 'setCategory']),
    ...mapMutations(['mutate']),
    close() {
      this.scrollToTop();
      this.toggle('filter');
    },
    open(id) {
      if (this.openCategory === id) {
        this.openCategory = 0;
      } else this.openCategory = id;
    },
    touchstart(e) {
      this.touch.startX = e.touches[0].clientX;
      this.touch.endX = e.touches[0].clientX;
      this.touch.startY = e.touches[0].clientY;
      this.touch.endY = e.touches[0].clientY;
    },
    touchmove(e) {
      this.touch.endX = e.touches[0].clientX;
      this.touch.endY = e.touches[0].clientY;
      if (
        Math.abs(this.touch.endY - this.touch.startY) >
        Math.abs(this.touch.endX - this.touch.startX)
      ) {
        return;
      }
      if (this.touch.endX < this.touch.startX) {
        e.preventDefault();
        this.movement = this.touch.endX - this.touch.startX;
      }
    },
    touchend() {
      if (
        Math.abs(this.touch.endY - this.touch.startY) >
        Math.abs(this.touch.endX - this.touch.startX)
      ) {
        this.movement = 0;
        this.animationStartPos(this.movement);
        return;
      }
      if (Math.abs(this.touch.endX - this.touch.startX) < 40) {
        this.movement = 0;
        this.animationStartPos(this.movement);
        return;
      }
      if (this.touch.endX < this.touch.startX) {
        this.animationStartPos(this.movement);
        this.close();
      }
    },
    scrollToTop() {
      this.mutate({
        property: 'scrollY',
        with: 0,
      });
    },
    save(c) {
      if (c) {
        this.setCategory(c);
        this.$router.push({ name: 'shop', params: { slug: c.slug } });
      } else {
        this.$router.push({ path: '/shop' });
      }
      this.close();
    },
    goToMyProducts() {
      this.close();
      this.$router.push({ name: 'shop', params: { slug: 'myproducts' } });
    },
  },
};
</script>

<style lang="scss" scoped>
.filter {
  height: 100vh;
  width: 85%;
  z-index: 5;
  position: fixed;
  top: 0;
  right: 0;
  background-color: $background-color;
  max-width: $component-max-width;
  .categories {
    > li {
      border-top: 1px solid rgba($color: #000000, $alpha: 0.05);
    }
    .my_products {
      padding: 20px;
      h4 {
        text-transform: none;
        text-align: left;
        font-size: 16px;
        font-weight: 300;
      }
    }
  }
  .content {
    height: 100vh;
    overflow-y: auto;
    background-color: $white-off;
    h3 {
      text-align: left;
      padding: 20px;
      text-transform: lowercase;
      color: $brown;
      font-weight: 500;
      font-size: 1.2rem;
      &::first-letter {
        text-transform: uppercase;
      }
    }
    .selected_products {
      padding: 20px;
      display: flex;
      align-items: center;
      .dot {
        height: 8px;
        width: 8px;
        border-radius: 100%;
        background-color: $dark-yellow;
        margin-bottom: 2px;
      }
      h4 {
        text-align: left;
        margin-right: 8px;
        text-transform: capitalize;
        font-size: 14px;
      }
    }
  }
}
</style>
