<template>
  <div class="appointment-wrapper">
    <div v-if="appointment" class="appointment">
      <h1 class="appointment__title page-title">{{ text }}</h1>

      <div class="appointment__info">
        <p class="appointment__type">{{ typeOfAppointment }}:</p>

        <p class="appointment__clinic">{{ clinicName }}</p>
      </div>

      <div class="appointment__next">
        <p>{{ nextDate }}</p>

        <!-- <img src="@/assets/calendar-plus.svg" /> -->
      </div>
    </div>

    <div v-else @click="toggle('contact')" @click.stop class="no-appointment">
      <h1 class="no-appointment__title page-title">
        {{ $t('appointmentCard.noAppointments') }}.
      </h1>

      <p class="no-appointment__schedule">
        {{ $t('appointmentCard.schedule') }}.
      </p>

      <div class="no-appointment__contact">
        <p>{{ $t('appointmentCard.contact_dietitian') }}</p>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import moment from 'moment';

export default {
  name: 'AppointmentCard',
  props: {
    appointment: Object,
    text: {
      type: String,
      default: 'appointment',
    },
  },

  data() {
    return {
      daysToAppointment: '',
    };
  },

  computed: {
    ...mapGetters(['getClinicName']),
    clinicName() {
      if (this.appointment.online === 1) {
        return 'online';
      }
      let name = this.getClinicName(this.appointment.clinic_id);
      return name.toUpperCase();
    },
    typeOfAppointment() {
      if (this.appointment.type === 2) {
        return this.$t('appointmentCard.follow_up');
      } else return this.$t('appointmentCard.consultation');
    },
    nextDate() {
      return moment(this.appointment.start)
        .locale(process.env.VUE_APP_LANG || 'en')
        .format('DD MMM HH:mm');
    },
  },
  mounted() {
    if (this.appointment) this.daysLeft();
  },
  methods: {
    ...mapActions(['toggle']),
    daysLeft() {
      const dateApp = new Date(this.appointment.start.replace(/\s/, 'T') + 'Z');
      const dateToday = new Date();
      dateApp.setHours(12);
      dateToday.setHours(12);
      dateApp.setMinutes(0);
      dateToday.setMinutes(0);
      dateToday.setSeconds(0);
      const difTime = dateApp.getTime() - dateToday.getTime();
      const daysLeft = Math.ceil(difTime / (1000 * 3600 * 24));
      if (daysLeft === 1) {
        this.daysToAppointment = this.$t('appointmentCard.tomorrow');
      } else if (daysLeft === 0) {
        this.daysToAppointment = this.$t('appointmentCard.today');
      } else
        this.daysToAppointment =
          this.$t('appointmentCard.in') +
          ' ' +
          daysLeft +
          ' ' +
          this.$t('appointmentCard.days');
    },
  },
};
</script>

<style lang="scss" scoped>
.appointment-wrapper {
  .appointment,
  .no-appointment {
    padding: 16px 18px;
    background-color: $deep-yellow;
    border-radius: $rounded-l;
    &__title {
      text-transform: capitalize;
    }
    &__schedule {
      font-size: 16px;
      margin: 10px 0 16px;
    }
    &__info {
      display: flex;
      font-size: 16px;
      font-weight: 400;
      margin: 10px 0 16px;
      text-transform: capitalize;
    }
    &__type {
      margin: 0;
    }
    &__clinic {
      margin: 0;
      margin-left: 5px;
      font-weight: 700;
      text-transform: lowercase;
      &::first-letter {
        text-transform: capitalize;
      }
    }
    &__next {
      padding: 6px;
      display: flex;
      align-content: center;
      justify-content: center;
      background-color: $background-color-light;
      border-radius: $rounded-l;
      p {
        margin: 0;
        font-size: 18px;
        font-weight: 600;
        line-height: 40px;
        text-transform: capitalize;
      }
      img {
        align-self: center;
        padding-left: 16px;
      }
    }
    &__contact {
      padding: 6px;
      display: flex;
      align-content: center;
      justify-content: center;
      background-color: $background-color-light;
      border-radius: $rounded-l;
      p {
        margin: 0;
        font-size: 18px;
        font-weight: 600;
        line-height: 40px;
        text-transform: capitalize;
      }
    }
  }
}
</style>
