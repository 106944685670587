<template>
  <div :style="counterWidth" class="counter flex" @click.stop>
    <button @click="decrease" class="minus flex" :class="{ disabled: zero }">
      <img src="@/assets/-.svg" alt="-" />
    </button>
    <!-- <h5 class="medium-label">{{quantity}}</h5> -->
    <input
      @keypress="testInput($event)"
      pattern="\d*"
      @keyup.enter="setCustom"
      @click="setFocus"
      @blur="setCustom"
      ref="input"
      class="medium-label"
      type="number"
      v-model="count"
    />
    <button
      @click="increase"
      class="plus flex"
      :class="{ disabled: !hasStock }"
    >
      <img src="@/assets/+.svg" alt="+" />
    </button>
  </div>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  data() {
    return {
      count: 0,
    };
  },
  props: {
    quantity: {
      type: Number,
      default: 0,
    },
    stock: {
      type: Number,
      default: 0,
    },
    width: {
      type: Number,
      default: 75,
    },
  },
  computed: {
    zero() {
      return this.quantity <= 0 ? true : false;
    },
    hasStock() {
      return this.stock <= this.count ? false : true;
    },
    counterWidth() {
      return `width: ${this.width}%`;
    },
  },
  watch: {
    quantity(value) {
      this.count = value;
    },
  },
  mounted() {
    this.count = this.quantity;
  },
  emits: ['increase', 'decrease', 'add-custom'],
  methods: {
    ...mapActions(['setCounterActive', 'disableCounter']),
    increase() {
      if (this.hasStock) {
        this.$emit('increase');
      }
    },
    decrease() {
      if (!this.zero) {
        this.$emit('decrease');
      }
    },
    setFocus() {
      this.count = null;
      setTimeout(() => {
        this.setCounterActive();
      }, 200);
    },
    setCustom() {
      this.disableCounter();
      this.$refs.input.blur();
      if (this.count > 999 || this.count < 0 || !this.count || !this.hasStock) {
        this.count = this.quantity;
      } else {
        this.$emit('add-custom', parseInt(this.count));
      }
    },
    testInput(evt) {
      evt = evt || window.event;
      const charCode = evt.which || evt.keyCode;
      if (charCode > 47 && charCode < 58) return true;
      evt.preventDefault();
    },
  },
};
</script>

<style lang="scss" scoped>
.counter {
  justify-content: space-between;
  align-items: center;
  height: 40px;
  input {
    height: 100%;
    width: 40%;
    border: none;
    text-align: center;
  }
  .flex {
    align-items: center;
    background-color: $accent-yellow;
    height: 40px;
    width: 40px;
    border-radius: $rounded;
    filter: $img-shadow;
    img {
      width: 12px;
      height: 12px;
    }
  }
  .disabled {
    opacity: 0.3;
  }
}
</style>
