<template>
  <NavTop :title="title" />
  <div
    v-if="measurements && measurements.length > 0"
    class="progress flex-column"
  >
    <CircleChart class="circle" innerColor="#faf1e4" />
    <h4 class="small-label-thin">
      {{ $t('progress.weight_goal') }}
      <span class="small-label"
        >{{
          numberFormat(measurements[measurements.length - 1].target_weight)
        }}kg</span
      >
    </h4>

    <LineCards />
  </div>
</template>

<script>
import shared from '@/shared';
import CircleChart from '@/components/CircleChart.vue';
import LineCards from '@/components/LineCards.vue';
import NavTop from '@/components/NavTop.vue';
import { mapState } from 'vuex';

export default {
  components: {
    CircleChart,
    NavTop,
    LineCards,
  },

  data() {
    return {
      strings: {
        weight: this.$t('progress.weight'),
      },
    };
  },

  created() {
    this.numberFormat = shared.numberFormat;
  },

  computed: {
    ...mapState(['measurements']),
    weight() {
      return this.measurements
        .filter((m) => m.current_weight !== null)
        .map((m) => ({
          value: m.current_weight,
          date: m.date,
        }));
    },
    weightFiltered() {
      return this.weight.map((item) => item.value);
    },
    title() {
      return this.$t('progress.progress');
    },
  },
};
</script>

<style lang="scss" scoped>
.progress {
  padding: 120px 0 100px 0;
  background-color: $background-color-peach;
  h4 {
    margin: 15px 0;
    margin-top: 30px;
    font-size: 13px;
  }
}
</style>
