<template>
  <div ref="password" :style="cardMove" class="recover-password flex-column">
    <div @click="closeOverlay" class="back flex">
      <img src="@/assets/back.svg" alt="" />
    </div>

    <img class="lev-logo" src="@/assets/lev-logo.svg" alt="" />

    <div class="text">
      <p v-if="passwordLinkActive">
        We will send you an email with instructions on how to reset your
        password.
      </p>

      <p v-else>
        Check your email and follow the instructions, you will be able to send a
        new link in
        <span>{{ timer }}</span>
        seconds
      </p>
    </div>

    <InputMail @type="mailTyping" @enter-clicked="reset" />

    <h6 v-if="error && this.email">{{ error }}</h6>

    <PrimaryButton
      :disabled="!finished || !passwordLinkActive"
      @click="reset"
      :title="btnMsg"
    />
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import shared from '@/shared';
import InputMail from '@/components/ui/InputMail.vue';
import PrimaryButton from '@/components/ui/PrimaryButton.vue';

export default {
  components: {
    InputMail,
    PrimaryButton,
  },
  data() {
    return {
      email: null,
      movement: 0,
      touch: {
        startX: 0,
        endX: 0,
      },
      regExp:
        // eslint-disable-next-line no-useless-escape
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/,
    };
  },
  computed: {
    ...mapState(['error', 'passwordLinkActive', 'timer']),
    cardMove() {
      return { transform: 'translateX(' + this.movement + 'px)' };
    },
    finished() {
      if (this.regExp.test(this.email)) {
        return true;
      } else return false;
    },
    btnMsg() {
      if (this.passwordLinkActive) return 'Send reset link';
      else return 'Password link sent';
    },
  },
  methods: {
    ...mapActions([
      'toggle',
      'animationStartPos',
      'requestResetPassword',
      'passwordSent',
      'passwordLinkReset',
      'togglePassword',
    ]),
    async reset() {
      if (this.passwordLinkActive) {
        this.$store.commit('setError', null);
        if (!this.regExp.test(this.email)) {
          this.$store.commit('setError', 'Please fill in a valid email');
          return false;
        }
        await this.requestResetPassword(this.email)
          .then(() => {
            if (this.$store.state.error === null) {
              this.passwordSent();
            }
          })
          .catch((err) => {
            console.log(err);
            this.$store.commit('setError', 'No such mail registred');
          });
      }
    },
    mailTyping(mail) {
      this.email = mail;
    },
    closeOverlay() {
      this.togglePassword();
    },
    touchstart(e) {
      this.touch.startX = e.touches[0].clientX;
      this.touch.endX = e.touches[0].clientX;
      this.touch.startY = e.touches[0].clientY;
      this.touch.endY = e.touches[0].clientY;
    },
    touchmove(e) {
      e.preventDefault();
      this.touch.endX = e.touches[0].clientX;
      this.touch.endY = e.touches[0].clientY;

      if (this.touch.endX > this.touch.startX) {
        this.movement = Math.abs(this.touch.startX - this.touch.endX);
      }
    },
    touchend() {
      if (
        Math.abs(this.touch.endY - this.touch.startY) >
        Math.abs(this.touch.endX - this.touch.startX)
      ) {
        this.movement = 0;
        this.animationStartPos(this.movement);
        return;
      }
      if (Math.abs(this.touch.endX - this.touch.startX) < 40) {
        this.movement = 0;
        this.animationStartPos(this.movement);
        return;
      }
      if (this.touch.endX > this.touch.startX) {
        this.animationStartPos(this.movement);
        this.togglePassword();
      }
    },
  },
  created() {
    this.addSwipeListeners = shared.addSwipeListeners;
    this.removeSwipeListeners = shared.removeSwipeListeners;
  },
  mounted() {
    this.addSwipeListeners('password');
    this.$store.commit('setError', null);
  },
  beforeUnmount() {
    this.removeSwipeListeners('password');
    this.$store.commit('setError', null);
  },
};
</script>

<style lang="scss" scoped>
.recover-password {
  width: 100vw;
  height: 100vh;
  position: absolute;
  left: 0;
  top: 0;
  background-color: $background-color;
  padding: 100px 38px;
  max-width: $max-width;
  .lev-logo {
    width: 122px;
    margin-top: 75px;
  }
  .back {
    height: $nav-top-height;
    width: 85px;
    position: absolute;
    left: 0;
    top: 0;
    img {
      width: 20px;
    }
  }
  p {
    margin: 30px 0;
    span {
      font-weight: 600;
      color: $orange;
    }
  }
  h6 {
    margin-bottom: 16px;
  }
}
</style>
