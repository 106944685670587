<template>
  <div
    class="nav-top"
    :class="{ search_active: searchActive, scrolled: isScrolled }"
  >
    <div class="nav-top__left" v-if="!searchActive">
      <!-- <div 
        v-if="product"
        @click="$router.go(-1)"
        class="nav-top__back"
      >
        <img src="@/assets/back.svg" alt="profile" />
      </div> -->

      <div
        v-if="phase"
        :class="{ show_on_top: onboarding > 0 }"
        class="nav-top__dietitian"
      >
        <div
          @click="toggle('contact')"
          @click.stop
          v-if="dietitian && dietitian.image === '/images/placeholder.svg'"
          class="nav-top__dietitian-circle"
        >
          <span class="nav-top__dietitian-name">{{ initials }}</span>
        </div>

        <div
          @click="toggle('contact')"
          @click.stop
          v-else-if="dietitian"
          class="nav-top__dietitian-circle"
        >
          <img
            :src="`${url}/${dietitian.image}`"
            alt="dietitian"
            class="nav-top__dietitian-img"
          />
        </div>
      </div>

      <img
        @click="$router.push({ path: '/' })"
        src="@/assets/lev.svg"
        alt="lev.svg"
        class="nav-top__lev-logo lev_logo"
      />
    </div>

    <div class="nav-top__right">
      <div class="nav-top__search search">
        <input
          :value="input"
          @input="(e) => search(e)"
          ref="searchinput"
          type="text"
          class="search__input"
          :class="{
            'search__input--active input_active': searchActive || input,
          }"
        />

        <div class="search__search">
          <img
            class="search__icon search_img"
            @click="enable"
            v-show="!searchActive"
            src="@/assets/search.svg"
            alt="search"
          />

          <img
            class="search__close close"
            v-show="searchActive"
            @click="clear"
            src="@/assets/close.svg"
            alt="close"
          />
        </div>
      </div>

      <CartButton v-if="!searchActive" class="search__cart" />

      <ShopMenu v-if="!searchActive" :input="input" class="search__nav" />

      <div
        class="product-list__wrapper"
        v-if="searchActive && input.length > 1"
      >
        <ProductList
          :search="true"
          :class="{ product_search: false }"
          :small="true"
          :products="products"
          :shop="true"
        >
          <div
            @click="searchMore"
            v-if="searchActive && products.length > 0 && hasMore"
            class="search__more"
          >
            {{ $t('shop.more') }}
          </div>
        </ProductList>
      </div>

      <div class="search__info" v-if="searchActive && input.length < 2">
        {{ $t('shop.type_to_find') }}
      </div>

      <div
        class="search__info"
        v-if="searchActive && input.length > 2 && products.length === 0"
      >
        {{ $t('shop.not_found') }}
        <span class="search__info-input">{{ input }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions, mapMutations } from 'vuex';
import moment from 'moment';
import touchOutside from '@/directives/TouchOutside';
import woocommerce from '@/apis/woocommerce';
import ShopMenu from '@/components/ShopMenu.vue';
import ProductList from '@/components/ProductList.vue';
import CartButton from '@/components/ui/CartButton.vue';

export default {
  name: 'NavTop',

  components: {
    ShopMenu,
    ProductList,
    CartButton,
  },

  data() {
    return {
      url: process.env.VUE_APP_BOLEV_URL,
      input: '',
      searchActive: false,
      isScrolled: false,
      debounce: null,
      result: [],
      page: 1,
      total: null,
    };
  },

  props: {
    shop: {
      type: Boolean,
      default: false,
    },
    /* isProduct: {
      type: Boolean,
      default: false,
    }, -- product: {} - from navtop */
    product: {
      type: Object,
      default: null,
    },
  },

  mounted() {
    window.addEventListener('scroll', this.onScroll);
    this.input = this.savedSearch;
    this.clear();
  },

  beforeUnmount() {
    window.removeEventListener('scroll', this.onScroll);
    this.mutate({
      property: 'savedSearch',
      with: this.input,
    });
    this.mutate({
      property: 'navBottomActive',
      with: true,
    });
  },

  watch: {
    searchActive(value) {
      if (value) {
        this.mutate({
          property: 'navBottomActive',
          with: false,
        });
      } else {
        this.mutate({
          property: 'navBottomActive',
          with: true,
        });
        this.page = 1;
      }
    },
  },

  directives: {
    touchOutside: touchOutside,
  },

  emits: ['search'],

  computed: {
    ...mapState([
      'cart',
      'phase',
      'tempImage',
      'user',
      'futureAppointments',
      'onboarding',
    ]),
    ...mapGetters(['getDietitian', 'getProduct']),

    dietitian() {
      return this.phase ? this.getDietitian(this.phase.dietitian_id) : null;
    },

    date() {
      if (this.futureAppointments && this.futureAppointments.length > 0) {
        return this.futureAppointments[0].start;
      } else {
        return null;
      }
    },

    day() {
      return moment(this.date)
        .locale(process.env.VUE_APP_LANG || 'en')
        .format('DD');
    },

    month() {
      return moment(this.date)
        .locale(process.env.VUE_APP_LANG || 'en')
        .format('MMM');
    },
    getImageUrl() {
      if (this.tempImage) {
        return this.tempImage;
      } else return this.user.image;
    },
    initials() {
      if (!this.dietitian) return 'XX';
      return this.dietitian.name
        .split(' ')
        .map((c) => c.toUpperCase().substring(0, 1))
        .join('');
    },

    /* search */

    ...mapState(['savedSearch', 'categories', 'currentCategory', 'prices']),

    products() {
      return this.result
        .filter((p) => {
          const product = this.prices.find((price) => price.sku === p.sku);
          return product?.product?.available ? p : null;
        })
        .filter((p) => p);
    },

    hasMore() {
      return this.total && this.page - 1 < this.total;
    },
  },

  methods: {
    ...mapActions(['toggle']),
    backToShop() {
      let product = this.getProduct(this.$route.params.id);
      let bottomLevel = product.categories.find((c) => c.parent !== 0);
      if (bottomLevel) {
        this.$router.push({ name: 'shop', params: { slug: bottomLevel.slug } });
      } else
        this.$router.push({
          name: 'shop',
          params: { slug: product.categories[0].slug },
        });
    },

    /* search */
    ...mapMutations(['mutate']),

    onScroll() {
      if (window.scrollY > 60) {
        this.isScrolled = true;
      } else {
        this.isScrolled = false;
      }
    },

    disable() {
      this.$refs.searchinput.blur();
      this.searchActive = false;
    },

    clear() {
      document.body.className = '';
      this.input = '';
      this.disable();
    },

    enable() {
      document.body.className = 'no-scroll';
      this.$refs.searchinput.focus();
      this.searchActive = true;
    },

    search(e) {
      this.input = e.target.value;
      if (this.input && this.input.length > 2) {
        clearTimeout(this.debounce);
        this.page = 1;
        this.debounce = setTimeout(() => {
          if (this.input.length > 2) this.getProducts(this.input);
        }, 1000);
      } else {
        this.result = [];
      }
    },

    async searchMore() {
      this.page++;
      const { data } = await woocommerce.searchProducts(this.input, this.page);
      this.result = [...this.result, ...data];
    },

    async getProducts(query) {
      const { data, headers } = await woocommerce.searchProducts(
        query,
        this.page
      );
      this.result = [...data];
      if (headers['x-wp-totalpages']) {
        this.total = parseInt(headers['x-wp-totalpages']);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.nav-top {
  position: fixed;
  width: 100vw;
  min-height: 35px;
  max-width: $max-width;
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  background-color: $nav-background;
  transition: 0.3s all ease-out;
  z-index: 3;

  &.scrolled {
    padding: 10px 20px;

    .lev_logo {
      max-height: 30px;
    }
  }

  &.search_active {
    position: fixed !important;
    width: 100%;
    min-height: 100vh;
    align-items: flex-start !important;
    top: 0;
    background-color: $background-color !important;
    .nav-top__right {
      width: 100%;
      flex-direction: column;
    }
    .search {
      width: 100%;
    }
  }

  .product-list {
    &__wrapper {
      position: absolute;
      top: 80px;
      left: 0;
      width: 100%;
      height: calc(100vh - 80px);
      padding-bottom: 200px;
      overflow-y: auto;
    }
  }

  &__left,
  &__right {
    display: flex;
    align-items: center;
    max-height: 35px;
  }

  &__left {
    margin-right: auto;
  }

  &__dietitian {
    margin-right: 16px;
    &-circle {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 35px;
      width: 35px;
      border-radius: 50%;
      background-color: $grey-transparent;
    }
    &-name {
      font-family: $font;
      font-weight: 700;
      font-size: 0.8rem;
      color: $black;
    }
    &-img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 50%;
    }
  }

  .search {
    display: flex;
    align-items: center;

    &__nav,
    &__cart,
    &__search {
      width: 35px;
      height: 35px;
    }

    &__nav,
    &__search {
      position: initial;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    &__input {
      width: 0;
      height: 40px;
      padding: 0;
      border-radius: 0;
      border: none;
      background-color: transparent !important;

      &--active {
        width: 100%;
        padding: 0 40px 0 10px;
        border: none;
        border-bottom: 2px solid rgba($color: $dark-yellow, $alpha: 1);
        transition: 0.5s ease-in width;
      }
    }

    &__icon {
      width: 19px;
      height: 19px;
      transition: 0.5s ease-in all;
    }

    &__info {
      width: 100%;
      padding: 20% 20px;
      font-family: $font;
      font-size: 1.2rem;
      font-weight: 400;
      text-align: center;
      animation: fade-in 1s forwards;
      &::first-letter {
        text-transform: capitalize;
      }
      &-input {
        color: $dark-yellow;
      }
    }

    &__more {
      margin: 20px 0;
      width: 100%;
      font-family: $font;
      font-size: 0.8rem;
      font-weight: 700;
      text-align: center;
      text-decoration: underline;
      pointer-events: auto;
    }
  }
}
</style>
