<template>
  <li
    :style="getHeight(category.id)"
    :class="{
      dropdown: secondLevelCategories.length,
      'shop__categories--item-active': isCurrentCategory(category),
    }"
  >
    <div ref="label" @click="labelClick" class="header">
      <h4>
        {{ replace(category.name) }}
      </h4>
    </div>
    <ul
      ref="content"
      v-if="secondLevelCategories.length > 0"
      :class="{ hidden: openCategory != category.id }"
    >
      <li @click="$emit('save', category)">
        <h4>{{ $t('shop.all') }} {{ replace(category.name) }}</h4>
      </li>
      <li
        @click="$emit('save', c)"
        v-for="c in secondLevelCategories"
        :key="c.id"
        :class="{ 'shop__categories--item-active': isCurrentCategory(c) }"
      >
        <h4>{{ replace(c.name) }}</h4>
      </li>
    </ul>
    <img
      v-if="secondLevelCategories.length > 0"
      src="@/assets/arrow.svg"
      alt="arrow"
    />
  </li>
</template>

<script>
import { mapState } from 'vuex';
export default {
  props: {
    category: {
      type: Object,
      default: null,
    },
    openCategory: {
      type: Number,
      default: 0,
    },
    isHorizontal: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['open', 'save'],
  methods: {
    isCurrentCategory(category) {
      if (this.$route.name === 'shop') {
        return this.$route.params.slug === category.slug ? true : false;
      } else {
        return this.currentCategory && this.currentCategory === category.slug
          ? true
          : false;
      }
    },
    labelClick() {
      if (this.secondLevelCategories.length > 0) {
        this.$emit('open', this.category.id);
      } else this.$emit('save', this.category);
    },
    getHeight(id) {
      if (this.secondLevelCategories.length < 1) {
        return { height: 'auto' };
      } else if (id === this.openCategory) {
        let contentHeight =
          this.$refs.content.clientHeight + this.$refs.label.clientHeight;
        return { height: `${contentHeight}px` };
      } else if (this.$refs.label) {
        return { height: `${this.$refs.label.clientHeight}px` };
      } else return this.isHorizontal ? { height: '32px' } : { height: '58px' };
    },
    replace(category) {
      return category.replace(/&amp;/gi, '&');
    },
  },
  computed: {
    ...mapState(['categories', 'currentCategory']),
    secondLevelCategories() {
      let array = this.categories.filter((c) => c.parent === this.category.id);
      return array;
    },
    parentCategoryActive() {
      if (this.secondLevelCategories.length > 0) {
        let found = this.secondLevelCategories.find(this.isCurrentCategory);
        if (found) {
          return true;
        } else return false;
      } else return false;
    },
  },
};
</script>

<style lang="scss" scoped>
.shop__categories--item-active {
  background-color: transparent !important;
  h4 {
    font-weight: 500;
    color: $brown;
  }
}
li {
  position: relative;
  overflow-y: hidden;
  transition: all 0.5s ease-in-out;
  .header {
    padding: 20px;
    display: flex;
    align-items: center;
    &__active {
      background-color: none;
    }
  }
  h4 {
    text-align: left;
    margin-right: 8px;
    text-transform: capitalize;
    font-size: 16px;
    font-weight: 300;
  }
  img {
    position: absolute;
    right: 20px;
    top: 25px;
    pointer-events: initial;
  }
  li {
    display: flex;
    align-items: center;
    padding: 20px 40px;
    h4 {
      font-weight: 200;
    }
  }
}
</style>
