<template>
  <a v-if="internalLink" @click="$router.push(to)">
    <slot></slot>
  </a>
  <a v-else :href="externalLink" target="_blank">
    <slot></slot>
  </a>
</template>
<script>
export default {
  name: 'Link',

  props: {
    to: {
      type: String,
      required: true,
    },
  },

  computed: {
    isUrl() {
      return /^http(s?):\/\/.*$/.test(this.to);
    },

    internalLink() {
      if (this.isUrl) {
        const url = new URL(this.to);
        return url.origin === process.env.WORDPRESS_URL ||
          url.origin === process.env.URL
          ? url.pathname
          : false;
      }
      return this.to;
    },

    externalLink() {
      return this.isUrl && !this.internalLink ? this.to : false;
    },
  },

  methods: {
    clicked() {
      this.$emit('clicked');
    },
  },
};
</script>
