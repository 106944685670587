<template>
  <div @click="toggle('cart')" class="cart" @click.stop>
    <img src="@/assets/shopping-bag.svg" alt="" />
    <div
      :class="{ animate: animate }"
      class="count flex"
      v-if="cartQuantity > 0"
    >
      <h5>{{ cartQuantity }}</h5>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
export default {
  data() {
    return {
      animate: false,
    };
  },
  computed: {
    ...mapState(['cart']),
    cartQuantity() {
      let quantity = 0;
      this.cart.forEach((e) => {
        quantity += parseInt(e.quantity);
      });
      return quantity;
    },
  },
  methods: {
    ...mapActions(['toggle']),
  },
  watch: {
    cartQuantity() {
      this.animate = true;
      setTimeout(() => {
        this.animate = false;
      }, 200);
    },
  },
};
</script>

<style lang="scss" scoped>
.cart {
  position: relative;
  padding: 8px;
  border-radius: 50%;
  max-height: 35px;
  img {
    height: 19px;
    width: 19px;
  }
  .count {
    position: absolute;
    top: -5px;
    right: -7px;
    height: 22px;
    border-radius: 100%;
    width: 22px;
    transition: 0.3s all ease-in-out;
    background-color: $accent-yellow;
    align-items: center;
    h5 {
      font-size: 11px;
      line-height: 0;
    }
  }
  .animate {
    height: 33px;
    width: 33px;
  }
}
</style>
