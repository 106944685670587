<template>
  <li
    @click="$emit('label-clicked')"
    :style="contentOpenClose"
    class="appointment-history__card appointment-card"
  >
    <div class="appointment-card__label">
      <h4 class="block-title">
        {{ month }}
        <strong>{{ day }}</strong>
      </h4>

      <div class="appointment-card__label-arrow">
        <img
          :class="{ rotate: open }"
          src="@/assets/arrow-yellow.svg"
          alt="arrow"
        />
      </div>
    </div>

    <div ref="content" class="appointment-card__content">
      <div v-if="dietitian" class="appointment-card__dietitian">
        <div
          v-if="dietitian.image === '/images/placeholder.svg'"
          class="img__placeholder flex"
        >
          <h2>{{ initials }}</h2>
        </div>

        <img v-else :src="`${url}/${dietitian.image}`" alt="" />

        <h5 class="appointment-card__measurement-data">
          {{ $t('appointments.dietitian') }}, {{ dietitian.name }}
        </h5>
      </div>

      <p v-if="appointment.measurements" class="body-regular">
        {{ appointment.measurements.notes }}
      </p>

      <div class="appointment-card__measurements">
        <div class="appointment-card__measurement">
          <h5 class="appointment-card__measurement-label">
            {{ $t('progress.weight') }}
          </h5>

          <div v-if="appointment.measurements">
            <h5
              v-if="appointment.measurements.current_weight"
              class="appointment-card__measurement-data"
            >
              {{ shorten(appointment.measurements.current_weight) }}kg
            </h5>

            <h5 v-else class="appointment-card__measurement-data">
              {{ $t('appointments.no_data') }}
            </h5>
          </div>

          <div v-else>
            <h5 class="appointment-card__measurement-data">
              {{ $t('appointments.no_data') }}
            </h5>
          </div>
        </div>

        <div class="appointment-card__measurement">
          <h5 class="appointment-card__measurement-label">
            {{ $t('progress.body_fat') }}
          </h5>

          <div v-if="appointment.measurements">
            <h5
              v-if="appointment.measurements.body_fat"
              class="appointment-card__measurement-data"
            >
              {{ shorten(appointment.measurements.body_fat) }}%
            </h5>

            <h5 v-else class="appointment-card__measurement-data">
              {{ $t('appointments.no_data') }}
            </h5>
          </div>

          <div v-else>
            <h5 class="appointment-card__measurement-data">
              {{ $t('appointments.no_data') }}
            </h5>
          </div>
        </div>

        <div class="appointment-card__measurement">
          <h5 class="appointment-card__measurement-label">BMI</h5>

          <div v-if="appointment.measurements">
            <h5
              v-if="appointment.measurements.bmi"
              class="appointment-card__measurement-data"
            >
              {{ shorten(appointment.measurements.bmi) }}
            </h5>

            <h5 v-else class="appointment-card__measurement-data">
              {{ $t('appointments.no_data') }}
            </h5>
          </div>

          <div v-else>
            <h5 class="appointment-card__measurement-data">
              {{ $t('appointments.no_data') }}
            </h5>
          </div>
        </div>

        <div class="appointment-card__measurement">
          <h5 class="appointment-card__measurement-label">
            {{ $t('progress.hip') }}
          </h5>

          <div v-if="appointment.measurements">
            <h5
              v-if="appointment.measurements.hip"
              class="appointment-card__measurement-data"
            >
              {{ shorten(appointment.measurements.hip) }}cm
            </h5>

            <h5 v-else class="appointment-card__measurement-data">
              {{ $t('appointments.no_data') }}
            </h5>
          </div>

          <div v-else>
            <h5 class="appointment-card__measurement-data">
              {{ $t('appointments.no_data') }}
            </h5>
          </div>
        </div>

        <div class="appointment-card__measurement">
          <h5 class="appointment-card__measurement-label">
            {{ $t('progress.thigh') }}
          </h5>

          <div v-if="appointment.measurements">
            <h5
              v-if="appointment.measurements.thigh"
              class="appointment-card__measurement-data"
            >
              {{ shorten(appointment.measurements.thigh) }}cm
            </h5>

            <h5 v-else class="appointment-card__measurement-data">
              {{ $t('appointments.no_data') }}
            </h5>
          </div>

          <div v-else>
            <h5 class="appointment-card__measurement-data">
              {{ $t('appointments.no_data') }}
            </h5>
          </div>
        </div>

        <div class="appointment-card__measurement">
          <h5 class="appointment-card__measurement-label">
            {{ $t('progress.waist') }}
          </h5>

          <div v-if="appointment.measurements">
            <h5
              v-if="appointment.measurements.waist"
              class="appointment-card__measurement-data"
            >
              {{ shorten(appointment.measurements.waist) }}cm
            </h5>

            <h5 v-else class="appointment-card__measurement-data">
              {{ $t('appointments.no_data') }}
            </h5>
          </div>

          <div v-else>
            <h5 class="appointment-card__measurement-data">
              {{ $t('appointments.no_data') }}
            </h5>
          </div>
        </div>
      </div>
    </div>
  </li>
</template>

<script>
import shared from '@/shared';
import { mapGetters } from 'vuex';
import moment from 'moment';

export default {
  computed: {
    ...mapGetters(['getDietitian']),
    dietitian() {
      return this.getDietitian(this.appointment.dietitian_id);
    },
    contentOpenClose() {
      if (!this.componentLoaded) {
        return null;
      }
      if (this.open) {
        return { height: `${this.contentHeight + 72}px` };
      } else {
        return { height: '72px' };
      }
    },
    month() {
      return moment(this.appointment.start)
        .locale(process.env.VUE_APP_LANG || 'en')
        .format('MMMM');
    },
    day() {
      return moment(this.appointment.start)
        .locale(process.env.VUE_APP_LANG || 'en')
        .format('DD');
    },
    contentHeight() {
      return this.$refs.content.getBoundingClientRect().height;
    },
    initials() {
      if (!this.dietitian) return 'XX';
      return this.dietitian.name
        .split(' ')
        .map((c) => c.toUpperCase().substring(0, 1))
        .join('');
    },
  },
  props: {
    open: {
      type: Boolean,
      default: false,
    },
    appointment: Object,
  },
  mounted() {
    this.componentLoaded = true;
  },
  data() {
    return {
      componentLoaded: false,
      url: process.env.VUE_APP_BOLEV_URL,
    };
  },
  created() {
    this.shorten = shared.shorten;
  },
  emits: ['label-clicked'],
};
</script>

<style lang="scss" scoped>
.appointment-card {
  display: flex;
  flex-direction: column;
  margin: 10px;
  background-color: $background-color-light;
  border-radius: $rounded-l;
  box-shadow: $card-shadow-l;
  overflow: hidden;
  transition: 0.3s ease-in-out;
  &__label {
    padding: 36px 20px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 72px;
    img {
      transition: all $open-card ease-in-out;
      z-index: 2;
    }
  }
  &__dietitian {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    padding: 0 16px;
    img,
    .img__placeholder {
      height: 40px;
      width: 40px;
      border-radius: 100%;
      object-fit: cover;
    }
    .img__placeholder {
      background-color: $grey-transparent;
      align-items: center;
      h2 {
        font-size: 1rem;
        color: $orange;
      }
    }
    h5 {
      margin-left: 20px;
      font-size: 16px;
      font-weight: 400;
      text-align: left;
      text-transform: capitalize;
      letter-spacing: 0;
    }
  }
  &__measurements {
    margin-top: 20px;
    padding: 0 10px 12px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 16px;
    h5 {
      text-transform: capitalize;
    }
  }
  &__measurement {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100px;
    height: 100px;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    text-align: center;
    background-color: #fcf5e5;
    &-label {
      font-weight: 600;
    }
    &-data {
      font-weight: 400;
    }
  }
  .rotate {
    transform: rotate(180deg);
  }
}
</style>
