<template>
  <div class="coupon">
    <div class="flex label" @click="this.open = !this.open">
      <h4 class="small-label">{{ $t('checkout.use_discount') }}</h4>
      <img src="@/assets/arrow.svg" alt="open" />
    </div>
    <div :class="{ open: open }" class="flex content">
      <input
        @input="resetError"
        v-model="input"
        type="text"
        @keyup.enter="applyCoupon(input)"
      />
      <PrimaryButton @click="applyCoupon(input)" :title="apply" :width="110" />
    </div>
    <p v-if="couponError && this.open" class="error">{{ error }}</p>
    <template v-if="order && order.coupons">
      <div
        class="flex discount"
        v-for="coupon in order.coupons"
        :key="coupon.id"
      >
        <p
          class="success"
          v-html="
            $t('checkout.coupon_added', {
              code: `<span>${coupon.code}</span>`,
            })
          "
        ></p>
        <!-- <p class="success">coupon {{coupon.code}} added</p> -->
        <p v-if="coupon.type === 1" class="amount">-{{ coupon.amount }} €</p>
        <p v-else class="amount">-{{ coupon.amount }} %</p>
      </div>
    </template>
  </div>
</template>

<script>
import PrimaryButton from '@/components/ui/PrimaryButton.vue';
import { mapActions, mapState } from 'vuex';
export default {
  components: {
    PrimaryButton,
  },
  data() {
    return {
      apply: this.$t('checkout.apply'),
      open: false,
      input: '',
    };
  },
  methods: {
    ...mapActions(['applyCoupon', 'couponReset']),
    resetError() {
      if (this.couponError) {
        this.couponReset();
      }
    },
  },
  computed: {
    ...mapState(['couponError', 'order']),
    error() {
      return this.$t('checkout.' + this.couponError);
    },
  },
};
</script>

<style lang="scss" scoped>
.coupon {
  padding: 20px;
  h4 {
    text-align: start;
  }
  .label {
    width: 100%;
    justify-content: space-between;
  }
  .content {
    margin-top: 14px;
    height: 0;
    overflow: hidden;
    transition: 0.2s all ease-in;
    input {
      width: 100%;
      border: none;
      border-bottom: 1px solid rgba($black, 0.1);
    }
    a {
      margin-left: 20px;
    }
  }
  .open {
    height: 53px;
  }
  .error,
  .amount {
    color: $orange;
  }
  .error,
  .amount,
  .success {
    ::v-deep span {
      text-decoration: underline;
    }
    font-size: 12px;
    &::first-letter {
      text-transform: capitalize;
    }
  }
  .discount {
    justify-content: space-between;
  }
}
</style>
