<template>
  <!-- x from -100 to 200 -->
  <!-- y from 0 to 100 -->
  <svg viewBox="0 -10 100 100" class="chart">
    <polyline
      class="polyline"
      fill="none"
      stroke="#dfb859"
      stroke-width="15"
      :points="points"
    />
  </svg>
</template>

<script>
export default {
  data() {
    return {
      points: '',
    };
  },
  props: {
    bodyData: Array,
    firstInt: Number,
    lastInt: Number,
  },

  mounted() {
    //Setting up all variables and then calling paintLine function
    this.calculate();
  },
  methods: {
    paintLine(xLength, ratio, firstInt, firstPoint) {
      let pointsString = '';
      for (let i = 0; i < this.bodyData.length; i++) {
        let x = i * xLength - 100;
        let y = (firstInt - parseFloat(this.bodyData[i])) * ratio + firstPoint;
        // keeps the line inside the image in case the numbers are incorrect
        if (y < 0) {
          y = 0;
        } else if (y > 80) {
          y = 80;
        }
        pointsString = pointsString + `${x},${y} `;
      }
      this.points = pointsString;
    },
    calculate() {
      //Decides the distance between the points on the x-axis
      let numberOfPoints = this.bodyData.length;
      let xLength = 300 / (numberOfPoints - 1);

      // Get the smallest and the highest value
      let min = Math.min.apply(Math, this.bodyData);
      let max = Math.max.apply(Math, this.bodyData);

      // Decides startingpoints and ratio for the curve depending on numbers
      let firstPoint = 40;
      let lastPoint = 40;
      let ratio = 1;

      if (this.lastInt > this.firstInt) {
        firstPoint = 40;
        lastPoint = 60;
        ratio = (lastPoint - firstPoint) / (max - min);
      } else if (this.lastInt < this.firstInt) {
        firstPoint = 0;
        lastPoint = 80;
        ratio = (lastPoint - firstPoint) / (max - min);
      }

      //Adds values to string in format for svg
      this.paintLine(xLength, ratio, this.firstInt, firstPoint);
    },
  },
};
</script>

<style lang="scss" scoped>
.chart {
  height: 100%;
  width: 100%;
}
</style>
