<template>
  <div
    @click.stop
    @click="toggle('filter')"
    :class="{ sort_hide: searchActive || input }"
    class="shop-menu"
  >
    <img
      src="@/assets/filter.svg"
      alt="filter"
      :class="{ active: hasActiveTag }"
    />
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';

export default {
  props: {
    input: {
      type: String,
      default: '',
    },
  },
  computed: {
    ...mapState(['searchActive', 'tags']),
    hasActiveTag() {
      let activeTags = this.tags.filter((t) => t.active);
      return activeTags.length > 0 ? true : false;
    },
  },

  methods: {
    ...mapActions(['toggle']),
  },
};
</script>

<style lang="scss" scoped>
.sort_hide {
  opacity: 0;
}
.shop-menu {
  display: flex;
  width: 16px;
  transition: opacity 0.5s ease-in-out;
  img {
    width: 20px;
  }
  .active {
    filter: invert(69%) sepia(40%) saturate(522%) hue-rotate(5deg)
      brightness(101%) contrast(90%);
  }
}
</style>
