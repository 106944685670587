<template>
  <!-- <Password v-if="passwordTesting"/> -->

  <div class="login flex-column">
    <img
      class="lev-logo"
      :class="{ logo_animate: isActive }"
      src="@/assets/lev-logo.svg"
      alt=""
    />

    <ul class="trust" :class="{ active: !isActive }">
      <li>
        <img src="@/assets/checked.svg" alt="" />

        <h6 class="medium-label-thin">Providing selected food plans</h6>
      </li>

      <li>
        <img src="@/assets/checked.svg" alt="" />

        <h6 class="medium-label-thin">Guiding you through weight loss</h6>
      </li>

      <li>
        <img src="@/assets/checked.svg" alt="" />

        <h6 class="medium-label-thin">Enjoyable online shop experience</h6>
      </li>
    </ul>

    <div @click="setActive" class="login-form flex-column">
      <InputMail @type="mailTyping" @enter-clicked="login" />

      <InputPassword
        :placeholder="'Password'"
        @type="passwordTyping"
        @enter-clicked="login"
      />

      <h6 v-if="error">{{ error }}</h6>

      <PrimaryButton
        :saving="saving"
        :disabled="!finished"
        @click="login"
        :title="'Sign in'"
      />
    </div>

    <p class="body-regular" @click="openOverlay">Recover password</p>
  </div>
</template>

<script>
import { mapState, mapMutations, mapActions } from 'vuex';
import PrimaryButton from '@/components/ui/PrimaryButton.vue';
import InputMail from '@/components/ui/InputMail.vue';
import InputPassword from '@/components/ui/InputPassword.vue';
import clickOutside from '@/directives/ClickOutside';

export default {
  name: 'login',

  components: {
    PrimaryButton,
    InputMail,
    InputPassword,
  },

  data() {
    return {
      saving: false,
      passwordTesting: true,
      active: false,
      email: null,
      password: null,
      regExp:
        // eslint-disable-next-line no-useless-escape
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/,
    };
  },

  mounted() {
    this.logout();
  },

  directives: {
    clickOutside: clickOutside,
  },

  computed: {
    ...mapState(['user', 'authStatus', 'error', 'timer']),
    isActive() {
      if (this.error === null && !this.active) {
        return false;
      } else return true;
    },
    finished() {
      if (this.regExp.test(this.email) && /^.{6,}$/.test(this.password)) {
        return true;
      } else return false;
    },
  },

  watch: {
    timer: {
      handler() {
        if (this.timer > 0) {
          setTimeout(() => {
            this.$store.state.timer--;
          }, 1000);
        } else this.passwordLinkReset();
      },
      immediate: true,
    },
  },

  methods: {
    ...mapMutations(['logout']),

    ...mapActions(['passwordLinkReset']),

    openOverlay() {
      this.$store.state.passwordOverlay = !this.$store.state.passwordOverlay;
    },

    setActive() {
      this.active = true;
    },

    mailTyping(mail) {
      this.email = mail;
    },
    passwordTyping(password) {
      this.password = password;
    },

    testEmail() {
      if (this.regExp.test(this.email)) {
        this.mailValid = true;
      } else this.mailValid = false;
    },

    login() {
      this.saving = true;
      this.$store.commit('setError', null);
      if (!this.regExp.test(this.email)) {
        this.$store.commit('setError', 'Please fill in a valid email');
        this.saving = false;
        return false;
      }
      if (!/^.{6,}$/.test(this.password)) {
        this.$store.commit('setError', 'Please fill in a valid password');
        this.saving = false;
        return false;
      }
      this.$store
        .dispatch('login', { email: this.email, password: this.password })
        .then(() => {
          this.saving = false;
          this.$router.push({ name: 'mylev' });
        })
        .catch((err) => {
          this.saving = false;
          this.$store.commit('setError', 'Wrong mail or incorrect password');
          console.log(err);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
$transition-length: 0.7s;

.login {
  z-index: 999;
  width: 100vw;
  padding: 40px 38px;
  max-width: $max-width;
  background-color: $background-color;
  min-height: 80vh;
  .active {
    max-height: 100px !important;
    opacity: 1 !important;
  }
  .trust {
    transition: all $transition-length ease-out;
    max-height: 0;
    overflow: hidden;
    width: 100%;
    margin-bottom: 40px;
    opacity: 0;
    li {
      display: flex;
      img {
        width: 15px;
        margin-right: 16px;
      }
      margin: 10px 0;
    }
    h6 {
      text-align: left;
    }
  }
  .lev-logo {
    width: 122px;
    margin-bottom: 40px;
    margin-top: 20px;
    transition: all $transition-length ease-out;
  }
  .logo_animate {
    margin-bottom: 20px;
    margin-top: 0px;
  }
  p {
    margin: 0;
    text-decoration: underline;
    pointer-events: initial;
  }
  .login-form {
    width: 100%;
    a,
    h6 {
      margin-bottom: 16px;
    }
  }
}
</style>
