<template>
  <div class="card-container">
    <h4 v-if="title" class="small-label slide-title">{{ title }}</h4>
    <div
      @touchstart.stop
      @touchmove.stop
      @touchend.stop
      :class="{ scroll: scroll, less_padding: !padding }"
      class="cards"
    >
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: String,
    scroll: {
      type: Boolean,
      default: true,
    },
    padding: {
      type: Boolean,
      default: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.card-container {
  width: 100vw;
  max-width: $max-width;

  .slide-title {
    text-align: start;
    padding-left: 20px;
  }
  .scroll {
    overflow-x: auto;
  }
  .cards {
    display: flex;
    padding: 20px;
    width: 100%;
    max-width: $max-width;
  }
  .less_padding {
    padding: 10px 0;
  }
}
</style>
