<template>
  <div :style="{ top: top }" class="onboarding">
    <div class="relative">
      <p class="body-regular">
        {{ text }}
      </p>
      <div :style="position" class="tag" />
      <PrimaryButton @click="next" :title="'next'" />
    </div>
  </div>
</template>

<script>
import PrimaryButton from '@/components/ui/PrimaryButton.vue';
import { mapMutations } from 'vuex';

export default {
  components: { PrimaryButton },
  props: {
    text: {
      type: String,
      default: '',
    },
    position: {
      type: Object,
    },
    top: {
      type: String,
      default: '10px',
    },
  },
  methods: {
    ...mapMutations(['inc']),
    next() {
      this.inc('onboarding');
    },
  },
};
</script>

<style lang="scss" scoped>
.onboarding {
  position: absolute;
  z-index: 999;
  background-color: white;
  width: calc(100vw - 40px);
  border-radius: $rounded;
  left: 50%;
  transform: translate(-50%, 0);
  .relative {
    padding: 10px 15px;
    height: 100%;
    width: 100%;
    position: relative;
    p {
      line-height: 22px;
    }
    .tag {
      z-index: -1;
      width: 0;
      height: 0;
      border: 30px solid transparent;
      border-bottom: 40px solid white;
      position: absolute;
      &::after {
        content: '';
        position: absolute;
        left: -30px;
        top: 40px;
        width: 0;
        height: 0;
        border: 30px solid transparent;
        border-top: 40px solid white;
      }
    }
  }
}
</style>
