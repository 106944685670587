<template>

  <div v-if="listPhase?.length" class="list-of-foods">

    <div class="list-of-foods__label">

      <h2 class="list-of-foods__title page-title">
         {{ $t('foodPlan.auth_food') }}
      </h2>

      <h4 class="block-subtitle">{{ $t('foodPlan.food_list') }}</h4>

    </div>

    <div v-if="listPhase?.length" class="list-of-foods__content">

      <template v-for="(phase, index) in listPhase" :key="phase.id">

        <FoodItem
          :phase="phase"
          @label-clicked="toggleOpen(phase.id)"
          :open="listOfComponents[index].open"
        />

      </template>

    </div>

  </div>

</template>

<script>
import { mapState } from "vuex";
import FoodItem from "@/components/FoodItem.vue";
import bolev from "../apis/bolev";

export default {
  data() {
    return {
      listOfComponents: [],
      id: 1,

      phaseOne: [],
      phaseTwo: [],
      phaseThree: [],
      phaseFour: [],
    };
  },
  components: {
    FoodItem,
  },

  methods: {
    setCurrent(id) {
      this.id = id;
    },
    toggleOpen(id) {
      this.listOfComponents.forEach((e) => {
        e.id === id ? (e.open = !e.open) : (e.open = false);
      });
    },
    close() {
      this.listOfComponents.forEach((e) => {
        e.open = false;
      });
    },
  },

  computed: {
    ...mapState(["phase", "onboarding"]),
    contentOpenClose() {
      if (this.open) return { height: `${this.contentHeight + 84}px` };
      return { height: "84px" };
    },
    contentHeight() {
      return this.$refs.content.getBoundingClientRect().height;
    },
    listPhase() {
      if (this.phase.phase === 1) {
        return this.phaseOne;
      } else if (this.phase.phase === 2) {
        return this.phaseTwo;
      } else if (this.phase.phase === 3) {
        return this.phaseThree;
      } else {
        return this.phaseFour;
      }
    },
    currentCat() {
      return this.listPhase.find((c) => c.id === this.id);
    },
    foodList(typeId) {
      return this.listPhase.find((c) => c.id === typeId);
    },
  },

  async mounted() {
    const phaseData = await bolev.phaseData(process.env.VUE_APP_LANG || "en");
    this.phaseOne = phaseData.phase_one;
    this.phaseTwo = phaseData.phase_two;
    this.phaseThree = phaseData.phase_three;
    this.phaseFour = phaseData.phase_four;
    this.listPhase.forEach((e) => {
      this.listOfComponents.push({ id: e.id, open: false });
    });
  },
};
</script>

<style lang="scss" scoped>
.list-of-foods {
  padding: 0 10px;
  padding-bottom: 100px;
  &__label {
    padding: 0 10px;
    margin-bottom: 20px;
    flex-direction: column;
    align-items: flex-start !important;
  }
  &__title {
    margin-top: 34px;
  }
}
.food-item {
  background-color: $background-color;
  box-shadow: $card-shadow-l;
  border: none;
  border-radius: $rounded-l;
  &:not(:last-child) {
    margin-bottom: 16px;
  }
  &__label {
    img {
      transition: all $open-card ease-in-out;
      z-index: 2;
    }
    .layer-img {
      top: 25px;
      z-index: 2;
    }
  }
  &__title {
    font-size: 18px;
    font-weight: 700;
    line-height: 16px;
    text-align: left;
    text-transform: lowercase;
    &::first-letter {
      text-transform: capitalize;
    }
  }
  &__arrow {
    position: absolute;
    top: 36px;
    right: 20px;
    height: 14px;
    width: 16px;
    display: flex;
    align-items: center;
    overflow: hidden;
  }
  &__content {
    padding-bottom: 10px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 10px;
    &-item {
      margin: 0;
      width: 100%;
    }
  }
  &__name {
    margin: 0;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    &::first-letter {
      text-transform: capitalize;
    }
  }
  .label {
    img {
      transition: all $open-card ease-in-out;
      z-index: 2;
    }
    .layer-img {
      top: 25px;
      z-index: 2;
    }
  }
  .rotate {
    transform: rotate(180deg);
  }
}
</style>

