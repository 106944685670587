import { createApp } from 'vue';
import App from './App.vue';
import store from './store';
import router from './router';
window.Pusher = require('pusher-js');

import setupI18n from '@/plugins/i18n';

if ('serviceWorker' in navigator) {
  navigator.serviceWorker
    .register('/sw.js')
    .then(function (registration) {
      console.log('Registration successful, scope is:', registration.scope);
    })
    .catch(function (error) {
      console.log('Service worker registration failed, error:', error);
    });
}

const locale = process.env.VUE_APP_LANG || 'en';

(async () => {
  const i18n = await setupI18n({
    globalInjection: true,
    legacy: false,
    locale,
  });

  createApp(App).use(i18n).use(store).use(router).mount('#app');
})();
