<template>
  <div class="profile" ref="profile">
    <NavTop :title="title" />

    <div class="profile-content">
      <AboutMe />
      <AppointmentHistory />
      <!-- <LevContainer :title="myOrders">
        <template v-for="order in orders" :key="order.id">
          <OrderCard @click="goToOrder(order.hash)" :order="order" />
        </template>
      </LevContainer> -->
      <Delivery :billing="true" />
      <h6 @click.stop="openGuide('guide')" class="guide">
        {{ $t('profile.show_guide') }}
      </h6>
      <h6 class="logout" @click="handleLogout">{{ $t('profile.log_out') }}</h6>
    </div>
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex';
import shared from '@/shared';
import AboutMe from '@/components/AboutMe.vue';
// import LevContainer from "@/components/LevContainer.vue";
// import OrderCard from "@/components/OrderCard.vue";
import Delivery from '@/components/Delivery.vue';
import NavTop from '@/components/NavTop.vue';
import AppointmentHistory from '@/components/AppointmentHistory.vue';

export default {
  created() {
    this.addSwipeListeners = shared.addSwipeListeners;
    this.removeSwipeListeners = shared.removeSwipeListeners;
  },
  components: {
    AboutMe,
    // LevContainer,
    // OrderCard,
    Delivery,
    NavTop,
    AppointmentHistory,
  },
  data() {
    return {
      addCard: false,
      movement: 0,
      touch: {
        startX: 0,
        endX: 0,
      },
    };
  },
  methods: {
    ...mapActions(['toggle', 'animationStartPos', 'logout']),
    ...mapMutations(['inc', 'mutate']),
    goToOrder(hash) {
      this.$router.push({ name: 'order', params: { hash } });
    },
    toggleAddCard() {
      this.addCard = !this.addCard;
    },
    handleLogout() {
      this.logout();
      this.$router.push('/logout');
    },
    openGuide() {
      this.$router.push({ path: '/' });
      document.body.className = 'no-scroll';
      this.inc('onboarding');
      this.mutate({
        property: 'overlayActive',
        with: true,
      });
    },
  },
  computed: {
    ...mapState(['orders']),
    myOrders() {
      return this.$t('profile.my_orders');
    },
    title() {
      return this.$t('profile.profile');
    },
  },
};
</script>

<style lang="scss" scoped>
.profile {
  background-color: $background-color-peach;
  .profile-content {
    overflow-y: auto;
    padding-top: 75px;
    padding-bottom: 80px;
  }
  top: 0;
  right: 1px;
  width: 100vw;
  max-width: $max-width;
  a {
    margin: 20px;
  }
  .top {
    div {
      height: 100%;
      width: 85px;
      img {
        width: 17px;
      }
    }
    align-items: center;
    height: $header-height;
    justify-content: space-between;
  }
  .logout {
    margin: 80px 0 40px 0;
  }
  .guide {
    margin-top: 40px;
    font-size: 0.8em;
    color: $orange;
  }
  .logout,
  .guide {
    text-transform: capitalize;
    pointer-events: initial;
    cursor: pointer;
  }
}
</style>
