import { createStore } from 'vuex';
import createPersistedState from 'vuex-persistedstate';
import router from '../router';
import shared from '../shared.js';
import bolev from '../apis/bolev';
import woocommerce from '../apis/woocommerce';
import wordpress from '../apis/wordpress';

const state = {
  patientId: null,
  orders: [],
  promotions: [],
  promotionsShop: [],
  couponError: null,
  deletedCard: null,
  shipping: null,
  activeCard: null,
  overlayActive: false,
  counterActive: false,
  newProductDays: 3,
  tempImage: null,
  user: null,
  admin: null,
  token: null,
  authStatus: null,
  error: null,
  addressError: null,
  cart: [],
  allProducts: [],
  categories: [],
  tags: [],
  currentCategory: null,
  prices: [],
  savedCards: [],
  posts: [],
  clinics: [],
  chartData: {},
  futureAppointments: null,
  pastAppointments: null,
  measurements: null,
  phase: null,
  dietitians: null,
  indexMylev: 0,
  animationStartPos: 0,
  profileOverlay: false,
  contactOverlay: false,
  chartOverlay: false,
  passwordOverlay: false,
  cartOverlay: false,
  filterOverlay: false,
  appointmentOverlay: false,
  navBottomActive: true,
  levPoints: false,
  passwordLinkActive: true,
  timer: null,
  deliveryAddress: [],
  order: null,
  lastOrder: {},
  onboarding: 0,
  firstTimeUser: true,
  savedSearch: '',
  sortBy: 'popular',
  scrollY: null,
  mbwayPending: false,
  myProducts: [],
  options: null,
};

const getters = {
  isAdmin: (state) => state.admin !== null,

  getStock: (state) => (sku) => {
    const price = state.prices.find((p) => p.sku === sku);
    return price && price.product
      ? {
          in_stock: price.product.in_stock,
          low_stock: price.product.low_stock,
          stock: price.product.stock,
        }
      : null;
  },

  products: (state) => {
    return state.allProducts
      .filter((p) => {
        const product = state.prices.find((price) => price.sku === p.sku);
        return product?.product?.available ? p : null;
      })
      .filter((p) => p);
  },

  getPrice: (state) => (sku) => {
    const price = state.prices.find((p) => p.sku === sku);
    return price ? Number(price.price) : null;
  },

  getActualPrice: (state, getters) => (sku) => {
    const product = state.allProducts.find((p) => p.sku === sku);
    if (product && product.on_sale) {
      return Number(product.sale_price);
    }
    return getters.getPrice(sku);
  },

  getClinicName: (state) => (id) => {
    let clinic = state.clinics.find((i) => i.id == id);
    return clinic.name;
  },

  getProduct: (state) => (slug) => {
    const found = state.allProducts.find((p) => p.slug == slug);
    return found;
  },

  getProductImage: (state) => (sku) => {
    const found = state.allProducts.find((p) => p.sku === sku);
    return found ? found.images[0] : null;
  },

  getOrder: (state) => (id) => state.orders.find((o) => o.id == id) || null,

  getOrderByHash: (state) => (hash) =>
    state.orders.find((o) => o.hash == hash) || null,

  getDietitian: (state) => (id) =>
    state.dietitians.find((i) => i.id === id) || state.dietitians[0],

  getCart: (state, getters) => {
    let cart = [...state.cart].map((product) => {
      const found = state.allProducts.find((p) => p.sku === product.sku);
      return {
        ...found,
        ...product,
        ...{
          bolev_price: parseFloat(getters.getPrice(found.sku)),
          sale_price: parseFloat(found.sale_price),
        },
      };
    });
    return cart;
  },

  getUniqueMeals: (state) => {
    return state.phase && state.phase.meals
      ? state.phase.meals
          .sort((a, b) => a.id - b.id)
          .reduce(
            (acc, curr) =>
              acc.map((meal) => meal.time_of_day).includes(curr.time_of_day)
                ? acc
                : [...acc, curr],
            []
          )
      : [];
  },

  getMealsSorted: (state, getters) => {
    return getters.getUniqueMeals.sort((a, b) => {
      if (a.weight > b.weight) {
        return 1;
      } else return -1;
    });
  },

  getCheckoutCart: (state) => {
    if (!state.order) {
      return [];
    }
    return [...state.order.order_rows].map((product) => {
      const found = state.allProducts.find((p) => p.sku === product.sku);
      return {
        ...product,
        ...{
          bolev_price: parseFloat(found.bolev_price),
          date_created: found.date_created,
          on_sale: found.on_sale,
          sale_price: parseFloat(found.sale_price),
          name: found.name,
          images: found.images,
        },
      };
    });
  },

  getLastOrder: (state) => {
    const array = state.lastOrder.order_rows.map((product) => {
      const found = state.allProducts.find((p) => p.sku === product.sku);
      return {
        ...product,
        ...{
          bolev_price: parseFloat(found.bolev_price),
          date_created: found.date_created,
          on_sale: found.on_sale,
          sale_price: parseFloat(found.sale_price),
          name: found.name,
          images: found.images,
        },
      };
    });
    return array;
  },

  getOrderRows: (state) => (id) => {
    const order = state.orders.find((o) => o.id == id);
    const array = order.order_rows.map((product) => {
      const found = state.allProducts.find((p) => p.sku === product.sku);
      return {
        ...product,
        ...{
          bolev_price: parseFloat(found.bolev_price),
          date_created: found.date_created,
          on_sale: found.on_sale,
          sale_price: parseFloat(found.sale_price),
          name: found.name,
          images: found.images,
        },
      };
    });
    return array;
  },

  getCartOrder: (state, getters) => {
    return [...state.cart].map((cartItem) => {
      const found = state.allProducts.find((p) => p.sku === cartItem.sku);
      return {
        ...cartItem,
        ...{ unit_price: parseFloat(getters.getActualPrice(found.sku)) },
      };
    });
  },

  subTotal: (_, getters) => {
    let amount = 0;
    getters.getCart.forEach((e) => {
      if (e.on_sale) {
        amount += e.sale_price * e.quantity;
      } else {
        amount += e.bolev_price * e.quantity;
      }
    });
    return amount.toFixed(2);
  },

  totalAmount: (state, getters) => {
    let total = parseFloat(getters.subTotal) + state.shipping.shipping.shipping;
    return parseFloat(total).toFixed(2);
  },

  itemQuantity: (state) => (sku) => {
    const found = state.cart.find((p) => p.sku === sku);
    if (found) return found.quantity;
  },
};

const mutations = {
  mutate(state, payload) {
    state[payload.property] = payload.with;
  },

  setProduct(state, product) {
    if (!state.allProducts.find((p) => p.id === product.id)) {
      state.allProducts = [...state.allProducts, product];
    }
  },

  setProducts(state, data) {
    const products = [...state.allProducts, ...data];
    state.allProducts = products.filter(
      (value, index, array) =>
        array.findIndex((p) => p.id === value.id) === index
    );
  },

  inc(state, payload) {
    state[payload]++;
  },

  setMbwayPending(state, value) {
    state.mbwayPending = value;
    state.overlayActive = value;
  },

  setToggle(state, payload) {
    state[payload + 'Overlay'] = !state[payload + 'Overlay'];
    state.overlayActive = !state.overlayActive;
  },

  setClose(state, payload) {
    state[payload + 'Overlay'] = false;
    state.overlayActive = false;
  },

  toggle(state, payload) {
    state[payload] = !state[payload];
  },

  setError: (state, err) => {
    state.error = err;
  },

  setAddressError: (state, err) => {
    state.addressError = err;
    setTimeout(() => {
      state.addressError = null;
    }, 4000);
  },

  setMbDetails: (state, payload) => {
    state.lastOrder = { ...state.lastOrder, ...{ mb: payload } };
  },

  filterCart: (state) => {
    state.cart = state.cart.filter((product) => {
      let found = state.allProducts.find((p) => p.sku === product.sku);
      return found ? product.sku === found.sku : null;
    });
  },

  addToCart: (state, sku) => {
    let found = state.cart.find((p) => p.sku === sku);
    if (found) {
      found.quantity++;
    } else state.cart.push({ sku: sku, quantity: 1 });
  },

  addCustomToCart: (state, payload) => {
    let found = state.cart.find((p) => p.sku === payload.sku);
    if (found && payload.quatity === 0) {
      state.cart = state.cart.filter((p) => {
        return p.sku !== payload.sku;
      });
    } else if (found) {
      found.quantity = payload.quantity;
    } else if (payload.quantity !== 0) {
      state.cart.push({ sku: payload.sku, quantity: payload.quantity });
    }
  },

  mergeCart: (state, payload) => {
    payload.forEach((p) => {
      let found = state.cart.find((i) => i.sku === p.sku);
      if (found) {
        found.quantity += p.quantity;
      } else {
        state.cart.push(p);
      }
    });
  },

  removeFromCart: (state, sku) => {
    let found = state.cart.find((p) => p.sku === sku);
    if (found.quantity > 1) {
      found.quantity--;
    } else
      state.cart = state.cart.filter((p) => {
        return p.sku !== sku;
      });
  },

  deleteFromCart: (state, sku) => {
    state.cart = state.cart.filter((p) => {
      return p.sku !== sku;
    });
  },

  authSuccess: (state, token) => {
    state.token = token;
    state.authStatus = 'success';
  },

  authError: (state) => (state.authStatus = 'error'),

  authStarted: (state) => {
    state.token = null;
    state.user = null;
    state.authStatus = 'loading';
  },

  logout: (state) => {
    state.token = null;
    state.user = null;
    state.patientId = null;
    state.authStatus = 'logged out';
  },

  addToSavedCards: (state, card) => state.savedCards.push(card),

  setPrices: (state, prices) => {
    state.prices = prices;
  },

  setFilterByTag: (state, id) => {
    let found = state.tags.find((t) => {
      return t.id === id;
    });
    if (found) {
      found.active = !found.active;
    }
  },

  setOneTagOnly: (state, id) => {
    state.tags.map((t) => {
      t.id === id ? (t.active = true) : (t.active = false);
      return t;
    });
  },
};

const actions = {
  toggle({ commit }, payload) {
    commit('setToggle', payload);
  },

  close({ commit }, payload) {
    commit('setClose', payload);
  },

  togglePassword({ commit, state }) {
    commit('mutate', {
      property: 'passwordOverlay',
      with: !state.passwordOverlay,
    });
  },

  setActiveCard({ commit }, payload) {
    commit('mutate', {
      property: 'activeCard',
      with: payload,
    });
  },

  filterByTag({ commit }, id) {
    commit('setFilterByTag', id);
  },

  async saveDelivery({ commit }, payload) {
    await commit('mutate', {
      property: 'deliveryAddress',
      with: [payload],
    });
  },

  async openChart({ commit }, payload) {
    await commit('mutate', {
      property: 'chartData',
      with: payload,
    });
    commit('setToggle', 'chart');
  },

  animationStartPos({ commit }, payload) {
    commit('mutate', {
      property: 'animationStartPos',
      with: payload,
    });
  },

  resetCart({ commit }) {
    commit('mutate', {
      property: 'cart',
      with: [],
    });
    commit('mutate', {
      property: 'order',
      with: null,
    });
  },

  async uploadImage({ commit }, img) {
    let form = new FormData();
    form.append('image', img);
    try {
      const data = await bolev.uploadImage(form);
      commit('mutate', {
        property: 'tempImage',
        with: data.message,
      });
    } catch (err) {
      commit('setError', err.message);
      setTimeout(() => {
        commit('setError', null);
      }, 5000);
    }
  },

  async login({ commit }, payload) {
    commit('authStarted');
    try {
      const { access_token } = await bolev.login(payload);
      commit('authSuccess', access_token);
    } catch (err) {
      commit('setError', 'Wrong mail or password');
      commit('authError', err);
    }
  },

  async getUserData({ commit }) {
    try {
      const user = await bolev.getUser();
      commit('mutate', {
        property: 'patientId',
        with: user.patientId,
      });
      commit('mutate', {
        property: 'user',
        with: user.user,
      });
      commit('mutate', {
        property: 'myProducts',
        with: user.myProducts,
      });
      commit('mutate', {
        property: 'phase',
        with: user.phase,
      });
      commit('mutate', {
        property: 'dietitians',
        with: user.dietitians,
      });
      commit('mutate', {
        property: 'clinics',
        with: user.clinics,
      });
      commit('mutate', {
        property: 'orders',
        with: user.orders.reverse(),
      });
      commit('mutate', {
        property: 'measurements',
        with: shared.filterByDate({ name: 'date', data: user.measurements }),
      });
      commit('mutate', {
        property: 'futureAppointments',
        with: shared.filterByDate({
          name: 'start',
          data: user.futureAppointments,
        }),
      });
      commit('mutate', {
        property: 'pastAppointments',
        with: shared.filterByDateReverse({
          name: 'start',
          data: user.pastAppointments,
        }),
      });
      commit('mutate', {
        property: 'deliveryAddress',
        with: user.deliveryAddress,
      });
    } catch (err) {
      commit('setError', err.message);
      if (err.message === 'unauthorized') {
        router.push({ name: 'login' });
      }
    }
  },

  async getPromotions({ commit }) {
    try {
      const promotions = await wordpress.getPromotions();
      commit('mutate', {
        property: 'promotions',
        with: promotions,
      });
    } catch (err) {
      commit('setError', err.message);
    }
  },

  async getPromotionsShop({ commit }) {
    try {
      const promotionsShop = await wordpress.getPromotionsShop();
      commit('mutate', {
        property: 'promotionsShop',
        with: promotionsShop,
      });
    } catch (err) {
      commit('setError', err.message);
    }
  },

  async getPosts({ commit }) {
    try {
      const posts = await wordpress.getPosts();
      commit('mutate', {
        property: 'posts',
        with: posts,
      });
    } catch (err) {
      commit('setError', err.message);
    }
  },

  async getShipping({ commit, state, getters }) {
    let zip;
    if (state.deliveryAddress && state.deliveryAddress.length > 0) {
      zip = state.deliveryAddress[0].zip;
    } else {
      zip = state.user.zip;
    }
    let total = getters.subTotal;
    try {
      const shipping = await bolev.getShipping({
        zip: zip,
        total: parseFloat(total),
        cart: state.cart,
      });
      commit('mutate', {
        property: 'shipping',
        with: shipping,
      });
    } catch (err) {
      commit('setError', err.message);
    }
  },

  async applyCoupon({ commit, state }, string) {
    try {
      const res = await bolev.applyCoupon({
        coupon: string,
        orderId: state.order.id,
      });
      if (!res.error) {
        commit('mutate', {
          property: 'order',
          with: res.order,
        });
      }
      commit('mutate', {
        property: 'couponError',
        with: res.error,
      });
    } catch (err) {
      commit('setError', err.message);
    }
  },

  async setLevPoints({ commit, state }) {
    try {
      const res = await bolev.applyLevPoints({
        levPoints: state.levPoints,
        orderId: state.order.id,
      });
      if (!res.error) {
        commit('mutate', {
          property: 'order',
          with: res,
        });
      }
    } catch (err) {
      commit('setError', err.message);
    }
  },

  couponReset({ commit }) {
    commit('mutate', {
      property: 'couponError',
      with: null,
    });
  },

  copyOrder({ commit, state }) {
    commit('mutate', {
      property: 'lastOrder',
      with: state.order,
    });
  },

  saveMbDetails({ commit }, payload) {
    commit('setMbDetails', payload);
  },

  passwordSent({ commit }) {
    commit('mutate', {
      property: 'passwordLinkActive',
      with: false,
    }),
      commit('mutate', {
        property: 'timer',
        with: 60,
      });
  },

  passwordLinkReset({ commit }) {
    commit('mutate', {
      property: 'passwordLinkActive',
      with: true,
    });
    commit('mutate', {
      property: 'timer',
      with: null,
    });
  },

  async getCategories({ commit }) {
    try {
      const { data } = await woocommerce.getCategories();
      commit('mutate', {
        property: 'categories',
        with: data,
      });
    } catch (err) {
      commit('setError', err.message);
    }
  },

  async getTags({ commit }) {
    try {
      const { data } = await woocommerce.getTags();
      data.map((t) => {
        t.active = false;
      });
      commit('mutate', {
        property: 'tags',
        with: data,
      });
    } catch (err) {
      commit('setError', err.message);
    }
  },

  async getOptions({ commit }) {
    try {
      const data = await wordpress.getOptions();
      commit('mutate', {
        property: 'options',
        with: data,
      });
    } catch (err) {
      commit('setError', err.message);
    }
  },

  setCategory({ commit }, payload) {
    commit('mutate', {
      property: 'currentCategory',
      with: payload,
    });
  },

  setSortBy({ commit }, payload) {
    commit('mutate', {
      property: 'sortBy',
      with: payload,
    });
  },

  async getPrices({ commit }) {
    try {
      const prices = await bolev.getPrices();
      commit('setPrices', prices);
    } catch (err) {
      commit('setError', err.message);
      if (err.message === 'unauthorized') {
        router.push({ name: 'login' });
      }
    }
  },

  async resetPassword({ commit }, payload) {
    try {
      await bolev.resetPassword(payload);
    } catch (err) {
      commit('setError', err.message);
    }
  },

  async requestResetPassword({ commit }, payload) {
    try {
      await bolev.requestResetPassword(payload);
    } catch (err) {
      commit('setError', 'No such mail registred');
    }
  },

  async deleteCard({ commit }, cardId) {
    try {
      const cards = await bolev.deleteCard(cardId);
      commit('mutate', {
        property: 'deletedCard',
        with: cardId,
      });
      setTimeout(() => {
        commit('mutate', {
          property: 'savedCards',
          with: cards,
        });
      }, 2000);
    } catch (err) {
      commit('setError', err.message);
    }
  },

  async makeOrder({ commit }, payload) {
    try {
      const order = await bolev.makeOrder(payload);
      commit('mutate', {
        property: 'order',
        with: order,
      });
    } catch (err) {
      commit('setError', err.message);
    }
  },

  async changeOrder({ commit, dispatch, state }, payload) {
    try {
      const order = await bolev.updateOrder(payload);
      commit('mutate', {
        property: 'order',
        with: order,
      });
    } catch (err) {
      console.log('change order error, testing creating new one', err);
      await dispatch('makeOrder', {
        rows: payload.rows,
        patientId: state.patientId,
        source: 'App',
      });
    }
  },

  setCounterActive({ commit }) {
    commit('mutate', {
      property: 'counterActive',
      with: true,
    });
  },

  disableCounter({ commit }) {
    commit('mutate', {
      property: 'counterActive',
      with: false,
    });
  },

  logout({ commit }) {
    commit('logout');
  },
};

const plugins = [
  createPersistedState({
    paths: [
      'token',
      // 'products',
      'prices',
      'cart',
      'activeCard',
      'order',
      'shipping',
      'lastOrder',
      'deliveryAddress',
      'orders',
      'firstTimeUser',
      'categories',
    ],
  }),
];

export default createStore({
  state,
  getters,
  actions,
  mutations,
  plugins,
});
