<template>
  <div class="wrapper">
    <div
      ref="carousel"
      :class="{ carouselActive: showCarousel }"
      class="carousel"
      @touchstart.stop
      @touchmove.stop
      @touchend.stop
    >
      <AppointmentCard
        v-for="appointment in futureAppointments"
        :key="appointment.id"
        :appointment="appointment"
      />
      <AppointmentCard
        v-if="futureAppointments && futureAppointments.length === 0"
      />
    </div>

    <div v-if="showCarousel" class="dots flex">
      <div
        class="dot"
        v-for="(appointment, i) in futureAppointments"
        :key="appointment.id"
        :class="{ active: indexMatch(i) }"
      ></div>
    </div>
  </div>
</template>

<script>
import AppointmentCard from '@/components/AppointmentCard.vue';
import shared from '@/shared';

export default {
  props: {
    futureAppointments: {
      type: Array,
      default: null,
    },
  },
  components: {
    AppointmentCard,
  },
  data() {
    return {
      touch: {
        startX: 0,
        endX: 0,
        startY: 0,
        endY: 0,
        long: false,
      },
      index: 0,
      showCarousel: false,
    };
  },
  computed: {
    position() {
      return window.innerWidth * this.index;
    },
  },
  created() {
    this.addSwipeListeners = shared.addSwipeListeners;
    this.removeSwipeListeners = shared.removeSwipeListeners;
  },
  mounted() {
    if (this.futureAppointments) {
      if (this.futureAppointments.length > 1) {
        this.showCarousel = true;
        this.addSwipeListeners('carousel');
      }
    }
  },

  beforeUnmount() {
    this.removeSwipeListeners('carousel');
  },
  methods: {
    indexMatch(i) {
      if (i === this.index) {
        return true;
      }
      return false;
    },
    nextCard() {
      if (this.index < this.futureAppointments.length - 1) {
        this.index++;
      }
    },
    prevCard() {
      if (this.index > 0) {
        this.index--;
      }
    },
    scroll() {
      this.$refs.carousel.scrollTo(this.position, 0);
    },
    touchstart(e) {
      this.timeout = setTimeout(() => {
        this.touch.long = true;
      }, 300);
      this.touch.startX = e.touches[0].clientX;
      this.touch.endX = e.touches[0].clientX;
      this.touch.startY = e.touches[0].clientY;
      this.touch.endY = e.touches[0].clientY;
    },
    touchmove(e) {
      this.touch.endX = e.touches[0].clientX;
      this.touch.endY = e.touches[0].clientY;
    },
    touchend() {
      let reqMov = 20;
      if (this.touch.long) {
        reqMov = window.innerWidth / 2 - 40;
      }

      this.touch.long = false;
      clearTimeout(this.timeout);

      //Swipe too small
      if (Math.abs(this.touch.endX - this.touch.startX) < reqMov) {
        this.scroll();
        return;
      }

      if (this.touch.endX > this.touch.startX) {
        this.prevCard();
      } else if (this.touch.endX < this.touch.startX) {
        this.nextCard();
      }

      this.scroll();
    },
  },
};
</script>

<style lang="scss" scoped>
.wrapper {
  margin-bottom: 20px;
  width: 100%;
}
.appointments {
  width: 100vw;
  align-items: flex-start;
  position: fixed;
  height: 90vh;
  bottom: 0;
  z-index: 5;
  background-color: $white;
  overflow-y: scroll;
  .carousel {
    scroll-behavior: smooth;
    display: flex;
    min-height: 180px;
    width: 100%;
    padding: 10px 20px;
    overflow-x: auto;
    gap: 40px;
    .app-card {
      margin-right: 20px;
      &:last-of-type {
        margin-right: 0;
      }
    }
    .appointment-wrapper {
      ::v-deep(.appointment) {
        width: calc(100vw - 40px);
      }
    }
  }
  .carouselActive::after {
    content: '';
    flex: 0 0 1px;
  }
  .dots {
    width: 100%;
    .dot {
      background-color: $grey;
      margin: 0 3px;
      border-radius: 100%;
      height: 6px;
      width: 6px;
    }
    .active {
      background-color: $orange;
    }
  }
}
</style>
