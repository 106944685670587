<template>
  <div
    v-click-outside="onClickOutside"
    :style="cardMove"
    class="contact"
    ref="contact"
  >
    <div class="wrapper flex-column">
      <img
        src="@/assets/close.svg"
        @click="toggle('contact')"
        class="close"
        alt="close"
      />

      <h2 class="header-label">{{ $t('contact.contact') }}</h2>

      <!-- //TODO Img to replaced with dynamic one from dietitian object -->
      <div
        v-if="dietitian.image === '/images/placeholder.svg'"
        class="img__placeholder flex"
      >
        <h2>{{ initials }}</h2>
      </div>
      <div v-else class="dietitian_image">
        <img :src="`${url}/${dietitian.image}`" alt="dietitian" />
      </div>
      <p class="body-regular">{{ $t('contact.info') }}</p>

      <!-- //TODO Phone to replaced with dynamic one from dietitian object -->
      <PrimaryButton
        :link="'https://api.whatsapp.com/send?phone=' + dietitian.phone"
        :title="'WhatsApp'"
        :leftIcon="'whatsapp.svg'"
      />
      <div class="flex">
        <hr />
        <h6>or</h6>
        <hr />
      </div>

      <div class="flex">
        <SecondaryButton
          :link="'mailto:' + dietitian.email"
          :title="'E-mail'"
          :width="120"
          :leftIcon="'mail.svg'"
        />
        <SecondaryButton
          :link="'sms://' + dietitian.phone"
          :title="'SMS'"
          :width="120"
          :leftIcon="'sms.svg'"
        />
      </div>
    </div>
  </div>
</template>

<script>
import shared from '@/shared';
import { mapActions, mapState, mapGetters } from 'vuex';
import PrimaryButton from '@/components/ui/PrimaryButton';
import SecondaryButton from '@/components/ui/SecondaryButton';
import clickOutside from '@/directives/ClickOutside';

export default {
  components: { PrimaryButton, SecondaryButton },
  data() {
    return {
      movement: 0,
      touch: {
        startX: 0,
        endX: 0,
      },
      url: process.env.VUE_APP_BOLEV_URL,
    };
  },
  computed: {
    ...mapState(['dietitians', 'phase']),
    ...mapGetters(['getDietitian']),

    dietitian() {
      return this.getDietitian(this.phase.dietitian_id);
    },
    cardMove() {
      return { transform: 'translateX(' + this.movement + 'px)' };
    },
    initials() {
      if (!this.dietitian) return 'XX';
      return this.dietitian.name
        .split(' ')
        .map((c) => c.toUpperCase().substring(0, 1))
        .join('');
    },
  },
  created() {
    this.addSwipeListeners = shared.addSwipeListeners;
    this.removeSwipeListeners = shared.removeSwipeListeners;
  },
  mounted() {
    document.body.className = 'no-scroll';
    this.addSwipeListeners('contact');
  },
  beforeUnmount() {
    document.body.className = '';
    this.removeSwipeListeners('contact');
  },
  directives: {
    clickOutside: clickOutside,
  },
  methods: {
    ...mapActions(['toggle', 'animationStartPos']),
    onClickOutside() {
      this.toggle('contact');
    },
    touchstart(e) {
      this.touch.startX = e.touches[0].clientX;
      this.touch.endX = e.touches[0].clientX;
      this.touch.startY = e.touches[0].clientY;
      this.touch.endY = e.touches[0].clientY;
    },
    touchmove(e) {
      e.preventDefault();
      this.touch.endX = e.touches[0].clientX;
      this.touch.endY = e.touches[0].clientY;

      if (this.touch.endX > this.touch.startX) {
        this.movement = Math.abs(this.touch.startX - this.touch.endX);
      }
    },
    touchend() {
      if (
        Math.abs(this.touch.endY - this.touch.startY) >
        Math.abs(this.touch.endX - this.touch.startX)
      ) {
        this.movement = 0;
        this.animationStartPos(this.movement);
        return;
      }
      if (Math.abs(this.touch.endX - this.touch.startX) < 40) {
        this.movement = 0;
        this.animationStartPos(this.movement);
        return;
      }
      if (this.touch.endX > this.touch.startX) {
        this.animationStartPos(this.movement);
        this.toggle('contact');
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.contact {
  z-index: 999;
  position: fixed;
  width: 340px;
  left: calc(50% - 170px);
  top: calc(50% - 275px);
  background-color: $background-color;
  filter: $card-shadow;
  border-radius: $rounded;
  .wrapper {
    position: relative;
    height: 100%;
    width: 100%;
    padding: 0 40px 25px 40px;
    .dietitian_image {
      border-radius: 50%;
      height: 116px;
      width: 116px;
      overflow: hidden;
      img {
        max-width: 100%;
      }
    }
    .close {
      position: absolute;
      top: 5px;
      right: 5px;
      cursor: pointer;
    }
    hr {
      width: 115px;
      background-color: $black;
      color: $black;
      height: 1px;
      border: none;
    }
    h6 {
      margin: 0 7px;
      font-weight: 700;
      font-size: 14px;
      letter-spacing: 0.25px;
    }
    a {
      margin: 10px;
    }
    p {
      margin: 20px 0;
      &::first-letter {
        text-transform: uppercase;
      }
    }
    // CHANGE TO HEIGHT 116px and margin 20px when image is not croped
    .dietitian {
      height: 116px;
      border-radius: 50%;
      margin: 12px 0;
    }
    .img__placeholder {
      height: 116px;
      width: 116px;
      border-radius: 50%;
      align-items: center;
      background-color: $grey-transparent;
      h2 {
        color: $orange;
        font-size: 2rem;
      }
    }
  }
}
@media only screen and (max-width: 340px) {
  .contact {
    max-width: 300px;
    left: calc(50% - 150px);
  }
}
</style>
