<template>
  <swiper
    v-if="images.length > 0"
    :loop="true"
    :slides-per-view="1"
    :space-between="0"
    :pagination="{ clickable: true }"
  >
    <swiper-slide v-for="image in images" :key="image.id">
      <div class="img-wrapper">
        <img :src="image.src" :alt="image.name" />
      </div>
    </swiper-slide>
  </swiper>
</template>

<script>
import { Swiper, SwiperSlide } from 'swiper/vue';
import SwiperCore, { Pagination } from 'swiper/core';
import 'swiper/swiper.scss';

SwiperCore.use([Pagination]);

export default {
  components: {
    Swiper,
    SwiperSlide,
  },
  props: {
    images: {
      type: Array,
      default: null,
    },
  },
};
</script>

<style lang="scss" scoped>
.img-wrapper {
  height: 40vh;
  width: 100%;
  padding: 20px;
  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}
</style>

<style>
.swiper-wrapper {
  max-width: 100%;
}
</style>
