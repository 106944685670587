<template>
  <div @click.stop class="line-cards flex">
    <LineCardSmall
      :class="{ show_on_top: onboarding === 2 }"
      @click="setValuesOpenChart(weight, strings.weight, 'kg')"
      :bodyData="weightFiltered"
      :unit="'kg'"
      :title="strings.weight"
    />
    <LineCardSmall
      @click="setValuesOpenChart(bodyFat, strings.bodyFat, '%')"
      :bodyData="bodyFatFiltered"
      :unit="'%'"
      :title="strings.bodyFat"
    />
    <LineCardSmall
      @click="setValuesOpenChart(bmi, strings.bmi, '')"
      :bodyData="bmiFiltered"
      :unit="''"
      :title="strings.bmi"
    />
    <LineCardSmall
      @click="setValuesOpenChart(hip, strings.hip, 'cm')"
      :bodyData="hipFiltered"
      :unit="'cm'"
      :title="strings.hip"
    />
    <LineCardSmall
      @click="setValuesOpenChart(thigh, strings.thigh, 'cm')"
      :bodyData="thighFiltered"
      :unit="'cm'"
      :title="strings.thigh"
    />
    <LineCardSmall
      @click="setValuesOpenChart(waist, strings.waist, 'cm')"
      :bodyData="waistFiltered"
      :unit="'cm'"
      :title="strings.waist"
    />
  </div>
</template>

<script>
import LineCardSmall from '@/components/LineCardSmall.vue';
import { mapState, mapActions } from 'vuex';

export default {
  components: {
    LineCardSmall,
  },
  data() {
    return {
      strings: {
        weight: this.$t('progress.weight'),
        hip: this.$t('progress.hip'),
        bmi: this.$t('progress.bmi'),
        bodyFat: this.$t('progress.body_fat'),
        waist: this.$t('progress.waist'),
        thigh: this.$t('progress.thigh'),
      },
    };
  },
  methods: {
    ...mapActions(['openChart']),
    setValuesOpenChart(type, title, unit) {
      this.openChart({ data: type, title: title, unit: unit });
    },
  },
  computed: {
    ...mapState(['onboarding', 'measurements']),
    weight() {
      return this.measurements
        .filter((m) => m.current_weight !== null)
        .map((m) => ({
          value: m.current_weight,
          date: m.date,
        }));
    },
    weightFiltered() {
      return this.weight.map((item) => item.value);
    },
    thigh() {
      return this.measurements
        .filter((m) => m.thigh !== null)
        .map((m) => ({
          value: m.thigh,
          date: m.date,
        }));
    },
    thighFiltered() {
      return this.thigh.map((item) => item.value);
    },
    bodyFat() {
      return this.measurements
        .filter((m) => m.body_fat !== null)
        .map((m) => ({
          value: m.body_fat,
          date: m.date,
        }));
    },
    bodyFatFiltered() {
      return this.bodyFat.map((item) => item.value);
    },
    bmi() {
      return this.measurements
        .filter((m) => m.bmi !== null)
        .map((m) => ({
          value: m.bmi,
          date: m.date,
        }));
    },
    bmiFiltered() {
      return this.bmi.map((item) => item.value);
    },
    hip() {
      return this.measurements
        .filter((m) => m.hip !== null)
        .map((m) => ({
          value: m.hip,
          date: m.date,
        }));
    },
    hipFiltered() {
      return this.hip.map((item) => item.value);
    },
    waist() {
      return this.measurements
        .filter((m) => m.waist !== null)
        .map((m) => ({
          value: m.waist,
          date: m.date,
        }));
    },
    waistFiltered() {
      return this.waist.map((item) => item.value);
    },
  },
};
</script>

<style lang="scss" scoped>
.line-cards {
  width: 100%;
  flex-wrap: wrap;
  max-width: 600px;
}
</style>
