<template>
  <div>{{ error }}</div>
</template>

<script>
export default {
  name: 'error',

  props: ['error'],
};
</script>
