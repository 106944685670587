<template>
  <div class="delivery">
    <h4 class="small-label">{{ title }}</h4>
    <div v-if="!edit" class="preview">
      <template v-if="!this.deliveryAddress || this.deliveryAddress.length < 1">
        <h6>{{ user.name }}</h6>
        <h6>{{ user.address }}</h6>
        <h6>{{ user.zip }}</h6>
        <h6>{{ user.city }}</h6>
      </template>
      <template v-else>
        <h6>{{ firstNameValue }} {{ lastNameValue }}</h6>
        <h6>{{ addressValue }}</h6>
        <h6>{{ zipValue }}</h6>
        <h6>{{ cityValue }}</h6>
      </template>
      <img src="@/assets/edit.svg" @click="toggle(true)" alt="" />
    </div>
    <div v-touch-outside="showBottomNav" v-else class="edit">
      <h2 class="header-label">{{ editText }}</h2>
      <TextInput
        @click="hideBottomNav"
        :min="2"
        @validated="setFirstNameValidated"
        v-model="name.first"
        :value="firstNameValue"
        :placeholder="firstName"
        :errorIcon="false"
        :label="firstName"
      />
      <TextInput
        @click="hideBottomNav"
        :min="2"
        @validated="setLastNameValidated"
        v-model="name.last"
        :value="lastNameValue"
        :placeholder="lastName"
        :errorIcon="false"
        :label="lastName"
      />
      <TextInput
        @click="hideBottomNav"
        :min="2"
        @validated="setStreetValidated"
        v-model="address.street"
        :value="addressValue"
        :placeholder="addressString"
        :errorIcon="false"
        :label="addressString"
      />
      <TextInput
        @click="hideBottomNav"
        :min="2"
        @validated="setZipValidated"
        v-model="address.zip"
        :value="zipValue"
        :placeholder="zip"
        :errorIcon="false"
        :label="zip"
      />
      <TextInput
        @click="hideBottomNav"
        :min="2"
        @validated="setCityValidated"
        v-model="address.city"
        :value="cityValue"
        :placeholder="city"
        :errorIcon="false"
        :label="city"
      />
      <TextInput
        type="tel"
        v-if="billing"
        @click="hideBottomNav"
        :min="2"
        v-model="phone"
        :value="user.phone"
        :placeholder="$t('delivery.phone')"
        :errorIcon="false"
        :label="$t('delivery.phone')"
      />
      <p v-if="addressError">{{ addressError }}</p>
      <img src="@/assets/close.svg" @click="toggle(false)" alt="" />
      <PrimaryButton
        :saving="saving"
        @click="changeAddress"
        :disabled="!validated"
        :title="saveContactDetails"
      />
    </div>
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex';
import PrimaryButton from '@/components/ui/PrimaryButton.vue';
import TextInput from 'afonso-forms/src/components/TextInput';
import bolev from '../apis/bolev';
import touchOutside from '@/directives/TouchOutside';

export default {
  props: {
    isOrder: {
      type: Boolean,
      default: false,
    },
    billing: {
      type: Boolean,
      default: false,
    },
    sameDelivery: {
      type: Boolean,
      default: false,
    },
  },
  directives: {
    touchOutside: touchOutside,
  },
  data() {
    return {
      saving: false,
      edit: false,
      name: {
        first: '',
        last: '',
      },
      address: {
        street: '',
        zip: '',
        city: '',
      },
      phone: '',
      streetValidated: true,
      zipValidated: true,
      cityValidated: true,
      firstNameValidated: true,
      lastNameValidated: true,
    };
  },
  components: {
    PrimaryButton,
    TextInput,
  },
  async mounted() {
    this.setAddressError(null);
    if (!this.deliveryAddress || this.deliveryAddress.length < 1) {
      try {
        const saved = await bolev.setDelivery({
          first_name: this.user.first_name,
          last_name: this.user.last_name,
          address: this.user.address,
          zip: this.user.zip,
          city: this.user.city,
        });
        await this.saveDelivery(saved);
      } catch (error) {
        this.setAddressError(error);
      }
    }
    this.setValues();
  },
  computed: {
    ...mapState([
      'user',
      'addressError',
      'deliveryAddress',
      'order',
      'patientId',
    ]),
    validated() {
      if (
        this.streetValidated &&
        this.firstNameValidated &&
        this.lastNameValidated &&
        this.zipValidated &&
        this.cityValidated &&
        this.address.zip &&
        this.address.city &&
        this.address.street &&
        this.name.first &&
        this.name.last
      )
        return true;
      else return false;
    },
    firstNameValue() {
      return this.deliveryAddress?.length && !this.billing
        ? this.deliveryAddress[0].first_name
        : this.user.first_name;
    },
    lastNameValue() {
      return this.deliveryAddress?.length && !this.billing
        ? this.deliveryAddress[0].last_name
        : this.user.last_name;
    },
    addressValue() {
      return this.deliveryAddress?.length && !this.billing
        ? this.deliveryAddress[0].address
        : this.user.address;
    },
    cityValue() {
      return this.deliveryAddress?.length && !this.billing
        ? this.deliveryAddress[0].city
        : this.user.city;
    },
    zipValue() {
      return this.deliveryAddress?.length && !this.billing
        ? this.deliveryAddress[0].zip
        : this.user.zip;
    },

    firstName() {
      return this.$t('delivery.first_name');
    },
    lastName() {
      return this.$t('delivery.last_name');
    },
    addressString() {
      return this.$t('delivery.address');
    },
    zip() {
      return this.$t('delivery.zip');
    },
    city() {
      return this.$t('delivery.city');
    },
    saveContactDetails() {
      return this.$t('delivery.save_delivery_details');
    },
    title() {
      return this.billing
        ? this.$t('delivery.address_details')
        : this.$t('delivery.delivery_details');
    },
    editText() {
      return this.billing
        ? this.$t('delivery.edit_address_details')
        : this.$t('delivery.edit_delivery_details');
    },
  },
  methods: {
    ...mapMutations(['setAddressError', 'mutate']),
    ...mapActions(['saveDelivery', 'changeOrder']),
    toggle(value) {
      this.edit = value;
      this.showBottomNav();
    },
    setValues() {
      this.name.first = this.firstNameValue;
      this.name.last = this.lastNameValue;
      this.address.street = this.addressValue;
      this.address.zip = this.zipValue;
      this.address.city = this.cityValue;
      this.phone = this.user.phone;
    },
    setStreetValidated(boo) {
      this.streetValidated = boo;
    },
    setZipValidated(boo) {
      this.zipValidated = boo;
    },
    setCityValidated(boo) {
      this.cityValidated = boo;
    },
    setFirstNameValidated(boo) {
      this.firstNameValidated = boo;
    },
    setLastNameValidated(boo) {
      this.lastNameValidated = boo;
    },
    hideBottomNav() {
      this.mutate({
        property: 'navBottomActive',
        with: false,
      });
    },
    showBottomNav() {
      this.mutate({
        property: 'navBottomActive',
        with: true,
      });
    },

    changeAddress() {
      if (this.billing) {
        this.changeBillingAddress();
      } else {
        this.changeDeliveryAddress();
      }
    },

    async changeBillingAddress() {
      if (!this.validated) {
        this.setAddressError(
          'Please make sure you have filled in all the fields.'
        );
      } else {
        try {
          this.working = true;
          const data = await bolev.changeAddress({
            orderId: this.sameDelivery ? this.order.id : null,
            patientId: this.patientId,
            address: this.address.street,
            zip: this.address.zip,
            city: this.address.city,
            phone: this.phone,
          });
          if (data.order) {
            this.mutate({
              property: 'order',
              with: data.order,
            });
          }
          if (data.patient) {
            this.mutate({
              property: 'user',
              with: data.patient,
            });
          }
          this.toggle(false);
          this.saving = false;
        } catch (error) {
          this.setAddressError(error);
          this.saving = false;
        }
      }
    },

    async changeDeliveryAddress() {
      if (!this.validated) {
        this.setAddressError(
          'Please make sure you have filled in all the fields.'
        );
      } else if (!this.deliveryAddress || this.deliveryAddress.length < 1) {
        try {
          this.saving = true;
          const saved = await bolev.setDelivery({
            first_name: this.name.first,
            last_name: this.name.last,
            address: this.address.street,
            zip: this.address.zip,
            city: this.address.city,
          });
          await this.saveDelivery(saved);
          this.toggle(false);
          this.saving = false;
        } catch (error) {
          this.setAddressError(error);
          this.saving = false;
        }
      } else {
        try {
          this.saving = true;
          const saved = await bolev.changeDelivery({
            address_id: this.deliveryAddress[0].id,
            first_name: this.name.first,
            last_name: this.name.last,
            address: this.address.street,
            zip: this.address.zip,
            city: this.address.city,
          });
          await this.saveDelivery(saved);
          this.toggle(false);
          this.saving = false;
        } catch (error) {
          this.setAddressError(error);
          this.saving = false;
        }
      }
      if (this.isOrder) {
        this.changeOrder({
          address_id: this.deliveryAddress[0].id,
          orderId: this.order.id,
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.delivery {
  div {
    text-transform: capitalize;
  }
  padding: 0 20px;
  .preview,
  .edit {
    padding: 20px;
    position: relative;
    width: 100%;
    box-shadow: $box-shadow;
    border-radius: $rounded;
    img {
      position: absolute;
      height: 50px;
      top: 0;
      right: 0;
      cursor: pointer;
    }
  }
  .preview {
    h6 {
      text-align: start;
      font-weight: 100;
      font-size: 14px;
      margin: 0;
      text-transform: capitalize;
      letter-spacing: 1px;
    }
  }
  .edit {
    .af-field__wrapper {
      width: 100%;
    }
    h2 {
      margin: 0;
      text-transform: lowercase;
      letter-spacing: 0.5px;
      text-align: start;
      &::first-letter {
        text-transform: uppercase;
      }
    }
    a {
      margin-top: 20px;
    }
  }
  h4 {
    text-align: start;
    margin-bottom: 20px;
  }
}
</style>
