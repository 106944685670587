<template>
  <div
    class="nav-bottom"
    :class="{ scroll_down: searchActive || counterActive }"
  >
    <div
      @click="this.$router.push({ path: '/' })"
      :class="{ active: $route.path === '/' }"
    >
      <img src="@/assets/home.svg" alt="mylev" />
      <h5 class="icon-label">
        {{ $t('navBottom.my_lev') }}
      </h5>
    </div>
    <div
      v-if="phase && phase.phase"
      @click="$router.push({ path: '/foodplan' })"
      :class="{ active: $route.name === 'foodplan' }"
    >
      <img src="@/assets/foodplan.svg" alt="appointments" />
      <h5 class="icon-label">
        {{ $t('navBottom.food_plan') }}
      </h5>
    </div>
    <div
      @click="$router.push({ path: '/progress' })"
      :class="{ active: $route.name === 'progress' }"
    >
      <img src="@/assets/progress.svg" alt="appointments" />
      <h5 class="icon-label">
        {{ $t('navBottom.progress') }}
      </h5>
    </div>
    <div
      @click="$router.push({ path: '/profile' })"
      :class="{ active: $route.name === 'profile' }"
    >
      <img src="@/assets/profile.svg" alt="appointments" />
      <h5 class="icon-label">
        {{ $t('navBottom.profile') }}
      </h5>
    </div>
    <div
      @click="$router.push({ name: 'shop' })"
      :class="{ active: $route.name === 'shop' || $route.name === 'product' }"
    >
      <img src="@/assets/shopping-bag.svg" alt="shop" />
      <h5 class="icon-label">
        {{ $t('navBottom.shop') }}
      </h5>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  computed: {
    ...mapState(['searchActive', 'counterActive', 'categories', 'phase']),
  },
};
</script>

<style lang="scss" scoped>
.scroll_down {
  bottom: -120px !important;
}
.nav-bottom {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  position: fixed;
  width: 100%;
  max-width: 100vw;
  height: calc(#{$nav-bottom-height} + env(safe-area-inset-bottom, 0px));
  background-color: $black;
  box-shadow: $shadow;
  border-radius: $rounded-top;
  transition: ease-out 0.3s all;
  padding-bottom: env(safe-area-inset-bottom, 0px);
  bottom: 0;
  z-index: 3;
  max-width: $max-width;

  .icon-label {
    padding-top: 6px;
    font-size: 9px;
  }
  .active {
    img {
      filter: invert(69%) sepia(40%) saturate(522%) hue-rotate(5deg)
        brightness(101%) contrast(90%);
    }
    h5 {
      color: $dark-yellow;
      opacity: 1;
    }
  }
  div {
    cursor: pointer;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 20%;

    img {
      height: 18px;
      z-index: 999;
      filter: brightness(1) invert(1);
    }
    h5 {
      opacity: 0.7;
      z-index: 999;
      text-transform: capitalize;
      font-weight: 600;
      color: #fff;
    }
  }
}
</style>
