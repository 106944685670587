<template>
  <div
    v-click-outside="closeChart"
    :style="cardMove"
    ref="linecard"
    class="line-card"
  >
    <hr />

    <h2 class="bold-label">{{ chartData.title }} progress</h2>

    <h1>
      {{ shorten(lastValue) }}
      <span>{{ chartData.unit }}</span>
    </h1>

    <h3 class="small-label">
      {{ positive
      }}{{
        $t('progress.since_start', {
          number: numberFormat(difference),
          unit: chartData.unit,
        })
      }}
    </h3>

    <div class="flex">
      <ul class="flex-column">
        <li v-for="label in yLabels.slice().reverse()" :key="label">
          <h5>{{ label }}</h5>
        </li>
      </ul>

      <div ref="scrollcontainer">
        <div class="graph-wrapper" :style="graphWidth">
          <LineChart :chartData="data" :options="options" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import shared from '@/shared';
import LineChart from '@/components/LineChart.vue';
import { mapActions, mapState } from 'vuex';
import clickOutside from '@/directives/ClickOutside';
import moment from 'moment';

export default {
  created() {
    this.addSwipeListeners = shared.addSwipeListeners;
    this.removeSwipeListeners = shared.removeSwipeListeners;
    this.numberFormat = shared.numberFormat;
    this.shorten = shared.shorten;

    // Adds the values to chart.js object
    this.data.datasets[0].data = this.values;
    let addLabels;
    if (this.chartData.data.length > 6) {
      addLabels = 1;
    } else {
      //change
      addLabels = 7 - this.chartData.data.length;
    }
    this.data.labels = Array.from(
      { length: this.chartData.data.length + addLabels },
      (_, i) => i + 1
    );
    this.min =
      Math.round(Math.min.apply(Math, this.values) / 10) * 10 -
      this.stepSize * 3;
    if (this.min < 0) {
      this.min = 0;
    }
    this.max =
      Math.round(Math.max.apply(Math, this.values) / 10) * 10 + this.stepSize;

    this.options.scales.yAxes[0].ticks.stepSize = this.stepSize;
    this.options.scales.yAxes[0].ticks.min = this.min;
    this.options.scales.yAxes[0].ticks.max = this.max;
  },
  data() {
    const vm = this;
    return {
      stepSize: 10,
      movement: 0,
      touch: {
        startY: 0,
        endY: 0,
        startX: 0,
        endX: 0,
      },
      data: {
        labels: [],
        datasets: [
          {
            data: [],
            label: false,
            borderColor: '#dfb859',
            borderWidth: 4,
            fill: false,
            lineTension: 0,
          },
        ],
      },
      options: {
        tooltips: {
          yPadding: 10,
          xPadding: 10,
          titleAlign: 'center',
          displayColors: false,
          caretPadding: 40,
          backgroundColor: '#dfb859',
          callbacks: {
            title: function (tooltipItem, data) {
              let title = data.datasets[0].data[tooltipItem[0].index];
              return `${title} ${vm.chartData.unit}`;
            },

            label: function (tooltipItem) {
              return vm.formatDate(vm.chartData.data[tooltipItem.index].date);
            },
          },
        },
        layout: {
          padding: {
            right: 20,
            left: 70,
          },
        },
        elements: {
          point: {
            radius: 2,
            hitRadius: 30,
            hoverRadius: 10,
            hoverBackgroundColor: 'rgba(255,255,255,1)',
            hoverBorderWidth: 3,
            hoverBorderColor: '#dfb859',
          },
        },
        scales: {
          xAxes: [
            {
              gridLines: {
                drawBorder: false,
                color: '#d3d5d6',
              },
              ticks: {
                fontFamily: "'Montserrat', sans-serif",
                padding: 10,
                fontSize: 18,
                fontColor: '#919497',
              },
            },
          ],
          yAxes: [
            {
              display: false,
              ticks: {
                stepSize: 50,
                min: 0,
                max: 150,
              },
            },
          ],
        },
        legend: {
          display: false,
        },
        responsive: true,

        title: {
          display: false,
        },
      },
    };
  },
  computed: {
    ...mapState(['chartData']),
    cardMove() {
      return { transform: 'translateY(' + this.movement + 'px)' };
    },

    values() {
      let data = this.valueArray.map((item) => {
        return parseFloat(item.value);
      });
      return data;
    },

    valueArray() {
      return this.chartData.data;
    },

    difference() {
      return this.values[this.values.length - 1] - this.values[0];
    },
    positive() {
      if (this.difference > 0) return `+`;
      else return '';
    },
    lastValue() {
      return this.chartData.data[this.chartData.data.length - 1].value;
    },
    yLabels() {
      return Array.from(
        { length: (this.max - this.min) / this.stepSize + 1 },
        (_, i) => i * this.stepSize + this.min
      );
    },
    graphWidth() {
      return { width: `${this.data.labels.length * 15}vw` };
    },
  },
  components: {
    LineChart,
  },
  methods: {
    ...mapActions(['toggle', 'animationStartPos']),
    closeChart() {
      this.toggle('chart');
    },
    touchstart(e) {
      this.touch.startX = e.touches[0].clientX;
      this.touch.endX = e.touches[0].clientX;
      this.touch.startY = e.touches[0].clientY;
      this.touch.endY = e.touches[0].clientY;
    },
    touchmove(e) {
      this.touch.endX = e.touches[0].clientX;
      this.touch.endY = e.touches[0].clientY;
      if (
        this.touch.endY - this.touch.startY >
        Math.abs(this.touch.endX - this.touch.startX)
      ) {
        if (e.cancelable) {
          e.preventDefault();
        }

        this.movement = Math.abs(this.touch.startY - this.touch.endY);
      }
    },
    touchend() {
      if (
        Math.abs(this.touch.endY - this.touch.startY) <
        Math.abs(this.touch.endX - this.touch.startX)
      ) {
        this.movement = 0;
        this.animationStartPos(this.movement);
        return;
      }
      if (Math.abs(this.touch.endY - this.touch.startY) < 40) {
        this.movement = 0;
        return;
      }
      if (this.touch.endY > this.touch.startY) {
        this.animationStartPos(this.movement);
        this.toggle('chart');
      }
    },
    formatDate(date) {
      return moment(date)
        .locale(process.env.VUE_APP_LANG || 'en')
        .format('DD MMM');
    },
  },

  mounted() {
    document.body.className = 'no-scroll';
    this.addSwipeListeners('linecard');

    // Moves graph to the right
    if (this.chartData.data.length > 6) {
      let container = this.$refs.scrollcontainer;
      container.scrollLeft = container.scrollWidth - container.clientWidth;
    }
  },
  beforeUnmount() {
    document.body.className = '';
    this.removeSwipeListeners('linecard');
  },

  directives: {
    clickOutside: clickOutside,
  },
};
</script>

<style lang="scss" scoped>
.line-card {
  max-height: 95vh;
  height: 618px;
  width: 100%;
  z-index: 5;
  position: fixed;
  bottom: 0;
  background-color: $background-color;
  padding: 20px;
  hr {
    margin-top: 0;
    margin-bottom: 0;
    width: 30px;
    height: 4px;
    border: none;
    border-radius: 5px;
    background-color: $black;
  }
  h1 {
    font-size: 48px;
    margin: 0;
    span {
      font-size: 22px;
      text-transform: lowercase;
    }
  }
  h2 {
    letter-spacing: 1.5px;
    margin: 25px 0 15px 0;
  }
  h3 {
    margin: 15px 0;
    color: #639a6b;
  }
  h4 {
    text-align: start;
    margin: 5px 0 0 10px;
  }
  .flex {
    @media only screen and (max-height: 600px) {
      ul,
      div {
        max-height: 50vh;
      }
    }
    ul {
      //   margin-top: -2px;
      position: absolute;
      left: 0;
      z-index: 1;
      background: -webkit-linear-gradient(
        left,
        rgb(253, 251, 249) 75%,
        rgba(253, 251, 249, 0.95) 77%,
        rgba(253, 251, 249, 0.9) 79%,
        rgba(253, 251, 249, 0.85) 81%,
        rgba(253, 251, 249, 0.8) 83%,
        rgba(253, 251, 249, 0.75) 85%,
        rgba(253, 251, 249, 0.7) 87%,
        rgba(253, 251, 249, 0.6) 89%,
        rgba(253, 251, 249, 0.5) 91%,
        rgba(253, 251, 249, 0.4) 93%,
        rgba(253, 251, 249, 0.3) 95%,
        rgba(253, 251, 249, 0.2) 97%,
        rgba(253, 251, 249, 0.1) 98%,
        rgba(253, 251, 249, 0.05) 99%,
        rgba(253, 251, 249, 0.02) 100%
      );

      width: 70px;
      height: 378px;
      justify-content: space-between;
      align-items: flex-start;
      padding-bottom: 45px;
      li {
        margin-left: 25px;
        h5 {
          color: #919497;
          margin: 0;
          font-size: 10px;
          letter-spacing: 1px;
        }
      }
    }
    div {
      height: 378px;
      overflow-x: auto;
      .graph-wrapper {
        position: relative;
        min-width: 100vw;
      }
    }
  }
}
@media only screen and (max-height: 600px) {
  .line-card {
    height: 550px;
  }
}
</style>
