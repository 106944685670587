import { createWebHistory, createRouter } from 'vue-router';
import store from '../store';
import Mylev from '../pages/MyLev';
import Shop from '../pages/Shop';
import Product from '../pages/Product';
import Checkout from '../pages/Checkout';
import Login from '../pages/Login';
import Password from '../pages/Password';
import Receipt from '../pages/Receipt';
import Order from '../pages/Order';
import Profile from '../pages/Profile';
import Progress from '@/pages/Progress.vue';
import FoodPlan from '@/pages/FoodPlan.vue';

const routes = [
  {
    path: '/',
    name: 'mylev',
    component: Mylev,
  },
  {
    path: '/login',
    name: 'login',
    component: Login,
  },
  {
    path: '/progress',
    name: 'progress',
    component: Progress,
  },
  {
    path: '/foodplan',
    name: 'foodplan',
    component: FoodPlan,
  },
  {
    path: '/shop/:slug?',
    name: 'shop',
    component: Shop,
  },
  {
    path: '/product/:slug',
    name: 'product',
    component: Product,
  },
  {
    path: '/order/:hash',
    name: 'order',
    component: Order,
  },
  {
    path: '/checkout',
    name: 'checkout',
    component: Checkout,
  },
  {
    path: '/profile',
    name: 'profile',
    component: Profile,
  },
  {
    path: '/receipt',
    name: 'receipt',
    component: Receipt,
  },
  {
    path: '/logout',
    name: 'logout',
    redirect: { name: 'login' },
  },
  {
    path: '/reset/:token/:email',
    name: 'reset',
    component: Password,
  },
  {
    path: '/:catchAll(.*)',
    redirect: '/',
    name: 'not-found',
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach((to, from, next) => {
  if (!['login', 'reset'].includes(to.name) && store.state.token === null) {
    next('/login');
    return;
  } else if (['login', 'reset'].includes(to.name) && store.state.token) {
    next('/');
    return;
  }
  if (to.name === 'shop' && from.name === 'product') {
    setTimeout(() => {
      window.scrollTo(0, store.state.scrollY);
    }, 200);
  } else {
    window.scrollTo(0, 0);
  }
  next();
});

export default router;
