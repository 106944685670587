<template>
  <div v-if="stock" class="stock" :class="{ in_shop: shop }">
    <div :style="color" class="dot"></div>
    <p>{{ message }}</p>
  </div>
</template>

<script>
export default {
  props: {
    stock: {
      type: Object,
      default: null,
    },
    // low_stock: {
    //   type: Number,
    //   default: 0,
    // },
    // in_stock: {
    //   type: Boolean,
    //   default: false,
    // },
    shop: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    color() {
      let color;
      if (!this.stock.in_stock) {
        color = '#ed4848';
      } else if (this.stock.stock <= this.stock.low_stock) {
        color = '#fec52f';
      } else {
        color = '#37b953';
      }
      return { backgroundColor: color };
    },
    message() {
      if (!this.stock.in_stock) {
        return this.$t('product.not_in_stock');
      } else if (this.stock.stock <= this.stock.low_stock) {
        return this.$t('product.low_stock');
      } else {
        return this.$t('product.in_stock');
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.in_shop {
  margin-left: 0 !important;
  height: 40px;
  p {
    font-weight: 400 !important;
  }
}
.stock {
  display: flex;
  align-items: center;
  .dot {
    height: 12px;
    width: 12px;
    margin-right: 10px;
    border-radius: 50%;
  }
  p {
    margin: 0;
    text-transform: lowercase;
    font-weight: 600;
    font-size: 14px;
    &::first-letter {
      text-transform: capitalize;
    }
  }
}
</style>
