<template>
  <div
    :style="cardMove"
    ref="appointments"
    v-click-outside="closeApp"
    class="appointments flex-column"
  >
    <hr />
    <AppointmentCarousel :futureAppointments="futureAppointments" />
    <AppointmentHistory />
  </div>
</template>

<script>
import AppointmentCarousel from './AppointmentCarousel.vue';
import AppointmentHistory from './AppointmentHistory.vue';
import shared from '@/shared';
import { mapActions, mapState } from 'vuex';
import clickOutside from '@/directives/ClickOutside';

export default {
  name: 'Appointments',

  components: {
    AppointmentCarousel,
    AppointmentHistory,
  },

  data() {
    return {
      movement: 0,
      touch: {
        startY: 0,
        endY: 0,
        startX: 0,
        endX: 0,
      },
    };
  },

  directives: {
    clickOutside: clickOutside,
  },

  methods: {
    ...mapActions(['toggle', 'animationStartPos']),
    closeApp() {
      this.toggle('appointments');
    },
    touchstart(e) {
      this.touch.startX = e.touches[0].clientX;
      this.touch.endX = e.touches[0].clientX;
      this.touch.startY = e.touches[0].clientY;
      this.touch.endY = e.touches[0].clientY;
    },
    touchmove(e) {
      this.touch.endX = e.touches[0].clientX;
      this.touch.endY = e.touches[0].clientY;
      if (
        this.touch.endY - this.touch.startY >
          Math.abs(this.touch.endX - this.touch.startX) &&
        this.$refs.appointments.scrollTop <= 0
      ) {
        if (e.cancelable) {
          e.preventDefault();
        }
        this.movement = Math.abs(this.touch.startY - this.touch.endY);
      }
    },
    touchend() {
      if (
        Math.abs(this.touch.endY - this.touch.startY) <
          Math.abs(this.touch.endX - this.touch.startX) &&
        this.$refs.appointments.scrollTop <= 0
      ) {
        this.movement = 0;
        this.animationStartPos(this.movement);
        return;
      }
      if (Math.abs(this.touch.endY - this.touch.startY) < 40) {
        this.movement = 0;
        return;
      }
      if (
        this.touch.endY > this.touch.startY &&
        this.$refs.appointments.scrollTop <= 0
      ) {
        this.animationStartPos(this.movement);
        this.toggle('appointments');
      }
    },
  },

  computed: {
    ...mapState(['futureAppointments']),
    cardMove() {
      return { transform: 'translateY(' + this.movement + 'px)' };
    },
  },

  created() {
    this.addSwipeListeners = shared.addSwipeListeners;
    this.removeSwipeListeners = shared.removeSwipeListeners;
  },

  mounted() {
    document.body.className = 'no-scroll';
    this.addSwipeListeners('appointments');
  },

  beforeUnmount() {
    document.body.className = '';
    this.removeSwipeListeners('appointments');
  },
};
</script>

<style lang="scss" scoped>
.appointments {
  height: 80vh;
  bottom: 0;
  background-color: $white;
  width: 100vw;
  position: fixed;
  z-index: 4;
  overflow-y: scroll;
  max-width: $max-width;
  hr {
    margin: 20px 0;
    width: 30px;
    height: 4px;
    min-height: 4px;
    border: none;
    border-radius: 5px;
    background-color: $black;
  }
}
</style>
