<template>
  <div v-if="order" class="order">
    <header class="flex overlay_header">
      <div @click="close" class="back flex">
        <img class="lev" src="@/assets/back.svg" alt="" />
      </div>
    </header>
    <main class="flex-column">
      <img class="lev_logo" src="@/assets/lev-big.svg" alt="lev" />
      <h2>{{ $t('receipt.thank_you_for_your_purchase') }}</h2>
      <div
        v-if="order.id === lastOrder.id && lastOrder.mb"
        class="flex-column mb"
      >
        <p class="body-regular">
          {{
            $t('receipt.mb_instructions', {
              mb: 'MB',
              homebanking: 'HomeBanking',
            })
          }}
        </p>
        <hr />
        <div class="grid">
          <div class="flex">
            <img src="@/assets/multibanco.svg" alt="multibanco" />
          </div>
          <ul>
            <li>
              <h5 class="medium-label-thin">{{ $t('receipt.entity') }}:</h5>
              <h5 class="medium-label">{{ lastOrder.mb.entity }}</h5>
            </li>
            <hr />
            <li>
              <h5 class="medium-label-thin">{{ $t('receipt.reference') }}:</h5>
              <h5 class="medium-label">{{ reference }}</h5>
            </li>
            <hr />
            <li>
              <h5 class="medium-label-thin">{{ $t('receipt.value') }}:</h5>
              <h5 class="medium-label">{{ lastOrder.price }} €</h5>
            </li>
          </ul>
        </div>
        <hr />
        <p class="body-regular">
          {{ $t('receipt.mb_validate', { mb: 'MB' }) }}
        </p>
      </div>
      <OrderList v-if="this.order" :orderProp="order" />
      <div class="flex-column delivery">
        <h4 class="medium-label">{{ $t('receipt.delivery_address') }}</h4>
        <div
          v-if="deliveryAddress && deliveryAddress.length > 0"
          class="address"
        >
          <h4 class="medium-label-thin">
            {{ deliveryAddress[0].first_name }}
            {{ deliveryAddress[0].last_name }}
          </h4>
          <h4 class="medium-label-thin">{{ deliveryAddress[0].address }}</h4>
          <h4 class="medium-label-thin">
            {{ deliveryAddress[0].zip }} {{ deliveryAddress[0].city }}
          </h4>
        </div>
        <div v-else class="address">
          <h4 class="medium-label-thin">
            {{ user.first_name }} {{ user.last_name }}
          </h4>
          <h4 class="medium-label-thin">{{ user.address }}</h4>
          <h4 class="medium-label-thin">{{ user.zip }} {{ user.city }}</h4>
        </div>
      </div>
      <div v-if="order.refunded > 0" class="total flex">
        <h4 class="medium-label green">{{ $t('order.refunded') }}</h4>
        <h4 class="medium-label green">{{ order.refunded }} €</h4>
      </div>
      <div class="total flex">
        <h4 class="medium-label">{{ $t('receipt.total') }}</h4>
        <h4 class="medium-label">{{ order.price }} €</h4>
      </div>
      <PrimaryButton @click.stop @click="sendToCart" :title="buttonMessage" />
    </main>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex';
import OrderList from '@/components/OrderList.vue';
import PrimaryButton from '@/components/ui/PrimaryButton.vue';

export default {
  components: {
    OrderList,
    PrimaryButton,
  },
  data() {
    return {
      order: null,
      buttonMessage: this.$t('order.add_to_cart'),
    };
  },
  computed: {
    ...mapState(['user', 'deliveryAddress', 'lastOrder']),
    ...mapGetters(['getOrderByHash']),
    reference() {
      return this.lastOrder.mb.reference.replace(
        /(?!^)(?=(?:\d{3})+(?:\.|$))/gm,
        ' '
      );
    },
  },
  mounted() {
    this.order = this.getOrderByHash(this.$route.params.hash);
  },
  methods: {
    ...mapActions(['toggle']),
    ...mapMutations(['mergeCart']),
    close() {
      this.$router.go(-1);
    },
    sendToCart() {
      this.mergeCart(this.order.order_rows);
      this.$router.push({ name: 'shop' });
      this.toggle('cart');
    },
  },
};
</script>

<style lang="scss" scoped>
.order {
  margin: 0 0 100px;
  main {
    padding: 0 20px;
    .lev_logo {
      width: 100px;
    }
  }
  .total {
    justify-content: space-between;
    width: 100%;
    margin-bottom: 20px;
    .green {
      color: $green;
    }
  }
  .mb {
    margin-bottom: 20px;
    p {
      &::first-letter {
        text-transform: capitalize;
      }
    }
    .grid {
      width: 100%;
      display: grid;
      grid-template-columns: 1.2fr 2fr;
      .flex {
        align-items: center;
        img {
          height: 66px;
        }
      }
      ul {
        li {
          display: flex;
          justify-content: space-between;
          h5 {
            margin: 10px 0;
            display: inline;
            &:first-letter {
              text-transform: capitalize;
            }
          }
        }
      }
    }
  }
  .delivery {
    margin-top: 20px;
    align-items: flex-start;
    width: 100%;
    .address {
      margin: 20px 0 40px;
    }
    h4 {
      text-align: left;
      letter-spacing: 1.5px;
      line-height: 20px;
      margin: 0;
    }
  }
  h2 {
    margin: 40px 0;
  }
}
</style>
