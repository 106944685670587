<template>
  <div
    v-if="userHasLoaded && $route.name !== 'receipt' && $route.name !== 'order'"
    class="flex-column"
  >
    <img
      :class="{ turn__arrow: reloadActive }"
      src="@/assets/back.svg"
      class="arrow"
    />
  </div>
  <Spinner
    v-else
    v-show="$route.name !== 'receipt' && $route.name !== 'order'"
    :fixed="true"
  />
  <router-view
    :key="$route.fullPath"
    v-if="userHasLoaded || !token"
    v-slot="{ Component }"
  >
    <component :is="Component" />
  </router-view>
  <NavBottom
    :class="{ show_on_top: onboarding > 0 }"
    v-if="
      navBottomActive &&
      userHasLoaded &&
      $route.path !== '/checkout' &&
      $route.name !== 'receipt' &&
      $route.name !== 'order'
    "
  />
  <Error v-if="!error" :error="error" />

  <!-- <Splash v-click-outside="closeSplash" @close="closeSplash" v-if="onboarding === 1 && splash" /> -->

  <Onboarding
    v-if="onboarding === 1"
    top="90px"
    :text="onboardingOne"
    :position="{ top: '-50px', left: '0' }"
  />
  <Onboarding
    v-if="onboarding === 2"
    top="160px"
    :text="onboardingTwo"
    :position="{ bottom: '20px', left: '10px' }"
  />
  <Onboarding
    v-if="onboarding === 3"
    top="30%"
    :text="onboardingThree"
    :position="{ top: '0', left: '10px' }"
  />
  <!-- overlays -->
  <TransitionCustom :transitionName="'slide-right'">
    <Contact v-if="contactOverlay" />
  </TransitionCustom>
  <TransitionCustom :transitionName="'slide-up'">
    <Appointments v-if="appointmentsOverlay" />
  </TransitionCustom>
  <TransitionCustom :transitionName="'slide-up'">
    <LineCardBig v-if="chartOverlay" />
  </TransitionCustom>
  <TransitionCustom :transitionName="'slide-left'">
    <FilterProducts v-if="filterOverlay" />
  </TransitionCustom>
  <TransitionCustom :transitionName="'slide-left'">
    <RecoverPassword v-if="passwordOverlay" />
  </TransitionCustom>
  <TransitionCustom :transitionName="'slide-left'">
    <Cart v-if="cartOverlay" />
  </TransitionCustom>
  <MbWay v-if="mbwayPending" />
  <div class="overlay" v-if="overlayActive"></div>
</template>

<script>
// import Navigation from './components/Navigation';
import NavBottom from './components/NavBottom';
import { mapState, mapActions, mapMutations } from 'vuex';
import Error from './components/Error';
import TransitionCustom from '@/components/TransitionCustom';
import Contact from '@/components/Contact.vue';
import LineCardBig from '@/components/LineCardBig.vue';
import FilterProducts from '@/components/FilterProducts.vue';
import RecoverPassword from '@/components/RecoverPassword.vue';
import Cart from '@/components/Cart.vue';
import MbWay from '@/components/MbWay.vue';
import Spinner from '@/components/ui/Spinner.vue';
import Appointments from '@/components/Appointments.vue';
import Onboarding from '@/components/ui/Onboarding.vue';
import bolev from './apis/bolev';
// import Splash from '@/components/ui/Splash.vue';
import clickOutside from '@/directives/ClickOutside';

export default {
  name: 'App',

  components: {
    Error,
    NavBottom,
    TransitionCustom,
    Contact,
    LineCardBig,
    Spinner,
    RecoverPassword,
    Cart,
    FilterProducts,
    Appointments,
    Onboarding,
    // Splash,
    MbWay,
  },

  directives: {
    clickOutside: clickOutside,
  },

  data() {
    return {
      reloadActive: false,
      touch: {
        yDif: 0,
      },
      throttleTimer: null,
      splash: true,
    };
  },

  watch: {
    token: function (value) {
      if (value) {
        this.$store.commit('setError', null);
        this.getPromotions();
        this.getPromotionsShop();
        this.getPosts();
        this.getPrices();
        this.getCategories();
        this.getTags();
        this.getOptions();
        this.getUserData();
        if (this.firstTimeUser) {
          setTimeout(() => {
            this.guide();
          }, 500);
        }
      }
    },
    patientId(value, oldValue) {
      if (value && oldValue === null) {
        this.listenForBolevEvents();
      }
    },
    onboarding(value) {
      if (value === 2) {
        this.$router.push({ name: 'progress' });
      } else if (value === 3) {
        this.$router.push({ name: 'foodplan' });
      } else if (value === 4) {
        this.mutate({
          property: 'onboarding',
          with: 0,
        }),
          this.mutate({
            property: 'overlayActive',
            with: false,
          }),
          this.$router.push({ path: '/' });
        document.body.className = '';
        this.mutate({
          property: 'firstTimeUser',
          with: false,
        });
      }
    },
  },

  computed: {
    ...mapState([
      'patientId',
      'token',
      'user',
      'error',
      'cart',
      'nextAppointment',
      'contactOverlay',
      'chartOverlay',
      'profileOverlay',
      'passwordOverlay',
      'appointmentsOverlay',
      'filterOverlay',
      'overlayActive',
      'cartOverlay',
      'onboarding',
      'firstTimeUser',
      'navBottomActive',
      'onboarding',
      'indexMylev',
      'mbwayPending',
    ]),
    onboardingOne() {
      return this.$t('onboarding.one');
    },
    onboardingTwo() {
      return this.$t('onboarding.two');
    },
    onboardingThree() {
      return this.$t('onboarding.three');
    },
    userHasLoaded() {
      if (this.user) return true;
      else return false;
    },
  },

  async mounted() {
    this.$store.commit('setError', null);
    if (this.token) {
      await this.getOptions();
      this.getPromotions();
      this.getPromotionsShop();
      this.getPosts();
      this.getPrices();
      this.getCategories();
      this.getTags();
      this.getUserData();
    }
  },

  methods: {
    ...mapActions([
      'getUserData',
      'getPosts',
      'getPromotions',
      'getPromotionsShop',
      'getPosts',
      'getPrices',
      'prevIndexMylev',
      'getCategories',
      'getTags',
      'getOptions',
    ]),
    ...mapMutations(['mutate', 'inc']),
    guide() {
      document.body.className = 'no-scroll';
      this.inc('onboarding');
      this.mutate({
        property: 'overlayActive',
        with: true,
      });
    },
    closeSplash() {
      this.splash = false;
    },
    openGuide() {
      this.$router.push({ name: 'mylev' });
      this.guide();
    },
    nextOnboarding() {
      this.onboarding++;
    },
    touchStart(e) {
      this.startX = e.touches[0].clientX;
      this.startY = e.touches[0].clientY;
      this.startOffset = window.pageYOffset;
    },
    preventScroll(e) {
      this.xDif = Math.abs(e.touches[0].clientX - this.startX);
      this.yDif = Math.abs(e.touches[0].clientY - this.startY);
      let yMove = e.touches[0].clientY - this.startY;
      if (yMove - this.startOffset > 150 && !this.overlayActive) {
        this.reloadActive = true;
      } else this.reloadActive = false;
      if (this.xDif > this.yDif && e.cancelable && !this.overlayActive) {
        e.preventDefault();
        e.returnValue = false;
        return false;
      }
    },
    reload() {
      if (this.reloadActive) {
        location.reload();
      }
    },
    throttle(func, delay) {
      if (this.throttleTimer) {
        return;
      }

      this.throttleTimer = setTimeout(() => {
        func();
        this.throttleTimer = null;
      }, delay);
    },
    async listenForBolevEvents() {
      if (this.patientId) {
        this.echo = await bolev.getSocketsClient();
        this.echo
          .channel(`patient.${this.patientId}`)
          .listen('.patient', () => {
            this.throttle(this.getUserData, 20000);
          })
          .listen('.order', () => {
            this.throttle(this.getUserData, 20000);
          });
      }
    },
  },
};
</script>

<style lang="scss">
@import '@/styles/_general';
@import url('https://fonts.googleapis.com/css2?family=Lato:wght@400;700&family=Montserrat:wght@400;500;600;700&display=swap');

.arrow {
  margin: -40px 0 20px 0;
  transition: all 0.3s ease;
  transform: rotate(-90deg);
}
.turn__arrow {
  transform: rotate(90deg);
}
.overlay {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  z-index: 3;
  backdrop-filter: brightness(60%);
  -webkit-backdrop-filter: brightness(60%);
}
</style>
