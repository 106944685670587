<template>
  <ul :class="{ closed: !open }" class="sort">
    <h4>{{ $t('shop.sort_by') }}</h4>
    <li @click="sortProducts(sort)" v-for="sort in sortList" :key="sort.type">
      <Checkbox :round="true" :active="isActive(sort.type)" />
      <h5>{{ sort.title }}</h5>
    </li>
  </ul>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import Checkbox from '@/components/ui/Checkbox.vue';

export default {
  components: {
    Checkbox,
  },
  computed: {
    ...mapState(['sortBy']),
  },
  emits: ['clicked'],
  methods: {
    ...mapActions(['setSortBy']),
    sortProducts(s) {
      if (this.open) {
        this.setSortBy(s.type);
      } else {
        this.open = !this.open;
      }
    },
    isActive(type) {
      if (type === this.sortBy) {
        return true;
      } else return false;
    },
  },
  data() {
    return {
      active: false,
      open: false,
      sortList: [
        { title: this.$t('shop.popularity'), type: 'popular' },
        { title: this.$t('shop.price_low_to_high'), type: 'price' },
        { title: this.$t('shop.by_letter_az'), type: 'letter' },
        { title: this.$t('shop.by_letter_za'), type: 'letterReverse' },
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
.closed {
  height: 150px;
  &::after {
    content: '';
    width: 100%;
    height: 15%;
    background: linear-gradient(180deg, rgba(251, 247, 244, 0), $light-brown);
    position: absolute;
    left: 0;
    bottom: 0;
    pointer-events: none;
  }
}
.sort {
  position: relative;
  overflow: hidden;
  background-color: $light-brown;
  padding-top: 40px;
  h4 {
    text-align: left;
    padding: 0 0 10px 20px;
    text-transform: lowercase;
    color: $brown;
    font-weight: 500;
    font-size: 1.2rem;
    &::first-letter {
      text-transform: uppercase;
    }
  }
  li {
    padding: 10px 20px;
    display: flex;
    align-items: center;
    h5 {
      margin-left: 10px;
      margin-top: 2px;
      font-weight: 300;
      color: $black;
      font-size: 16px;
      text-align: left;
      &::first-letter {
        text-transform: capitalize;
      }
    }
  }
}
</style>
