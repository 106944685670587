import { createI18n } from 'vue-i18n';
import axios from 'axios';

const getTranslations = (locale) => {
  try {
    return axios.get(
      `${process.env.VUE_APP_BOLEV_URL}/api/lang/${locale}.json`
    );
  } catch (err) {
    throw new Error('login error');
  }
};

const setI18nLanguage = (i18n, locale) => {
  if (i18n.mode === 'legacy') {
    i18n.global.locale = locale;
  } else {
    i18n.global.locale.value = locale;
  }
  document.querySelector('html').setAttribute('lang', locale);
};

export default async function setupI18n(options = { locale: 'en' }) {
  const i18n = createI18n(options);
  setI18nLanguage(i18n, options.locale);
  const { data: messages } = await getTranslations(options.locale);
  i18n.global.setLocaleMessage(options.locale, messages);
  return i18n;
}
