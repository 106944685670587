<template>
  <div class="circle-content flex-column">
    <svg
      :width="isSmall ? '130' : '200'"
      :height="isSmall ? '130' : '200'"
      :viewBox="isSmall ? '0 0 130' : '0 0 200'"
      preserveAspectRatio="xMidYMin slice"
    >
      <circle
        :cx="isSmall ? '65' : '100'"
        :cy="isSmall ? '65' : '100'"
        :r="isSmall ? '65' : '100'"
        :fill="background"
      ></circle>
      <path
        v-for="path in paths"
        :key="path"
        :d="drawPath(path.percent)"
        :fill="path.color"
        :transform="
          isSmall
            ? `rotate(${path.start * 3.6} 65 65)`
            : `rotate(${path.start * 3.6} 100 100)`
        "
      ></path>
      <circle
        :cx="isSmall ? '65' : '100'"
        :cy="isSmall ? '65' : '100'"
        :r="isSmall ? '54' : '84'"
        :fill="center"
      ></circle>
    </svg>
    <div class="circle-content__text-block">
      <p class="circle-content__percent" :class="{ small: isSmall }">
        {{ numberFormat(percent) }}<span>%</span>
      </p>
      <p class="circle-content__amount" :class="{ small: isSmall }">
        {{ numberFormat(difference) }}kg
      </p>
    </div>
  </div>
</template>

<script>
import shared from '@/shared';
import { mapState } from 'vuex';

export default {
  props: {
    innerColor: {
      type: String,
      default: '#fffdf8',
    },
    isSmall: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      background: '#ffe2ab',
      center: this.innerColor,
      segments: [{ percent: 0, color: '#e4a814' }],
    };
  },

  created() {
    this.numberFormat = shared.numberFormat;
  },

  mounted() {
    this.segments[0].percent = this.percent;
  },

  computed: {
    ...mapState(['measurements']),
    paths() {
      const res = this.segments.reduce(
        (acc, segment) => {
          acc.segments.push({ ...segment, start: acc.start });
          acc.start += segment.percent;
          return acc;
        },
        { segments: [], start: 0 }
      );

      return res.segments;
    },
    percent() {
      const percent = Math.abs(
        (this.difference /
          (this.weightFiltered[0] -
            this.measurements[this.measurements.length - 1].target_weight)) *
          100
      );
      if (percent < 0) {
        return 0;
      } else if (percent > 100) {
        return 100;
      } else return percent;
    },
    difference() {
      return (
        this.weightFiltered[this.weightFiltered.length - 1] -
        this.weightFiltered[0]
      );
    },
    weight() {
      return this.measurements
        .filter((m) => m.current_weight !== null)
        .map((m) => ({
          value: m.current_weight,
          date: m.date,
        }));
    },
    weightFiltered() {
      return this.weight.map((item) => item.value);
    },
  },
  methods: {
    drawPath(percent) {
      var start = 0;
      const size = this.isSmall ? '130' : '200',
        rad = size / 2,
        unit = (Math.PI * 2) / 100,
        end = percent * unit - 0.001,
        x1 = rad + rad * Math.sin(start),
        y1 = rad - rad * Math.cos(start),
        x2 = rad + rad * Math.sin(end),
        y2 = rad - rad * Math.cos(end),
        big = end - start > Math.PI ? 1 : 0;
      return `M ${rad},${rad} L ${x1},${y1} A ${rad},${rad} 0 ${big} 1 ${x2},${y2} Z`;
    },
  },
};
</script>

<style lang="scss" scoped>
svg {
  position: absolute;
  z-index: 0;
}
.circle-content {
  position: relative;
  height: 180px;
  justify-content: center;
  margin: 0 auto;
  h4 {
    margin-top: 57px;
    margin-bottom: 5px;
  }
  h1 {
    margin: 0;
  }
  h5 {
    margin: 5px;
    font-size: 12px;
    letter-spacing: -0px;
    color: $orange;
  }
  p {
    margin: 0;
  }
  &__text-block {
    text-align: center;
    z-index: 1;
  }
  &__percent {
    font-size: 68px;
    font-weight: 600;
    letter-spacing: -0.06em;
    color: $warm-yellow;
    span {
      font-size: 36px;
      font-weight: 700;
    }
    &.small {
      font-size: 36px;
      span {
        font-size: 22px;
      }
    }
  }
  &__amount {
    font-size: 22px;
    font-weight: 700;
    letter-spacing: -0.01em;
    color: $primary-green;
    &.small {
      font-size: 15px;
    }
  }
  .circle {
    z-index: -1;
    position: absolute;
    left: 50%;
    transform: translate(-50%, 0);
  }
}
</style>
