<template>
  <div :class="{ show_on_top: onboarding === 3 }" class="day-to-day">
    <div class="day-to-day__title block-subtitle">
      {{ $t('foodPlan.day_to_day') }}
    </div>
    <ul ref="content" class="day-to-day__content">
      <li class="day-to-day__content-item">
        <img src="@/assets/arrow-right.svg" alt="" />
        <p
          class="body-regular"
          v-html="
            $t('foodPlan.meals_per_day', {
              min: `<span>${shorten(phase.min)}</span>`,
              max: `<span>${shorten(phase.max)}</span>`,
              numberRed: `<span>${shorten(phase.red_marked)}</span>`,
            })
          "
        ></p>
      </li>
      <li class="day-to-day__content-item">
        <img src="@/assets/arrow-right.svg" alt="" />

        <p
          class="body-regular"
          v-html="
            $t('foodPlan.drink_water_per_day', {
              liters: `<span>${shorten(phase.water)}</span>`,
              arl_liters: `<span>${shorten(phase.arl_liters)}</span>`,
              arl_dose: `<span>${shorten(phase.arl)}</span>`,
              arl: `ARL`,
            })
          "
        ></p>
      </li>
      <p class="body-regular" v-if="phase.notes">"{{ phase.notes }}"</p>
      <h6 v-if="dietitian && phase.notes">{{ dietitian.name }}</h6>
    </ul>
  </div>
</template>

<script>
import shared from '@/shared';
import { mapGetters, mapState } from 'vuex';

export default {
  data() {
    return {
      componentLoaded: false,
    };
  },
  props: {
    open: {
      type: Boolean,
      default: true,
    },
    phase: {
      type: Object,
    },
  },

  created() {
    this.shorten = shared.shorten;
  },

  computed: {
    ...mapGetters(['getDietitian']),
    ...mapState(['onboarding']),
    dietitian() {
      return this.getDietitian(this.phase.dietitian_id);
    },
    contentOpenClose() {
      if (!this.componentLoaded) {
        return null;
      }
      if (this.open) {
        return { height: `${this.contentHeight + 120}px` };
      } else {
        return { height: '84px' };
      }
    },
    contentHeight() {
      return this.$refs.content.getBoundingClientRect().height;
    },
  },

  mounted() {
    this.componentLoaded = true;
  },

  methods: {
    capitalize(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    },
  },
};
</script>

<style lang="scss" scoped>
.day-to-day {
  padding: 0;
  padding-bottom: 20px;
  &__title {
    margin-bottom: 20px;
  }
  &__content {
    &-item {
      display: flex;
      align-items: flex-start;
      &:not(:last-child) {
        margin-bottom: 20px;
      }
      p {
        margin: 0;
        ::v-deep(span) {
          font-weight: 700;
          color: $orange;
        }
        &::first-letter {
          text-transform: uppercase;
        }
        span {
          font-weight: 700;
        }
      }
      img {
        margin-right: 15px;
      }
    }
    h6 {
      text-align: left;
      text-transform: capitalize;
    }
  }
}
</style>
