import axios from "axios";

const apiCall = async ({ endpoint, method, payload, name }) => {
  method = method || "get";
  const namespace = name ? name : "wp/v2";

  try {
    const { data } = await axios({
      method: method,
      url: `${process.env.VUE_APP_WORDPRESS_URL}/wp-json/${namespace}/${endpoint}`,
      data: payload,
    });
    return data;
  } catch (err) {
    if (err.response.status === 404) {
      throw new Error("not found");
    } else {
      throw new Error("error");
    }
  }
};

const getPosts = async () => await apiCall({ endpoint: "posts" });

const getPromotions = async () => await apiCall({ endpoint: "promotion" });

const getOptions = async () =>
  await apiCall({ endpoint: "options", name: "lev" });

const getPromotionsShop = async () =>
  await apiCall({ endpoint: "promotion_shop" });

export default {
  getPosts,
  getPromotions,
  getPromotionsShop,
  getOptions,
};
