<template>
  <div class="order-list">
    <header>
      <h4 class="medium-label">
        {{ $t('receipt.order_number') }}: #{{ order.id }}
      </h4>

      <h4 class="medium-label-thin">{{ date }}</h4>
    </header>

    <ProductList
      v-if="orderProp"
      :products="getOrderRows(order.id)"
      :order="true"
      :small="true"
    />

    <ProductList
      v-else-if="getLastOrder"
      :products="getLastOrder"
      :order="true"
      :small="true"
    />

    <div v-if="order.refunded > 0" class="refund flex">
      <h3>{{ $t('order.refunded') }}</h3>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import ProductList from '@/components/ProductList.vue';
import moment from 'moment';

export default {
  components: {
    ProductList,
  },
  props: {
    orderProp: {
      type: Object,
      default: null,
    },
  },
  computed: {
    ...mapGetters(['getCart', 'getLastOrder', 'getOrderRows']),
    ...mapState(['lastOrder']),
    date() {
      return moment(this.order.created_at)
        .locale(process.env.VUE_APP_LANG || 'en')
        .format('DD MMM YYYY');
    },
    order() {
      if (this.orderProp) return this.orderProp;
      else return this.lastOrder;
    },
  },
};
</script>

<style lang="scss" scoped>
.order-list {
  position: relative;
  width: 100%;
}
.refund {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  align-items: center;
  background-color: rgba($color: #fff, $alpha: 0.5);
  h3 {
    color: $green;
  }
}
p,
h6,
h4 {
  text-align: left;
}
header {
  width: 100%;
  h4 {
    margin: 10px 0;
    letter-spacing: 1.5px;
  }
}
.order_list {
  width: 100%;
  margin: 20px 0;
  .flex {
    justify-content: left;
    align-items: center;
    .img_wrapper {
      width: 60px;
      height: 60px;
      margin: 0 20px;
      justify-content: center;
      img {
        max-width: 60px;
        max-height: 40px;
        object-fit: contain;
      }
    }
    .price {
      margin-left: auto;
    }
    .name {
      max-width: 120px;
    }
  }
}
</style>
