<template>
  <ul class="tags">
    <h4>{{ $t('shop.show') }}</h4>
    <li @click="filterByTag(tag.id)" v-for="tag in tags" :key="tag.id">
      <Checkbox :active="isActive(tag.id)" />
      <h5>{{ tag.name }}</h5>
    </li>
  </ul>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import Checkbox from '@/components/ui/Checkbox.vue';

export default {
  components: {
    Checkbox,
  },
  computed: {
    ...mapState(['tags']),
  },
  methods: {
    ...mapActions(['filterByTag']),
    isActive(id) {
      let found = this.tags.find((t) => {
        return t.id === id;
      });
      return found.active ? true : false;
    },
  },
};
</script>

<style lang="scss" scoped>
.tags {
  position: relative;
  overflow: hidden;
  background-color: $light-brown;
  padding-bottom: 10px;
  h4 {
    text-align: left;
    padding: 10px 20px;
    text-transform: lowercase;
    color: $brown;
    font-weight: 500;
    font-size: 1.2rem;
    &::first-letter {
      text-transform: uppercase;
    }
  }
  li {
    padding: 10px 20px;
    display: flex;
    align-items: center;
    h5 {
      margin-left: 10px;
      margin-top: 2px;
      font-weight: 300;
      font-size: 1rem;
      color: $black;
      &::first-letter {
        text-transform: capitalize;
      }
    }
  }
}
</style>
