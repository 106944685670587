<template>
  <NavTop :title="title" />
  <div v-if="phase && phase.phase" class="food-plan">
    <h1 v-if="phase" class="food-plan__title page-title">
      phase {{ phase.phase }}
    </h1>
    <section v-if="phase" class="food-plan__day-to-day">
      <DayToDay
        @click="toggleOpen(1)"
        :open="listOfComponents[0].open"
        :phase="phase"
      />
    </section>

    <section v-if="phase" class="food-plan__meals">
      <template v-for="(meal, index) in getMealsSorted" :key="meal.id">
        <Meal
          :meal="meal"
          :title="getTitle(meal.time_of_day)"
          :layerImg="getLayerImg(meal.time_of_day)"
          @label-clicked="toggleOpen(meal.id)"
          :open="listOfComponents[index + 1].open"
        />
      </template>
    </section>

    <ListOfFoods v-if="phase" @label-clicked="toggleOpen(9999)" />
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import DayToDay from '@/components/DayToDay';
import Meal from '@/components/Meal.vue';
import ListOfFoods from '@/components/ListOfFoods.vue';
import NavTop from '@/components/NavTop.vue';

export default {
  data() {
    return {
      listOfComponents: [{ id: 1, open: true }],
    };
  },

  components: {
    DayToDay,
    Meal,
    ListOfFoods,
    NavTop,
  },

  mounted() {
    if (this.onboarding === 3) {
      this.toggleOpen(1);
    }
  },

  methods: {
    toggleOpen(id) {
      this.listOfComponents.forEach((e) => {
        e.id === id ? (e.open = !e.open) : (e.open = false);
      });
    },

    close() {
      this.listOfComponents.forEach((e) => {
        e.open = false;
      });
    },

    getLayerImg(timeOfDay) {
      if (timeOfDay.includes('breakfast') || timeOfDay.includes('morning')) {
        return 'sunrise.svg';
      } else if (
        timeOfDay.includes('dinner') ||
        timeOfDay.includes('evening')
      ) {
        return 'sunset.svg';
      } else return;
    },
    getTitle(timeOfDay) {
      return this.$t(`foodPlan.${timeOfDay}`);
    },
  },

  computed: {
    ...mapState(['phase', 'indexMylev', 'onboarding']),
    ...mapGetters(['getMealsSorted']),
    foodListOpen() {
      const foodList = this.listOfComponents.find((c) => c.id === 9999);
      return foodList.open;
    },
    title() {
      return this.$t('navBottom.food_plan');
    },
  },

  created() {
    this.getMealsSorted.forEach((e) => {
      this.listOfComponents.push({ id: e.id });
    });
    this.listOfComponents.push({ id: 9999 });
  },
};
</script>

<style scoped lang="scss">
.food-plan {
  padding-top: 75px;
  background-color: $background-color-peach;
  &__title {
    padding: 16px 20px;
    background-color: rgba($deep-yellow, 0.15);
  }
  &__day-to-day {
    padding: 0 20px;
    background-color: rgba($deep-yellow, 0.15);
  }
  &__meals {
    padding: 25px 10px;
  }
}
</style>
