<template>
  <div class="sheet" :style="contentOpenClose">
    <div class="label" @click="$emit('labelclicked', info.title)">
      <h4 class="medium-label">{{ info.title }}</h4>
      <img
        :class="{ flip: info.open }"
        src="@/assets/arrow-yellow.svg"
        alt=""
      />
    </div>
    <div
      v-if="!Array.isArray(info.info)"
      v-html="info.info"
      ref="content"
      class="content flex"
    ></div>
    <div v-else ref="content" class="content flex-column">
      <a
        v-for="link in info.info"
        target="_blank"
        :key="link"
        :href="link.link_url"
      >
        <div class="link">
          <p>{{ link.link_title }}</p>
        </div>
      </a>
    </div>
  </div>
  <hr />
</template>

<script>
export default {
  data() {
    return {
      componentLoaded: false,
    };
  },
  props: {
    info: {
      type: Object,
    },
  },

  emits: ['labelclicked'],
  computed: {
    contentHeight() {
      return this.$refs.content.getBoundingClientRect().height;
    },
    contentOpenClose() {
      if (!this.componentLoaded) {
        return null;
      }
      if (this.info.open) {
        return { height: `${this.contentHeight + 67}px` };
      } else {
        return { height: '55px' };
      }
    },
  },
  mounted() {
    this.componentLoaded = true;
  },
};
</script>

<style lang="scss" scoped>
.sheet {
  height: 200px;
  overflow: hidden;
  transition: $open-card ease-in-out;
  .label {
    height: 55px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    overflow: hidden;
    white-space: nowrap;
    h4 {
      white-space: normal;
    }
    img {
      width: 14px;
      margin-right: 20px;
      transition: all $open-card ease-in-out;
    }
    .flip {
      transform: rotate(-180deg);
    }
  }
  .content {
    margin-bottom: 100px;
    justify-content: flex-start;
    flex-direction: column;
    .link {
      box-shadow: $box-shadow;
      width: calc(100vw - 44px);
      position: relative;
      align-items: center;
      margin: 10px 0 10px 2px;

      p {
        margin: 0;
        padding: 20px;
        text-align: left;
      }
    }
  }

  h4 {
    text-align: start;
    text-transform: capitalize;
  }
}
hr {
  margin: 0 0 0 -20px;
  height: 1px;
  border: none;
  width: calc(100% + 40px);
  background-color: rgba($color: #000000, $alpha: 0.05);
}
p {
  width: 100%;
}
</style>
