<template>
  <ul ref="list" class="product-list flex-column">
    <template v-for="product in productsSorted" :key="product.id">
      <ProductCard
        v-if="!order"
        :search="search"
        @clicked="goToProduct(product)"
        :product="product"
        :small="small"
        :deleted="deleted"
      />
      <ProductCard v-else :product="product" :small="small" :order="true" />
    </template>
    <slot v-if="$slots.default"></slot>
  </ul>
</template>

<script>
import ProductCard from '@/components/ProductCard.vue';
import { mapActions, mapMutations, mapState } from 'vuex';

export default {
  props: {
    products: Array,
    small: Boolean,
    deleted: Boolean,
    shop: {
      type: Boolean,
      default: false,
    },
    order: {
      type: Boolean,
      default: false,
    },
    search: {
      type: Boolean,
      default: false,
    },
    activeTags: {
      type: Array,
      default: null,
    },
  },
  components: {
    ProductCard,
  },
  computed: {
    ...mapState(['searchActive', 'overlayActive']),
    productsSorted() {
      let products = [...this.products];
      return products.sort((a, b) => (a.featured && !b.featured ? -1 : 0));
    }
  },
  methods: {
    ...mapActions(['setCategory']),
    ...mapMutations(['mutate']),

    goToProduct(product) {
      this.setCategory(this.getCategory(product));
      this.mutate({
        property: 'scrollY',
        with: window.scrollY,
      });
      this.$router.push({ path: `/product/${product.slug}` });
    },

    getCategory(product) {
      const bottomLevel = product.categories.find((c) => c.parent !== 0);
      return bottomLevel ? bottomLevel : product.categories[0];
    },
  },
};
</script>

<style lang="scss" scoped>
.product-list {
  width: 100%;
}
.padding {
  padding: 0 20px;
}
</style>
