<template>
  <div class="shop flex-column" ref="shop">
    <NavTop />
    <div class="shop__breadcrumbs">
      <Breadcrumbs :category="category" />
    </div>
    <div class="shop__categories">
      <ul class="shop__categories-list">
        <li
          @click="
            $router.push({ name: 'shop', params: { slug: 'myproducts' } })
          "
          :class="{
            'shop__categories-myproducts--active':
              $route.params.slug === 'myproducts',
          }"
          class="shop__categories-myproducts"
        >
          <h4>{{ $t('shop.my_products') }}</h4>
        </li>
        <Category
          :category="category"
          :openCategory="openCategory"
          v-for="category in topLevelCategories"
          :key="category.id"
          @open="open"
          @save="save"
          class="shop__categories-item"
          :isHorizontal="true"
        />
      </ul>
    </div>
    <div class="shop_content">
      <section
        v-if="!category && $route.params.slug !== 'myproducts'"
        class="promotions"
      >
        <div v-if="promotionsShop" class="cards">
          <p class="promotions__title block-title slider-title">
            {{ $t('news.news') }}
          </p>
          <LevContainer>
            <CustomLink
              v-for="promo in promotionsShop"
              :key="promo.id"
              :to="promoLink(promo.fields)"
            >
              <div class="card">
                <div v-if="promo.fields.image" class="img-wrapper">
                  <img
                    :src="promo.fields.image.sizes.large"
                    :alt="promo.fields.image.title"
                  />
                </div>
                <p class="promotions__name">{{ promo.fields.title }}</p>
              </div>
            </CustomLink>
          </LevContainer>
        </div>
        <div v-else class="cards">
          <div :style="backgroundColor('')" class="card">
            <div class="titles">
              <h5>{{ $t('news.no_current_news') }}</h5>
            </div>
          </div>
        </div>
      </section>
      <div class="shop_category">
        <h2 v-if="categoryName" class="page-title">{{ categoryName }}</h2>
        <h2
          v-else-if="$route.fullPath === '/shop/myproducts'"
          class="page-title"
        >
          {{ $t('shop.my_products') }}
        </h2>
        <h2 v-else-if="!activeTags.length" class="page-title">
          {{ options.field_app_shop.title }}
        </h2>
        <h5
          class="small-label-thin filter_info"
          v-if="activeTags.length > 0 && filteredAndSorted.length === 1"
        >
          {{ $t('shop.product') }}
          <span
            @click="openTag(tag.id)"
            v-for="(tag, index) in activeTags"
            :key="tag.id"
          >
            <span v-if="index !== 0"> & </span>
            <strong>{{ tag.name }}</strong>
          </span>
        </h5>
        <h5
          class="small-label-thin filter_info"
          v-else-if="activeTags.length > 0"
        >
          {{ $t('shop.products') }}
          <span
            @click="openTag(tag.id)"
            v-for="(tag, index) in activeTags"
            :key="tag.id"
          >
            <span v-if="index !== 0"> & </span>
            <strong>{{ tag.name }}</strong>
          </span>
        </h5>
      </div>
      <ProductList
        class="product_list_grid"
        :small="false"
        :products="filteredAndSorted"
        :shop="true"
        :activeTags="activeTags"
      />
      <Skeleton v-if="loading" />
      <LoadMore :next="page" :total="lastPage" @more="loadProducts" />
    </div>
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState, mapGetters } from 'vuex';
import ProductList from '@/components/ProductList.vue';
import NavTop from '@/components/NavTop.vue';
import clickOutside from '@/directives/ClickOutside';
import touchOutside from '@/directives/TouchOutside';
import woocommerce from '../apis/woocommerce';
import LoadMore from '@/components/ui/LoadMore.vue';
import Breadcrumbs from '@/components/ui/Breadcrumbs.vue';
import CustomLink from '@/components/ui/Link.vue';
import LevContainer from '@/components/LevContainer.vue';
import Category from '@/components/Category.vue';
import Skeleton from '@/components/ui/Skeleton.vue';

export default {
  name: 'shop',

  components: {
    ProductList,
    NavTop,
    LoadMore,
    Breadcrumbs,
    CustomLink,
    LevContainer,
    Category,
    Skeleton,
  },

  directives: {
    clickOutside: clickOutside,
    touchOutside: touchOutside,
  },

  data() {
    return {
      searchInput: '',
      tagsToFilter: [],
      page: 1,
      lastPage: null,
      loading: false,
      openCategory: 0,
    };
  },

  computed: {
    ...mapState([
      'user',
      'newProductDays',
      'searchActive',
      'phase',
      'categories',
      'sortBy',
      'tags',
      'myProducts',
      'promotionsShop',
      'filterOverlay',
      'options',
    ]),
    ...mapGetters(['products']),

    categoryName() {
      if (this.$route.name === 'shop' && this.categories.length > 0) {
        if (this.$route.params.slug) {
          const found = this.categories.find(
            (c) => c.slug === this.$route.params.slug
          );
          if (found) {
            return this.replace(found.name);
          } else {
            return '';
          }
        } else return null;
      } else if (this.$route.name === 'product' && this.categories.length > 0) {
        const cat = this.getCategory();
        return this.replace(cat.name);
      } else {
        return null;
      }
    },

    topLevelCategories() {
      return this.categories.filter((c) => c.parent === 0).sort((a, b) => a.menu_order - b.menu_order);
    },

    activeCategory() {
      if (
        this.$route.params.slug &&
        this.$route.name === 'shop' &&
        this.$route.params.slug !== 'myproducts'
      ) {
        let found = this.categories.find(
          (c) => c.slug === this.$route.params.slug
        );
        return found.parent;
      } else if (this.$route.name === 'product') {
        let found = this.categories.find(
          (c) => c.id === this.currentCategory.id
        );
        return found.parent;
      } else return 0;
    },

    productsByCategory() {
      return this.category?.slug
        ? this.products.filter((p) =>
            p.categories.find((c) => c.slug === this.category.slug)
          )
        : this.products;
    },

    filteredAndSorted() {
      if (this.sortBy === 'price') {
        return this.sortByPrice(this.productFilter);
      } else if (this.sortBy === 'letter') {
        return this.sortByLetter(this.productFilter);
      } else if (this.sortBy === 'letterReverse') {
        return this.sortByLetterReverse(this.productFilter);
      } else return this.productFilter;
    },

    activeTags() {
      return this.tags.filter((t) => t.active);
    },

    productFilter() {
      if (this.activeTags) {
        return this.productsByCategory.filter((p) => {
          return this.activeTags.every((t) =>
            p.tags.some((p) => p.id === t.id)
          );
        });
      } else return this.productsByCategory;
    },

    category() {
      return this.categories.find((c) => c.slug === this.$route.params.slug);
    },
  },

  methods: {
    ...mapActions([
      'getProducts',
      'getPrices',
      'makeOrder',
      'toggle',
      'setCategory',
    ]),
    ...mapMutations(['mutate', 'setOneTagOnly', 'setProducts']),

    close() {
      this.scrollToTop();
      this.toggle('filter');
    },

    open(id) {
      if (this.openCategory === id) {
        this.openCategory = 0;
      } else this.openCategory = id;
    },

    save(c) {
      if (c) {
        this.setCategory(c);
        this.$router.push({ name: 'shop', params: { slug: c.slug } });
      } else {
        this.$router.push({ path: '/shop' });
      }
      if (this.filterOverlay) {
        this.close();
      }
    },

    scrollToTop() {
      this.mutate({
        property: 'scrollY',
        with: 0,
      });
    },

    openTag(id) {
      this.setOneTagOnly(id);
      this.$router.push({ path: '/shop' });
    },

    search(input) {
      this.searchInput = input;
    },

    replace(string) {
      return string.replace(/&amp;/gi, '&');
    },

    sortByPrice(arr) {
      return arr.concat().sort((a, b) => {
        if (a.on_sale && b.on_sale) {
          return a.sale_price - b.sale_price;
        } else if (a.on_sale && !b.on_sale) {
          return a.sale_price - b.bolev_price;
        } else if (!a.on_sale && b.on_sale) {
          return a.bolev_price - b.sale_price;
        } else {
          return a.bolev_price - b.bolev_price;
        }
      });
    },

    sortByLetter(arr) {
      return arr.concat().sort((a, b) => {
        return new Intl.Collator(process.env.VUE_APP_LANG).compare(
          a.name,
          b.name
        );
      });
    },

    sortByLetterReverse(arr) {
      return this.sortByLetter(arr).reverse();
    },

    filterByDate() {
      let d = new Date();
      d.setDate(d.getDate() - this.newProductDays);
      return this.products.filter((p) => {
        return new Date(p.date_created) > d;
      });
    },

    loadProducts() {
      if (this.category) {
        this.loadProductsCategory();
      } else if (this.activeTags.length > 0) {
        this.loadProductsTag();
      } else if (this.$route.params.slug === 'myproducts') {
        this.loadMyProducts();
      }
    },

    promoLink(fields) {
      return fields.product_link
        ? `/product/` + fields.product_link.post_name
        : fields.link;
    },

    async loadProductsCategory() {
      this.loading = true;
      const { data, headers } = await woocommerce.getProductsByCategory(
        this.page,
        this.category.id,
        8
      );
      this.setProducts(data);
      this.page++;
      this.loading = false;
      if (headers['x-wp-totalpages']) {
        this.lastPage = parseInt(headers['x-wp-totalpages']);
      }
    },

    async loadProductsTag() {
      this.loading = true;
      const { data, headers } = await woocommerce.getProductsByTag(
        this.page,
        this.activeTags[0].id,
        8
      );
      this.setProducts(data);
      this.page++;
      this.loading = false;
      if (headers['x-wp-totalpages']) {
        this.lastPage = parseInt(headers['x-wp-totalpages']);
      }
    },

    async loadMyProducts() {
      this.loading = true;
      const { data } = await woocommerce.getProductsSku(this.myProducts);
      this.setProducts(data);
      this.loading = false;
    },

    async loadStartPageProducts() {
      this.loading = true;
      const { data } = await woocommerce.getProductsId(
        this.options.field_app_shop.products
      );
      this.setProducts(data);
      this.loading = false;
    },
  },

  mounted() {
    this.openCategory = this.activeCategory;
    if (this.category) {
      this.loadProductsCategory();
    } else if (this.activeTags.length > 0) {
      this.loadProductsTag();
      setTimeout(() => {
        window.scrollTo({
          top: 570,
          left: 0,
          behavior: 'smooth',
        });
      }, 100);
    } else if (this.$route.params.slug === 'myproducts') {
      this.loadMyProducts();
    } else {
      this.loadStartPageProducts();
    }
  },

  watch: {
    $route(to, from) {
      if (
        from.name === to.name &&
        from.path !== to.path &&
        to.name !== 'checkout'
      ) {
        this.page == 1;
        this.mutate({
          property: 'allProducts',
          with: [],
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/styles/_mixins';

.shop {
  min-height: 100vh;
  padding-bottom: 100px;
  position: relative;
  background-color: $background-color;
  &__breadcrumbs {
    margin-top: 80px;
    padding: 16px 20px 30px;
    width: 100%;
  }
  &_content {
    max-width: 100%;
    width: 100%;
    padding-top: 24px;
    ::v-deep(.card) {
      .img-wrapper {
        width: 285px !important;
        height: 285px !important;
      }
      .promotions__name {
        font-size: 18px;
        line-height: 22px;
        text-align: left;
      }
    }
  }
  &__categories {
    width: 100vw;
    height: 32px;
    //mask-image: linear-gradient(90deg,#000 90%,transparent);
    &-list {
      padding: 0 15px;
      display: flex;
      position: absolute;
      width: 100vw;
      overflow-x: scroll;
      overflow-y: hidden;
      -webkit-overflow-scrolling: touch;
    }
    &-myproducts {
      h4 {
        text-transform: capitalize;
      }
      &--active {
        background-color: transparent !important;
      }
    }
    ::v-deep(li) {
      //all: unset;
      position: relative;
      margin-right: 8px;
      //padding: 8px;
      align-self: flex-start;
      background-color: $background-color-peach;
      border-radius: $rounded-l;
      transition: all 0.5s ease-in-out;
      overflow-y: unset;
      z-index: 2;
      white-space: nowrap;
      .header {
        padding: 0 !important;
        white-space: nowrap;
      }
      .hidden {
        visibility: hidden;
        //height: 0;
      }
      &.dropdown {
        padding-right: 25px;
      }
      h4 {
        margin: 0;
        padding: 8px;
        font-size: 11px;
        font-weight: 600;
        line-height: 16px;
        text-align: center;
        color: $black !important;
      }
      img {
        right: 5px;
        top: 13px;
        transform: scale(0.8);
      }
      ul {
        position: sticky;
        top: 32px;
        left: 0;
        width: 100%;
        background-color: $background-color-peach;
        li {
          margin: 0;
          padding: 0;
        }
        h4 {
          text-align: left;
          margin-bottom: 10px;
        }
      }
    }
  }
  &_category {
    padding-left: 20px;
    margin-bottom: 20px;
    .filter_info {
      text-transform: capitalize;
    }
    h2 {
      @include sliderTitle;
    }
    h5 {
      max-width: 80%;
      text-align: left;
      margin-top: 16px;
      font-size: 14px;
      pointer-events: initial;
      strong {
        font-weight: 600;
        text-transform: capitalize;
        text-decoration: underline;
      }
    }
  }
  .product_list_grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 8px;
    padding: 0 8px;
  }
  .product_search {
    padding-top: 0;
  }
  .category-info {
    width: 100%;
    padding: 0 20px;
    .flex {
      justify-content: space-between;
      align-items: center;
    }
    p {
      margin: 8px 0 0 0;
      color: $black;
      opacity: 0.7;
      &::first-letter {
        text-transform: capitalize;
      }
    }
  }
  h4 {
    text-align: left;
  }
}
.promotions {
  width: 100%;
  &__title {
    padding: 0 20px;
  }
  &__name {
    max-width: 250px;
    font-size: 16px;
    line-height: 18px;
    font-weight: 400;
    text-align: center;
  }
  .cards {
    width: 100%;
    display: flex;
    flex-direction: column;
    .card_dark {
      .titles {
        h5 {
          color: $black !important;
          text-align: left;
        }
      }
      .promo_arrow {
        filter: brightness(0) invert(0) !important;
      }
    }
    a:not(:last-child) {
      margin-right: 14px;
    }
    .card {
      box-shadow: unset;
      width: 100%;
      border-radius: $rounded;
      filter: $card-shadow;
      align-items: center;
      margin-bottom: 10px;
      overflow: hidden;
      .post__title {
        max-width: 150px;
        text-align: left;
        margin-top: 20px;
      }
      .promo_arrow {
        transform: rotate(-90deg);
        margin-right: 16px;
        width: 16px;
        filter: brightness(0) invert(1);
      }
      .img-wrapper {
        height: 220px;
        width: 210px;
        display: flex;
        justify-content: center;
        align-items: center;
        object-fit: cover;
        overflow: hidden;
        img {
          height: 100%;
          width: auto;
        }
      }
    }
  }
}
</style>
