<template>
  <div />
</template>

<script>
export default {
  name: 'loadmore',

  data() {
    return {
      observer: null,
    };
  },

  emits: ['more'],

  computed: {
    hasMore() {
      return this.total && this.next - 1 < this.total;
    },
  },

  props: {
    next: {
      type: Number,
      default: 1,
    },
    total: {
      type: Number,
      default: null,
    },
  },

  methods: {
    handleScroll() {
      if (
        this.hasMore &&
        window.scrollY + window.innerHeight >=
          document.documentElement.scrollHeight - window.innerHeight / 2
      ) {
        this.$emit('more');
      }
    },
  },

  mounted() {
    window.addEventListener('scroll', this.handleScroll);
  },

  beforeUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  },
};
</script>
