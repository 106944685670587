<template>
  <div class="about-me">
    <h2 v-if="user" class="about-me__title page-title">{{ user.name }}</h2>
    <div v-if="user" class="about-me__info">
      <div
        :key="componentKey"
        class="about-me__image-wrapper"
        @click="$refs.imageupload.click()"
      >
        <div class="about-me__image">
          <h2 v-if="user.image === '/images/placeholder.svg'">
            {{ initials }}
          </h2>
          <img
            v-else
            ref="image"
            class="profile-img"
            :src="`${url}${getImageUrl}`"
            :alt="getImageUrl"
          />
        </div>
        <div class="about-me__camera">
          <img src="@/assets/camera.svg" alt="" />
        </div>
      </div>
    </div>
    <div class="about-me__levpoints">
      <span class="about-me__levpoints-number">{{ user.points }}</span>
      <span>{{ $t('profile.lev_points') }}</span>
    </div>
    <h6 v-if="error" class="mini-label-thin">{{ errorMessage }}</h6>

    <!-- hidden file upload input -->
    <input
      ref="imageupload"
      style="display: none"
      type="file"
      @change="onFileSelected"
    />
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';

export default {
  methods: {
    ...mapActions(['uploadImage']),
    async onFileSelected(e) {
      let file = e.target.files[0] || e.dataTransfer.files;
      await this.uploadImage(file);
      this.componentKey++;
    },
  },
  computed: {
    ...mapState(['user', 'tempImage', 'error']),
    getImageUrl() {
      if (this.tempImage) {
        return this.tempImage;
      } else return this.user.image;
    },
    initials() {
      return this.user.first_name.slice(0, 1) + this.user.last_name.slice(0, 1);
    },
    errorMessage() {
      if (this.error === '422') {
        return this.$t('profile.too_big');
      } else return this.$t('profile.error');
    },
  },
  data() {
    return {
      url: process.env.VUE_APP_BOLEV_URL,
      componentKey: 0,
    };
  },
};
</script>

<style lang="scss" scoped>
.about-me {
  position: relative;
  padding: 16px 20px;
  font-family: $font;
  background-color: rgba($deep-yellow, 0.15);
  &__title {
    margin-bottom: 20px;
  }
  &__image {
    width: 128px;
    height: 128px;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 50%;
    }
    &-wrapper {
      position: relative;
      width: fit-content;
    }
  }
  &__camera {
    position: absolute;
    bottom: 2px;
    right: 2px;
    height: 28px;
    width: 28px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $orange;
    border-radius: 50%;
    img {
      width: 16px;
    }
  }
  &__info {
    .image {
      box-shadow: $box-shadow;
      border-radius: 100%;
      background-color: $grey-transparent;
      overflow: hidden;
      h2 {
        color: $orange;
        font-size: 24px;
      }
    }
  }
  &__levpoints {
    position: absolute;
    bottom: 0;
    right: 0;
    padding: 0 16px;
    height: 68px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-family: $font;
    letter-spacing: -1px;
    color: #ffffff;
    background-color: $orange;
    &-number {
      font-size: 32px;
      font-weight: 700;
    }
    &-text {
      font-size: 16px;
      font-weight: 300;
    }
  }
}
</style>
