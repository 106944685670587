<template>
  <li
    @click="$emit('label-clicked')"
    :style="contentOpenClose"
    class="meal flex-column card-list"
  >
    <div class="meal__label label">
      <h4 class="meal__title">{{ title }}</h4>
      <img
        v-if="!layerImg"
        :class="{ rotate: open }"
        src="@/assets/arrow-yellow.svg"
        alt="arrow"
      />
      <div v-else class="meal__arrow">
        <img
          :class="{ rotate: open }"
          src="@/assets/arrow-yellow.svg"
          alt="arrow"
        />
      </div>
    </div>
    <ul ref="content" class="meal__content content">
      <li class="meal__content-item food-plan__content-item--levmeals">
        <p class="meal__number number">{{ shorten(meal.levmeals) }}</p>
        <p class="meal__name">{{ $t('foodPlan.lev_meals') }}</p>
      </li>
      <template v-for="item in sortMeals(meal.items)" :key="item.id">
        <li
          v-if="item.product"
          @click="openNotes(item.notes, item.product)"
          class="meal__content-item"
        >
          <p v-if="!item.notes" class="meal__number number">{{ item.count }}</p>
          <p class="meal__name">{{ item.product.name }}</p>
        </li>
        <p v-if="item.notes" class="body-regular">"{{ item.notes }}"</p>
      </template>
    </ul>
  </li>
</template>

<script>
import shared from '@/shared';
export default {
  created() {
    this.shorten = shared.shorten;
  },

  emits: ['label-clicked'],

  methods: {
    openNotes(notes, product) {
      if (notes && product) {
        this.notesOpen = !this.notesOpen;
      }
    },
    sortMeals(array) {
      return array.sort((a, b) => {
        if (!a.product && b.product) {
          return 1;
        } else if (a.product && !b.product) {
          return -1;
        } else return 0;
      });
    },
  },

  props: {
    open: {
      type: Boolean,
      default: false,
    },
    meal: Object,
    title: String,
    layerImg: String,
  },

  computed: {
    contentOpenClose() {
      if (this.open) return { height: `${this.contentHeight + 84}px` };
      return { height: '84px' };
    },
    contentHeight() {
      return this.$refs.content.getBoundingClientRect().height;
    },
  },
};
</script>

<style lang="scss" scoped>
.meal {
  background-color: $background-color;
  box-shadow: $card-shadow-l;
  border: none;
  border-radius: $rounded-l;
  &:not(:last-child) {
    margin-bottom: 16px;
  }
  &__label {
    img {
      transition: all $open-card ease-in-out;
      z-index: 2;
    }
    .layer-img {
      top: 25px;
      z-index: 2;
    }
  }
  &__title {
    font-size: 18px;
    font-weight: 700;
    line-height: 16px;
    text-transform: lowercase;
    &::first-letter {
      text-transform: capitalize;
    }
  }
  &__arrow {
    position: absolute;
    top: 36px;
    right: 20px;
    height: 14px;
    width: 16px;
    display: flex;
    align-items: center;
    overflow: hidden;
  }
  &__content {
    padding-bottom: 10px;
    &-item {
      margin: 0;
      display: grid;
      grid-template-columns: 30px auto;
      align-items: center;
      width: 100%;
    }
    .number {
      margin-right: 20px;
    }
  }
  &__name,
  &__number {
    margin: 0;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
  }
  &__number {
    font-weight: 700;
  }
  &__name {
    &::first-letter {
      text-transform: capitalize;
    }
  }
  .label {
    img {
      transition: all $open-card ease-in-out;
      z-index: 2;
    }
    .layer-img {
      top: 25px;
      z-index: 2;
    }
  }
  .rotate {
    transform: rotate(180deg);
  }
}
</style>
