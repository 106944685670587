<template>
  <div class="flex-column">
    <InputPassword
      placeholder="New Password"
      @type="typePass"
      @enter-clicked="resetPassword"
    />
    <InputPassword
      placeholder="Repeat Password"
      @type="typeVerify"
      @enter-clicked="resetPassword"
    />
    <h6>{{ error }}</h6>
    <PrimaryButton
      :disabled="!finish"
      @click="resetPassword"
      title="Reset password"
    />
  </div>
</template>

<script>
import InputPassword from '@/components/ui/InputPassword.vue';
import PrimaryButton from '@/components/ui/PrimaryButton.vue';
import { mapState } from 'vuex';

export default {
  name: 'password',

  components: {
    InputPassword,
    PrimaryButton,
  },

  data() {
    return {
      password: '',
      verify: '',
    };
  },

  computed: {
    ...mapState(['error']),

    redirect() {
      return this.$route.query.redirect
        ? decodeURIComponent(this.$route.query.redirect)
        : null;
    },
    email() {
      return this.$route.params.email;
    },
    token() {
      return this.$route.params.token;
    },
    finish() {
      if (/^.{6,}$/.test(this.password) && this.verify === this.password) {
        return true;
      } else return false;
    },
  },

  methods: {
    typePass(input) {
      this.password = input;
    },
    typeVerify(input) {
      this.verify = input;
    },
    async resetPassword() {
      this.$store.commit('setError', null);
      if (this.password !== this.verify) {
        this.$store.commit('setError', 'Password mismatch');
        return false;
      } else if (this.password.length < 6) {
        this.$store.commit('setError', 'Password too short');
        return false;
      }
      await this.$store
        .dispatch('resetPassword', {
          token: this.token,
          email: this.email,
          password: this.password,
        })
        .then(() => {
          if (!this.error) {
            if (this.redirect) {
              window.location.href = this.redirect;
            } else {
              this.$router.push({ name: 'login' });
            }
          }
        })
        .catch((err) => {
          console.log(err);
          this.password = '';
          this.verify = '';
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.flex-column {
  height: 100vh;
  width: 100vw;
  background-color: $background-color;
  padding: 100px 38px 40px 38px;
  max-width: $max-width;
}
h6 {
  margin-bottom: 16px;
}
</style>
