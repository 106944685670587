<template>
  <canvas id="myChart" width="0"></canvas>
</template>

<script>
/* eslint-disable no-unused-vars */
import Chart from 'chart.js';

export default {
  props: {
    chartData: Object,
    options: Object,
  },

  mounted() {
    const ctx = document.getElementById('myChart');
    let myChart = new Chart(ctx, {
      type: 'line',
      data: this.chartData,
      options: this.options,
    });
    /* eslint-enable no-unused-vars */
  },
};
</script>
