<template>
  <div class="cart" :style="cardMove" ref="cart" v-click-outside="close">
    <div class="cart-content">
      <header class="flex overlay_header">
        <div @click="close" class="back flex">
          <img src="@/assets/back.svg" alt="back.svg" />
        </div>
        <h2 class="header-label">{{ $t('cart.cart') }}</h2>
        <div @click="clearCart" class="clear flex">
          <p>{{ $t('cart.clear_cart') }}</p>
        </div>
      </header>
      <div v-if="shipping && shipping.left" class="shipping flex">
        <h4 class="mini-label">
          {{ $t('cart.amount_left', { amount: shipping.left }) }}
        </h4>
      </div>
      <ProductList
        v-if="ready && deletedProducts.length > 0"
        :products="deletedProducts"
        :small="true"
        :deleted="true"
      />
      <ProductList
        v-if="ready && cart.length > 0"
        :products="getCart"
        :small="true"
      />
      <div v-if="cart.length < 1" class="empty flex-column">
        <img src="@/assets/cart.svg" alt="" />
        <h3>{{ $t('cart.cart_empty') }}</h3>
      </div>
      <div v-else-if="shipping && ready" class="info">
        <CartSummary />
        <PrimaryButton @click="goToCheckout" :title="'Checkout'" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState, mapMutations } from 'vuex';
import clickOutside from '@/directives/ClickOutside';
import shared from '@/shared';
import ProductList from '@/components/ProductList.vue';
import PrimaryButton from '@/components/ui/PrimaryButton.vue';
import CartSummary from '@/components/CartSummary.vue';
import woocommerce from '../apis/woocommerce';

export default {
  components: {
    ProductList,
    PrimaryButton,
    CartSummary,
  },
  data() {
    return {
      movement: 0,
      touch: {
        startX: 0,
        endX: 0,
      },
      ready: false,
      deletedProducts: [],
    };
  },
  computed: {
    ...mapState([
      'shipping',
      'cart',
      'deliveryAddress',
      'phase',
      'order',
      'patientId',
      'cartOverlay',
    ]),
    ...mapGetters(['getCart', 'getCartOrder', 'getStock']),

    cardMove() {
      return { transform: 'translateX(' + this.movement + 'px)' };
    },
  },
  methods: {
    ...mapActions([
      'toggle',
      'animationStartPos',
      'makeOrder',
      'changeOrder',
      'resetCart',
      'getShipping',
    ]),
    ...mapMutations(['filterCart', 'setProducts']),

    clearCart() {
      this.resetCart();
      this.close();
    },

    close() {
      setTimeout(() => {
        this.toggle('cart');
      }, 100);
    },

    createOrder() {
      const order = {
        rows: this.getCartOrder,
        patientId: this.patientId,
        source: 'App',
      };
      this.makeOrder(order);
    },

    async updateOrder() {
      const order = {
        rows: this.getCartOrder,
        orderId: this.order.id,
      };
      await this.changeOrder(order);
    },

    async goToCheckout() {
      if (this.order && this.order.status < 4) {
        await this.updateOrder();
      } else {
        await this.createOrder();
      }
      this.close();
      this.$router.push({ name: 'checkout' });
    },

    touchstart(e) {
      this.touch.startX = e.touches[0].clientX;
      this.touch.endX = e.touches[0].clientX;
      this.touch.startY = e.touches[0].clientY;
      this.touch.endY = e.touches[0].clientY;
    },

    touchmove(e) {
      this.touch.endX = e.touches[0].clientX;
      this.touch.endY = e.touches[0].clientY;
      let yDif = this.touch.endY - this.touch.startY;
      let xDif = this.touch.endX - this.touch.startX;
      if (Math.abs(xDif) > Math.abs(yDif)) e.preventDefault();

      if (
        this.touch.endX > this.touch.startX &&
        Math.abs(xDif) > Math.abs(yDif)
      ) {
        this.movement = Math.abs(this.touch.startX - this.touch.endX);
      }
    },

    touchend() {
      if (
        Math.abs(this.touch.endY - this.touch.startY) >
        Math.abs(this.touch.endX - this.touch.startX)
      ) {
        this.movement = 0;
        this.animationStartPos(this.movement);
        return;
      }
      if (Math.abs(this.touch.endX - this.touch.startX) < 40) {
        this.movement = 0;
        this.animationStartPos(this.movement);
        return;
      }
      if (this.touch.endX > this.touch.startX) {
        this.animationStartPos(this.movement);
        this.toggle('cart');
      }
    },

    removeUnavailableProducts() {
      this.getCart.forEach((p) => {
        const stock = this.getStock(p.sku);
        if (!stock.in_stock) {
          this.$store.commit('deleteFromCart', p.sku);
          this.deletedProducts.push(p);
        }
      });
    },
  },

  directives: {
    clickOutside: clickOutside,
  },

  created() {
    this.addSwipeListeners = shared.addSwipeListeners;
    this.removeSwipeListeners = shared.removeSwipeListeners;
  },

  async mounted() {
    console.log(this.order);
    if (!this.shipping) {
      this.getShipping();
    }
    document.body.className = 'no-scroll';
    this.addSwipeListeners('cart');
    const { data } = await woocommerce.getProductsSku(
      this.cart.map((p) => p.sku)
    );
    this.setProducts(data);
    this.ready = true;
    this.removeUnavailableProducts();
  },

  beforeUnmount() {
    document.body.className = '';
    this.removeSwipeListeners('cart');
  },
};
</script>

<style lang="scss" scoped>
.cart {
  z-index: 100;
  width: 90vw;
  background-color: $background-color;
  position: fixed;
  top: 0;
  right: 0;
  height: 100vh;
  max-width: $component-max-width;
  .overlay_header {
    justify-content: space-between;
    .clear {
      height: $nav-top-height;
      width: 85px;
      align-items: center;
      p {
        font-size: 12px;
        text-decoration: underline;
        text-align: center;
      }
    }
  }
  .shipping {
    height: 50px;
    width: 100%;
    background-color: $accent-yellow;
    align-items: center;
    padding: 0 10px;
  }
  .cart-content {
    height: 100vh;
    overflow-y: auto;
    padding-bottom: 120px;
    .info,
    .empty {
      padding: 0 20px;
    }
    .empty {
      padding: 0 40px;
      h3,
      img {
        opacity: 0.7;
        margin: 20px 0;
      }
      img {
        width: 50%;
      }
    }
  }
}
</style>

<style lang="scss">
.cart {
  .cart-content {
    h5 {
      text-align: left;
    }
  }
}
</style>
